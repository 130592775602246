var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      { staticClass: "small text-muted", attrs: { for: _vm.label } },
      [_vm._v("\n    " + _vm._s(_vm._f("localized")(_vm.label)) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "w-100 filter-group flex-wrap btn-group btn-group-toggle btn-group-sm",
      },
      _vm._l(_vm.options, function (option, oidx) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            key: oidx,
            staticClass:
              "pl-1 pr-1 btn btn-outline-primary custom-hover cursor-pointer",
            class: { active: _vm.proxiedValues.indexOf(option.id) !== -1 },
            attrs: {
              id: `filter-visit-status-${option.id}`,
              title: _vm._f("localized")(option._key),
            },
            on: {
              click: function ($event) {
                return _vm.select(option.id)
              },
            },
          },
          [
            _c("img", {
              style: { backgroundColor: option._bg, marginTop: -3 + "px" },
              attrs: { height: "20px", src: "static/" + option._src + ".png" },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
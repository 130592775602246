import {
  FEATURE_FULL_VIEW,
  REPORT_STATUS_CORRECTION,
  REPORT_STATUS_FINAL,
  REPORT_STATUS_NONE,
  REPORT_STATUS_PRELIMINARY,
} from "@/common/constants";
import striptags from "striptags";

const reportFlags = 0b111;

const PRELIMINARY = 0b001;
const FINAL = 0b010;
const CORRECTION = 0b100;

const possible =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const convertIntToReportStatus = (status) => {
  const report = status & reportFlags;
  switch (report) {
    case PRELIMINARY:
      return REPORT_STATUS_PRELIMINARY;
    case FINAL:
      return REPORT_STATUS_FINAL;
    case CORRECTION:
      return REPORT_STATUS_CORRECTION;
  }
};
export const convertReportStatusToInt = (str) => {
  switch (str) {
    case REPORT_STATUS_PRELIMINARY.status:
      return PRELIMINARY;
    case REPORT_STATUS_FINAL.status:
      return FINAL;
    case REPORT_STATUS_CORRECTION.status:
      return CORRECTION;
  }
};

export const makeID = () => {
  let text = "";
  for (let i = 0; i < 32; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const isHTMLEmpty = (text) => {
  return (
    striptags(text)
      .replace(/&nbsp;/gi, "")
      .trim() === ""
  );
};

export const displayLevelSequence = (levels, type) => {
  let i = 0;
  levels.forEach((l) => {
    if (l.Type === type) {
      i++;
    }
  });
  return i > 1;
};

export const availableReportStates = (reportStatus, statusSequence) => {
  const res = [];
  switch (reportStatus) {
    case REPORT_STATUS_NONE.status: // fallthrough
    case REPORT_STATUS_PRELIMINARY.status:
      // sequence = P-F-C
      if (statusSequence.length === 3) {
        res.push(REPORT_STATUS_PRELIMINARY);
      }
      res.push(REPORT_STATUS_FINAL);
      break;
    case REPORT_STATUS_FINAL.status:
      res.push(REPORT_STATUS_FINAL);
      res.push(REPORT_STATUS_CORRECTION);
      break;
    case REPORT_STATUS_CORRECTION.status:
      res.push(REPORT_STATUS_CORRECTION);
      break;
  }
  return res;
};

export const nextReportStatus = (reportStatus) => {
  switch (reportStatus) {
    case REPORT_STATUS_PRELIMINARY.status:
      return REPORT_STATUS_FINAL.status;
    // fallthrough
    case REPORT_STATUS_FINAL.status:
    case REPORT_STATUS_CORRECTION.status:
      return REPORT_STATUS_CORRECTION.status;
  }
};

export const supportedFeatures = (version) => {
  const features = [];
  switch (version) {
    case 0: // last CEF based wrapper (3.10)
      break;
    case 1: // last CEF based wrapper (3.20)
    case 2: // WebView2 based wrapper (since SeS 3.30)
      features.push(FEATURE_FULL_VIEW);
  }
  return features;
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 h-100" },
    [
      _c("notifications", {
        staticClass: "mt-2",
        attrs: { width: "30%", position: "top center", duration: 5000 },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function (props) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "alert shadow d-flex justify-content-between",
                    class: `alert-${props.item.type}`,
                  },
                  [
                    _c("div", [
                      props.item.title
                        ? _c("b", [_vm._v(_vm._s(props.item.title) + ":")])
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("localized")(props.item.text)) +
                          "\n          "
                      ),
                      props.item.data && props.item.data.message
                        ? _c("span", [
                            _c("br"),
                            _c("i", {
                              staticClass: "fas fa-exclamation-triangle",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(props.item.data.message) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          id: "btn-dismiss-notification",
                          type: "button",
                        },
                        on: { click: props.close },
                      },
                      [_vm._v("\n          ×\n        ")]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
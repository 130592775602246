var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      { staticClass: "small text-muted", attrs: { for: _vm.label } },
      [_vm._v("\n    " + _vm._s(_vm._f("localized")(_vm.label)) + "\n  ")]
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.proxiedValue,
          expression: "proxiedValue",
        },
      ],
      staticClass: "form-control form-control-sm",
      attrs: { id: _vm.label, type: "text" },
      domProps: { value: _vm.proxiedValue },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.proxiedValue = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-modal
    ref="modal"
    size="lg"
    lazy
    id="create-report-modal"
    :hide-footer="isProcessing && switchToReport"
    @show="onShow"
    @shown="focusInput"
    @hide="onHide"
    @hidden="onHidden"
  >
    <template slot="modal-title">
      <h5 class="modal-title pl-2">
        {{ "Review.View.CreateReport.3" | localized }}
      </h5>
    </template>
    <template slot="modal-footer">
      <div class="form-check form-check-inline mr-2">
        <input
          type="checkbox"
          class="form-check-input"
          id="switchToReport"
          v-model="switchToReport"
        />
        <label
          class="form-check-label"
          id="switchToReportLabel"
          for="switchToReport"
        >
          {{ "Review.View.CreateReport.11" | localized }}
        </label>
      </div>
      <button
        id="btn-cancel-create-report"
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.CreateReport.2" | localized }}
      </button>
      <button
        id="btn-confirm-create-report"
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="!selectedReport"
        @click="handleOK(selectedTemplateIndex)"
      >
        {{ "Review.View.CreateReport.1" | localized }}
      </button>
    </template>
    <!-- content -->
    <div>
      <div class="row m-2" v-if="isProcessing && switchToReport">
        <div class="col-12 text-center">
          <Spinner type="border" small />
          <span class="font-weight-bold">{{ selectedReport.name }}</span>
          <br />
          <span class="text-muted">
            {{ "Review.View.CreateReport.12" | localized }}
          </span>
        </div>
      </div>
      <div v-else>
        <div class="row mb-2" style="height: 45vh">
          <!-- category list-->
          <div class="col-6 h-100 d-flex" style="flex-direction: column">
            <label class="small text-muted">
              {{ "Review.View.CreateReport.6" | localized }}
            </label>
            <div
              class="h-100 border"
              style="overflow-y: auto; overflow-x: hidden"
              ref="reportTemplateLists"
            >
              <ul class="selection-list">
                <li
                  class="p-2"
                  v-for="(l, idx) in reportLists"
                  :key="l.label"
                  :id="`report-list-${idx}`"
                  @click="selectReportList(idx)"
                  :class="{ 'bg-active': selectedListIndex === idx }"
                >
                  <div style="height: 24px">
                    <div class="ellipsis" style="width: calc(100% - 40px)">
                      {{ l.label }}
                    </div>
                    <div
                      class="badge badge-secondary badge-pill float-right"
                      style="margin-top: 3px"
                    >
                      {{ l.list.length }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- report list-->
          <div class="col-6 h-100 d-flex" style="flex-direction: column">
            <label class="small text-muted">
              {{ "Review.View.CreateReport.5" | localized }}
            </label>
            <div style="min-height: 32px; height: 32px">
              <div class="input-group input-group-sm">
                <input
                  id="input-search-create-report"
                  ref="reportCreateFilter"
                  type="text"
                  class="form-control"
                  v-model="search"
                  autocomplete="off"
                />
                <div class="input-group-append">
                  <button
                    id="btn-clear-search-create-report"
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="search = ''"
                  >
                    {{ "Review.View.CreateReport.9" | localized }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="h-100 mt-2 border"
              style="overflow-x: hidden; overflow-y: auto; position: relative"
              ref="reportTemplates"
            >
              <ul class="selection-list">
                <li
                  class="p-2"
                  v-for="(rt, idx) in filteredTemplates"
                  :id="`report-template-${idx}`"
                  :key="idx"
                  :class="{
                    'bg-active': selectedTemplateIndex === idx,
                    'text-muted': pendingReportNames[rt.name],
                  }"
                  @click="selectTemplate(idx)"
                  @dblclick="handleOK(idx)"
                >
                  <div style="height: 24px" class="d-flex">
                    <Spinner
                      color="muted"
                      type="border"
                      class="mt-1"
                      small
                      v-if="pendingReportNames[rt.name]"
                    />
                    <div
                      v-html="highlight(rt.name)"
                      class="mx-1 flex-fill ellipsis"
                    ></div>
                    <div
                      v-if="rt.id"
                      class="text-secondary float-right"
                      style="width: 20px; font-size: 12px; margin-top: 3px"
                    >
                      <i class="fas fa-sync"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <label class="small text-muted">
          {{ "Review.View.CreateReport.4" | localized }}
        </label>
        <ReportStatusSelection
          @update="selectedStatus = $event"
          :initial-status="selectedStatus"
          :available-states="availableStates"
          :disabled="isReportStatusSelectionDisabled"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import ApiService from "@/common/api.service";
import {
  REPORT_TYPE_INTERPRETATION,
  REPORT_STATUS_NONE,
} from "@/common/constants";
import {
  availableReportStates,
  convertIntToReportStatus,
} from "@/common/utils";
import {
  DISABLE_SWITCH_TO_REPORT,
  ENABLE_SWITCH_TO_REPORT,
  NEW_REPORT,
} from "@/store/actions.type";

import ReportStatusSelection from "@/components/ReportStatusSelection";
import Spinner from "@/components/Spinner";

// store last create report promise to allow correct handling of redirect after creation
let lastPromise = null;

export default {
  name: "ReportModalCreate",
  components: { ReportStatusSelection, Spinner },
  data() {
    return {
      recommendedReportTemplates: [],
      isProcessing: false,
      selectedListIndex: 0, // we always have all and recommended
      selectedTemplateIndex: -1, // start without selection
      search: "",
      selectedStatus: this.$store.getters.HISStatusSequence[0].status,
    };
  },
  computed: {
    pendingReportNames() {
      const v = this.selectedVisit;
      if (!v) {
        return {};
      }
      return this.$store.getters.pendingReportNamesBySelectedVisit;
    },
    // all available report lists (all, recommended and custom lists) with name, label and list of templates with name, id, status
    reportLists() {
      const reportLists = [];
      reportLists.push({
        label: this.$store.getters.localizedText("Review.View.CreateReport.7"),
        list: [],
      });
      reportLists.push({
        label: this.$store.getters.localizedText("Review.View.CreateReport.8"),
        list: [],
      });

      const recommendedReports = {};
      this.recommendedReportTemplates.forEach((rrt) => {
        recommendedReports[rrt.Name] = true;
      });
      const reportInterpretationList = [];
      // iterate report templates and fill lists all and recommended
      this.$store.getters.reportTemplates.map((rt) => {
        const er = this.existingReports[rt.Name];
        const item = {
          name: rt.Name,
          type: rt.Type,
          id: er ? er.id : null,
          status: er ? er.status : null,
        };
        reportLists[0].list.push(item);
        if (recommendedReports[rt.Name]) {
          reportLists[1].list.push(item);
        }
        if (rt.Type === REPORT_TYPE_INTERPRETATION) {
          reportInterpretationList.push(item);
        }
      });

      if (reportInterpretationList.length > 0) {
        reportLists.push({
          label: this.$store.getters.localizedText(
            "Review.View.CreateReport.10"
          ),
          list: reportInterpretationList,
        });
      }

      // add custom lists
      const lists = this.$store.getters.customReportLists;
      const names = Object.keys(lists);
      names.map((name) => {
        const reportsOfCustomList = lists[name].map((rt) => {
          const item = { name: rt.Name, type: rt.Type };
          // copy over id and status
          const er = this.existingReports[rt.Name];
          if (er) {
            item.id = er.id;
            item.status = er.status;
          }
          return item;
        });
        reportLists.push({
          label: name,
          list: reportsOfCustomList,
        });
      });
      return reportLists;
    },
    existingReports() {
      const existingReports = {};
      const v = this.selectedVisit;
      if (v) {
        v.Reports.forEach((r) => {
          existingReports[r.Filename] = {
            id: r.ID,
            status: r.Status,
          };
        });
      }
      return existingReports;
    },
    regex() {
      const escaped = this.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      return new RegExp(escaped, "gi");
    },
    filteredTemplates() {
      let fts = [];
      fts = this.reportLists[this.selectedListIndex].list.filter(
        (rt) => rt.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      );
      return fts;
    },
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    selectedReport() {
      return this.filteredTemplates[this.selectedTemplateIndex];
    },
    switchToReport: {
      get() {
        return this.$store.getters.switchToReport;
      },
      set(val) {
        if (!!val) {
          this.$store.dispatch(ENABLE_SWITCH_TO_REPORT);
        } else {
          this.$store.dispatch(DISABLE_SWITCH_TO_REPORT);
        }
      },
    },
    // compute the available states depending on the selected report and the
    // configured HIS status sequence.
    availableStates() {
      return availableReportStates(
        this.selectedReport && this.selectedReport.status
          ? convertIntToReportStatus(this.selectedReport.status).status
          : REPORT_STATUS_NONE.status,
        this.$store.getters.HISStatusSequence
      );
    },
    isReportStatusSelectionDisabled() {
      return (
        !this.selectedReport ||
        this.selectedReport.type === REPORT_TYPE_INTERPRETATION
      );
    },
  },
  watch: {
    search() {
      this.selectNextTemplate(true);
    },
  },
  destroyed() {
    // always remove event listener on destruction, e.g. caused by auto-logout
    this.removeEventListener();
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    highlight(rt) {
      if (!this.search) {
        return rt;
      }
      return rt.replace(this.regex, (match) => {
        return (
          '<span class="font-weight-bold highlighted">' + match + "</span>"
        );
      });
    },
    resetStatus() {
      this.recommendedReportTemplates = [];
      this.selectedListIndex = 0;
      this.isProcessing = false;
      this.selectedStatus = this.$store.getters.HISStatusSequence[0].status;
      this.selectedTemplateIndex = -1;
      this.search = "";
    },
    onShow() {
      this.resetStatus();
      this.selectNextTemplate(true);

      // fetch recommended report templates
      ApiService.fetchRecommendedReportTemplates(this.selectedVisit.ID).then(
        (d) => {
          this.recommendedReportTemplates = d.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
        }
      );
    },
    selectReportList(idx) {
      // skip selection of list with 0 reports or while report is processing
      if (this.isProcessing || this.reportLists[idx].list.length === 0) {
        return;
      }
      this.selectedListIndex = idx;
      this.selectNextTemplate(true);
    },
    selectNextTemplate(reset) {
      if (reset) {
        this.selectedStatus = this.availableStates[0].status;
        this.selectedTemplateIndex = -1;
      }
      for (
        let i = this.selectedTemplateIndex + 1;
        i < this.filteredTemplates.length;
        i++
      ) {
        const t = this.filteredTemplates[i];
        // select next non processing report
        if (!this.pendingReportNames[t.Name]) {
          // update selection
          this.selectTemplate(i);
          return;
        }
      }
    },
    selectPrevTemplate(reset) {
      if (reset) {
        this.selectedStatus = this.availableStates[0].status;
        this.selectedTemplateIndex = -1;
      }
      for (let i = this.selectedTemplateIndex - 1; i > -1; i--) {
        const t = this.filteredTemplates[i];
        // select prev non processing report
        if (!this.pendingReportNames[t.name]) {
          // update selection
          this.selectTemplate(i);
          return;
        }
      }
    },
    selectTemplate(idx) {
      if (this.isProcessing || this.filteredTemplates.length === 0) {
        return;
      }
      const templateCandidate = this.filteredTemplates[idx];
      // no-op if the selected template is already processing
      if (this.pendingReportNames[templateCandidate.name]) {
        return;
      }
      this.selectedTemplateIndex = idx;
      // available states is synced with selected report, hence take first element
      this.selectedStatus = this.availableStates[0].status;
      this.scrollToSelection();
    },
    focusInput() {
      // bind event handler
      window.addEventListener("keyup", this.handleKeyup);
      this.$refs.reportCreateFilter.focus();
    },
    handleOK(idx) {
      // already creating a report do nothing
      if (this.isProcessing) {
        return;
      }
      // we don't allow a double click before a valid template is selected
      if (this.selectedTemplateIndex !== idx) {
        return;
      }
      this.isProcessing = true;
      // as most of the instance properties are mutated we create local copies here
      const visitID = this.$store.getters.selection.visitID;
      const { id, name } = this.selectedReport;
      const pr = this.$store.dispatch(NEW_REPORT, {
        visitID: visitID,
        reportName: name,
        reportStatus: this.selectedStatus,
        reportID: id,
      });
      // update last promise
      lastPromise = pr;
      if (!this.switchToReport) {
        this.$refs.modal.hide();
        pr.then(() => {
          // we assume everything worked, emit report-created event
          this.$emit("report-created", {
            visitID,
            reportName: name,
            background: true,
          });
        });
        return;
      }
      // chain promise with the handlers required for navigation after creation
      pr.then(() => {
        // no-op if there's a newer promise, e.g. the dialog was shown again in the meantime
        if (lastPromise !== pr) {
          this.$emit("report-created", {
            visitID,
            reportName: name,
            background: true,
          });
          return;
        }
        // emit report-created event and hide modal now
        this.$emit("report-created", {
          visitID,
          reportName: name,
          background: false,
        });
        this.$refs.modal.hide();
      }).catch((err) => {
        console.log(err);
        // the creation failed, keep modal visible
        this.isProcessing = false;
      });
    },
    handleCancel() {
      this.$refs.modal.hide("cancel");
    },
    handleKeyup: function (event) {
      // no-op if event was already handled or the selected report is processing
      if (event.defaultPrevented || this.isProcessing) {
        return;
      }
      // fallback to keyCode
      const key = event.key || event.keyCode;
      if ((key === "Enter" || key === 13) && this.selectedTemplateIndex >= 0) {
        this.handleOK(this.selectedTemplateIndex);
        return;
      }
      if (key === "ArrowUp" || key === "Up" || key === 38) {
        this.selectPrevTemplate(false);
        return;
      }
      if (key === "ArrowDown" || key === "Down" || key === 40) {
        this.selectNextTemplate(false);
      }
    },
    scrollToSelection() {
      const el = document.getElementById(
        `report-template-${this.selectedTemplateIndex}`
      );
      if (!el) {
        return;
      }
      const p = this.$refs.reportTemplates;
      // scroll up
      if (el.offsetTop < p.scrollTop) {
        p.scrollTop = el.offsetTop;
        return;
      }
      const diff =
        el.offsetTop + el.clientHeight - (p.scrollTop + p.clientHeight);
      // scroll down
      if (diff > 0) {
        p.scrollTop += diff;
      }
    },
    onHidden() {
      // reset this.isProcessing after the modal is hidden to avoid flickering
      this.isProcessing = false;
    },
    // onHide is always called when the modal is closed
    onHide(event) {
      // keep dialog visible
      if (this.switchToReport && event && event.trigger === "ok") {
        event.preventDefault();
        return;
      }
      this.$emit("hide", event);
      // reset promise
      lastPromise = null;
      // always remove event listeners
      this.removeEventListener();
    },
    removeEventListener() {
      window.removeEventListener("keyup", this.handleKeyup);
    },
  },
};
</script>

<style lang="scss">
@import "../custom";

.highlighted {
  background-color: map-get($theme-colors, "highlight-light") !important;
}

.selection-list {
  list-style: none;
  padding: 0;

  li {
    &:hover {
      background-color: map-get($theme-colors, "hover") !important;
      cursor: pointer;
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
</style>

import store from "@/store/index";
import { DEFAULT_LANG } from "@/common/constants";

export default (value) => {
  // console.log("localizedFilter: ", value);
  if (!value) {
    return "";
  }
  // check language db
  const str = store.getters.localizedText(value);
  if (str) {
    return str;
  }
  // check default values
  const defaultStr = DEFAULT_LANG[value];
  if (defaultStr) {
    return `${DEFAULT_LANG[value]}`;
  }
  // otherwise return raw value
  return value;
};

<template>
  <div class="row">
    <template v-for="(f, idx) in filters">
      <FilterText
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'ExternalID'"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterText
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'LastName'"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterText
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'FirstName'"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterText
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'HISVisitID' && isHISAvailable"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterText
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'HISOrderID' && isHISAvailable"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'Type'"
        :initial-value="currentFilter[f]"
        :options="patientTypeOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'Location' && useLocationFilter"
        :initial-value="currentFilter[f]"
        :options="locationOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterTypeahead
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'Location' && !useLocationFilter"
        :initial-value="currentFilter[f]"
        :options="locationOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'Operator'"
        :initial-value="currentFilter[f]"
        :options="operatorOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'ReferringPhysician'"
        :initial-value="currentFilter[f]"
        :options="refPhysicianOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'Physician'"
        :initial-value="currentFilter[f]"
        :options="physicianOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'ReportStatusPreliminary'"
        :initial-value="currentFilter[f]"
        :options="reportStatusOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'ReportStatusFinal'"
        :initial-value="currentFilter[f]"
        :options="reportStatusOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterSelection
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'ReportStatusCorrection'"
        :initial-value="currentFilter[f]"
        :options="reportStatusOptions"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterVisitStatus
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'VisitStatus'"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
      <FilterDate
        class="col-12 col-md-6 col-xl-3"
        v-if="f === 'Date'"
        :initial-value="currentFilter[f]"
        :label="filterLabels[f]"
        @update="updateCurrentFilter(f, $event)"
        :key="`${f}-${idx}`"
      />
    </template>
  </div>
</template>

<script>
import { FILTER_LABELS } from "@/common/constants";
import { UPDATE_CURRENT_FILTER } from "@/store/actions.type";

import FilterText from "@/components/views/visits/FilterText";
import FilterSelection from "@/components/views/visits/FilterSelection";
import FilterVisitStatus from "@/components/views/visits/FilterVisitStatus";
import FilterDate from "@/components/views/visits/FilterDate";
import FilterTypeahead from "@/components/views/visits/FilterTypeahead";

export default {
  name: "FilterForm",
  components: {
    FilterTypeahead,
    FilterSelection,
    FilterVisitStatus,
    FilterText,
    FilterDate,
  },
  props: {
    value: Object,
    filters: Array,
  },
  data() {
    return {
      filterLabels: FILTER_LABELS,
      patientTypeOptions: [
        {
          ID: "Normal",
          Name: this.$options.filters.localized("Review.View.ReportSearch.7"),
        },
        {
          ID: "Demo",
          Name: this.$options.filters.localized("Review.View.ReportSearch.8"),
        },
        {
          ID: "BioQualityControl",
          Name: this.$options.filters.localized("Review.View.ReportSearch.9"),
        },
        {
          ID: "SeSQM",
          Name: this.$options.filters.localized("Review.View.ReportSearch.10"),
        },
        {
          ID: "ATSTest",
          Name: this.$options.filters.localized("Review.View.ReportSearch.11"),
        },
      ],
      reportStatusOptions: [
        {
          ID: "Changed",
          Name: this.$options.filters.localized(
            "Review.Common.ReportStatus.11"
          ),
        },
        {
          ID: "Sent",
          Name: this.$options.filters.localized(
            "Review.Common.ReportStatus.10"
          ),
        },
        {
          ID: "NotSent",
          Name: this.$options.filters.localized("Review.Common.ReportStatus.9"),
        },
        {
          ID: "Modified",
          Name: this.$options.filters.localized("Review.Common.ReportStatus.8"),
        },
      ],
    };
  },
  computed: {
    useLocationFilter() {
      return this.$store.getters.settings.UseLocationFilter;
    },
    locationOptions() {
      const opts = this.$store.getters.availableLocations;
      if (opts.length === 0) {
        return [];
      }
      return opts;
    },
    operatorOptions() {
      const opts = this.$store.getters.availableOperators;
      if (opts.length === 0) {
        return [];
      }
      return opts;
    },
    physicianOptions() {
      const opts = this.$store.getters.availablePhysicians;
      if (opts.length === 0) {
        return [];
      }
      return opts;
    },
    refPhysicianOptions() {
      const opts = this.$store.getters.availableRefPhysicians;
      if (opts.length === 0) {
        return [];
      }
      return opts;
    },
    currentFilter: {
      get() {
        return this.$store.getters.currentFilter;
      },
      set(value) {
        this.$store.dispatch(UPDATE_CURRENT_FILTER, value);
      },
    },
    isHISAvailable() {
      return this.$store.getters.isHISAvailable;
    },
  },
  methods: {
    updateCurrentFilter(name, val) {
      this.currentFilter = { ...this.currentFilter, [name]: val };
    },
  },
};
</script>

<style scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.measurementTypeExists
    ? _c("div", { staticClass: "bg-selected pl-1 pr-1 mb-1" }, [
        _vm._v(
          "\n  " + _vm._s(_vm._f("time")(_vm.measurement.LocalDate)) + ":\n  "
        ),
        _c(
          "span",
          {
            class: { "cursor-pointer text-primary": _vm.isDesktopMode },
            on: { click: _vm.openMeasurement },
          },
          [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
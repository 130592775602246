<template>
  <BaseLayout>
    <template v-slot:header>&nbsp;</template>
    <template v-slot:content>
      <div
        class="d-flex flex-column w-100 h-100 justify-content-center align-items-center"
      >
        <div class="text-center"><img src="@/assets/img/logo-login.png" /></div>
        <div
          v-if="isMobileReviewEnabled"
          class="w-100"
          style="max-width: 300px"
        >
          <ErrorMessage :error="error" :args="errorArgs" :dismiss="hideError" />
          <form @submit.prevent="login" v-if="!showChangePassword">
            <div class="col-12">
              <div class="form-group">
                <label for="input-username">
                  {{ "Review.View.Login.1" | localized }}
                </label>
                <input
                  id="input-username"
                  v-model="username"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Login name"
                  autofocus
                />
              </div>
              <div class="form-group">
                <label for="input-password">
                  {{ "Review.View.Login.3" | localized }}
                </label>
                <input
                  id="input-password"
                  v-model="password"
                  autocomplete="current-password"
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                />
              </div>
              <div class="form-group d-flex justify-content-between">
                <button
                  id="btn-show-change-password"
                  type="button"
                  class="btn btn-sm btn-link ml-0 pl-0"
                  tabindex="-1"
                  :disabled="busy || !isChangePasswordAllowed"
                  @click="toggleShowChangePassword"
                >
                  {{ "Review.View.Login.10" | localized }}
                </button>
                <button
                  id="btn-login"
                  :disabled="loginButtonDisabled"
                  type="submit"
                  class="btn btn-sm btn-primary"
                >
                  <Spinner type="border" small color="white" v-if="busy" />
                  {{ "Review.View.Login.2" | localized }}
                </button>
              </div>
            </div>
          </form>
          <form @submit.prevent="changePassword" v-else>
            <div class="col-12">
              <div class="form-group">
                <label for="input-username">
                  {{ "Review.View.Login.1" | localized }}
                </label>
                <input
                  id="input-username"
                  v-model="username"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Login name"
                  autofocus
                />
              </div>
              <div class="form-group">
                <label for="input-old-password">
                  {{ "Review.View.Login.102" | localized }}
                </label>
                <input
                  id="input-old-password"
                  v-model="password"
                  autocomplete="current-password"
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Current Password"
                />
              </div>
              <div class="form-group">
                <label for="input-new-password">
                  {{ "Review.View.Login.103" | localized }}
                </label>
                <input
                  id="input-new-password"
                  v-model="newPassword"
                  autocomplete="new-password"
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="New Password"
                />
              </div>
              <div class="form-group">
                <label for="input-confirm-password">
                  {{ "Review.View.Login.104" | localized }}
                </label>
                <input
                  id="input-confirm-password"
                  v-model="newPasswordConfirm"
                  autocomplete="new-password"
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="New Password"
                />
              </div>
              <div class="form-group d-flex justify-content-end">
                <button
                  id="btn-cancel-change-password"
                  type="button"
                  class="btn btn-sm btn-outline-secondary ml-0"
                  :disabled="busy"
                  @click="toggleShowChangePassword"
                >
                  {{ "Review.View.Login.105" | localized }}
                </button>
                <button
                  id="btn-change-password"
                  :disabled="changePasswordButtonDisabled"
                  type="submit"
                  class="btn btn-sm btn-primary ml-2"
                >
                  <Spinner type="border" small color="white" v-if="busy" />
                  {{ "Review.View.Login.101" | localized }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="alert alert-warning mt-3" role="alert" v-else>
          <i class="fas fa-info-circle"></i>
          Mobile Review is not a licensed option! Program is aborted.
        </div>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import "@/assets/img/logo-login.png";

import ApiService from "@/common/api.service";
import {
  AUTHENTICATE,
  FETCH_CONFIGURATION,
  CHANGE_LOCALE,
} from "@/store/actions.type";

import ErrorMessage from "@/components/ErrorMessage";
import Spinner from "@/components/Spinner";
import BaseLayout from "@/components/BaseLayout";

export default {
  name: "Login",
  components: { BaseLayout, ErrorMessage, Spinner },
  data() {
    return {
      username: "",
      password: "",
      newPassword: "",
      newPasswordConfirm: "",
      showChangePassword: false,
      isChangePasswordAllowed: false,
      error: "",
      busy: false,
      interval: null,
      lockedDuration: 0,
    };
  },
  created() {
    ApiService.changePasswordAllowed().then(
      (allowed) => (this.isChangePasswordAllowed = allowed)
    );
  },
  computed: {
    changePasswordButtonDisabled() {
      return (
        this.username === "" ||
        this.password === "" ||
        this.newPassword === "" ||
        this.busy ||
        this.newPassword !== this.newPasswordConfirm
      );
    },
    loginButtonDisabled() {
      return (
        this.username === "" ||
        this.password === "" ||
        this.busy ||
        this.isUserLocked
      );
    },
    isInitialized() {
      return this.$store.getters.isInitialized;
    },
    isMobileReviewEnabled() {
      return this.$store.getters.isMobileReviewEnabled;
    },
    isUserLocked() {
      return this.lockedDuration > 0;
    },
    errorArgs() {
      return [this.lockedDuration];
    },
  },
  methods: {
    toggleShowChangePassword() {
      this.error = "";
      this.showChangePassword = !this.showChangePassword;
      this.clearPasswordFields();
    },
    changePassword() {
      const { username, password, newPassword } = this;
      this.error = "";
      this.busy = true;

      ApiService.changePassword(username, password, newPassword)
        .then((data) => {
          let errorText;
          switch (data.Status) {
            case 0:
              // success
              break;
            case 1:
              errorText = "Review.View.Login.201";
              break;
            case 2:
              errorText = "Review.View.Login.202";
              break;
            case 3:
              errorText = "Review.View.Login.203";
              break;
            case 4:
              errorText = "Review.View.Login.204";
              break;
            case 5:
              errorText = "Review.View.Login.205";
              break;
            case 6:
              errorText = "Review.View.Login.206";
              break;
            case 7:
              errorText = "Review.View.Login.207";
              break;
            case 8:
              errorText = "Review.View.Login.208";
              break;
            case 9:
              errorText = "Review.View.Login.209";
              break;
            default:
              errorText = "Review.View.Login.106";
              break;
          }

          if (errorText) {
            this.error = errorText;
            return;
          }

          this.showChangePassword = false;
          this.$notify({
            type: "success",
            text: "Review.View.Messages.22",
          });
        })
        .catch((err) => {
          console.log(err);
          this.error = "Review.View.Login.106";
        })
        .finally(() => {
          this.busy = false;
          this.clearPasswordFields();
        });
    },
    clearPasswordFields() {
      this.password = "";
      this.newPassword = "";
      this.newPasswordConfirm = "";
    },
    login() {
      const { username, password } = this;
      this.error = "";
      this.busy = true;

      this.$store
        .dispatch(AUTHENTICATE, {
          username,
          password,
        })
        .then(() => {
          // once authenticated load configuration
          return Promise.all([
            this.$store.dispatch(FETCH_CONFIGURATION),
            this.$store.dispatch(CHANGE_LOCALE, {
              language: this.$store.getters.userLanguage,
              country: this.$store.getters.userCountry,
            }),
          ]);
        })
        .then(() => {
          this.busy = false;
          // redirect to requested page after successful login
          let redirect = "/";
          if (this.$route.query.redirect) {
            redirect = this.$route.query.redirect;
          }
          this.$router
            .push({ path: redirect })
            .catch((err) => console.log(err));
        })
        .catch((e) => {
          // noop
          console.log("catched:", e);
          // update local component state
          this.busy = false;
          this.password = "";
          if (e.response && e.response.status === 423) {
            const { UserLockedDuration, PasswordExpired } = e.response.data;
            if (PasswordExpired) {
              this.error = "Review.View.Login.9";
              return;
            }
            if (UserLockedDuration > 0) {
              this.lockedDuration = UserLockedDuration;
              clearInterval(this.interval);
              this.interval = setInterval(() => {
                --this.lockedDuration;
                if (!this.isUserLocked) {
                  clearInterval(this.interval);
                  this.hideError();
                }
              }, 1000);
              this.error = "Review.View.Login.8";
              return;
            }
          }
          this.error = "Review.View.Login.5";
        });
    },
    hideError() {
      this.error = "";
    },
  },
};
</script>

<style scoped></style>

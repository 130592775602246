var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "flex-grow-1 d-flex flex-column border-top",
                staticStyle: {
                  "min-height": "0",
                  "border-width": "2px !important",
                  "overflow-y": "hidden",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "container-fluid flex-fill overflow-auto" },
                  [
                    _c(
                      "div",
                      { staticClass: "p-2 mt-2 bg-white" },
                      [
                        _vm.hasAdminRight
                          ? [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("localized")(
                                      "Review.View.Administration.10"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              !_vm.isDesktopMode
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-4" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("localized")(
                                              "Review.View.Administration.20"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-control custom-checkbox",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.settings
                                                    .HISActiveForMobile,
                                                expression:
                                                  "settings.HISActiveForMobile",
                                              },
                                            ],
                                            staticClass: "custom-control-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: "HISActiveForMobile",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.settings.HISActiveForMobile
                                              )
                                                ? _vm._i(
                                                    _vm.settings
                                                      .HISActiveForMobile,
                                                    null
                                                  ) > -1
                                                : _vm.settings
                                                    .HISActiveForMobile,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.settings
                                                      .HISActiveForMobile,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.settings,
                                                        "HISActiveForMobile",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.settings,
                                                        "HISActiveForMobile",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "HISActiveForMobile",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", {
                                            staticClass: "custom-control-label",
                                            attrs: {
                                              id: "HISActiveForMobileLabel",
                                              for: "HISActiveForMobile",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.11"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.settings.AutoSendToHIS,
                                            expression:
                                              "settings.AutoSendToHIS",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "AutoSendToHIS",
                                          disabled:
                                            !_vm.isDesktopMode &&
                                            !_vm.settings.HISActiveForMobile,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.settings.AutoSendToHIS
                                          )
                                            ? _vm._i(
                                                _vm.settings.AutoSendToHIS,
                                                null
                                              ) > -1
                                            : _vm.settings.AutoSendToHIS,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.settings.AutoSendToHIS,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AutoSendToHIS",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AutoSendToHIS",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.settings,
                                                "AutoSendToHIS",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: {
                                          id: "AutoSendToHISLabel",
                                          for: "AutoSendToHIS",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.12"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.settings
                                                .AllowSendToHISWithoutComment,
                                            expression:
                                              "settings.AllowSendToHISWithoutComment",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "AllowSendToHISWithoutComment",
                                          disabled:
                                            !_vm.isDesktopMode &&
                                            !_vm.settings.HISActiveForMobile,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.settings
                                              .AllowSendToHISWithoutComment
                                          )
                                            ? _vm._i(
                                                _vm.settings
                                                  .AllowSendToHISWithoutComment,
                                                null
                                              ) > -1
                                            : _vm.settings
                                                .AllowSendToHISWithoutComment,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.settings
                                                  .AllowSendToHISWithoutComment,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowSendToHISWithoutComment",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowSendToHISWithoutComment",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.settings,
                                                "AllowSendToHISWithoutComment",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: {
                                          id:
                                            "AllowSendToHISWithoutCommentLabel",
                                          for: "AllowSendToHISWithoutComment",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("h5", { staticClass: "mt-4" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("localized")(
                                        "Review.View.Administration.2"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.3"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.settings.HISStatusSequence,
                                          expression:
                                            "settings.HISStatusSequence",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: { id: "HISStatusSequence" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.settings,
                                            "HISStatusSequence",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.hisStatusSequences,
                                      function (option, oidx) {
                                        return _c(
                                          "option",
                                          {
                                            key: oidx,
                                            attrs: {
                                              id: `HISStatusSequenceOption${oidx}`,
                                            },
                                            domProps: { value: option.id },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("localized")(
                                                    option.label
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row pt-2 pb-1" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.27"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.settings.CommentTemplateSearch,
                                          expression:
                                            "settings.CommentTemplateSearch",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: { id: "CommentTemplateSearch" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.settings,
                                            "CommentTemplateSearch",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.commentTemplateSearchOptions,
                                      function (option, oidx) {
                                        return _c(
                                          "option",
                                          {
                                            key: oidx,
                                            attrs: {
                                              id: `CommentTemplateSearchOption${oidx}`,
                                            },
                                            domProps: { value: option.id },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("localized")(
                                                    option.label
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.13"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.settings
                                                .AllowSignReportWithoutComment,
                                            expression:
                                              "settings.AllowSignReportWithoutComment",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "AllowSignReportWithoutComment",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.settings
                                              .AllowSignReportWithoutComment
                                          )
                                            ? _vm._i(
                                                _vm.settings
                                                  .AllowSignReportWithoutComment,
                                                null
                                              ) > -1
                                            : _vm.settings
                                                .AllowSignReportWithoutComment,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.settings
                                                  .AllowSignReportWithoutComment,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowSignReportWithoutComment",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowSignReportWithoutComment",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.settings,
                                                "AllowSignReportWithoutComment",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: {
                                          id:
                                            "AllowSignReportWithoutCommentLabel",
                                          for: "AllowSignReportWithoutComment",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.14"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.settings
                                                .AllowExternalReportUpload,
                                            expression:
                                              "settings.AllowExternalReportUpload",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "AllowExternalReportUpload",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.settings
                                              .AllowExternalReportUpload
                                          )
                                            ? _vm._i(
                                                _vm.settings
                                                  .AllowExternalReportUpload,
                                                null
                                              ) > -1
                                            : _vm.settings
                                                .AllowExternalReportUpload,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.settings
                                                  .AllowExternalReportUpload,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowExternalReportUpload",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowExternalReportUpload",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.settings,
                                                "AllowExternalReportUpload",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: {
                                          id: "AllowExternalReportUploadLabel",
                                          for: "AllowExternalReportUpload",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.15"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.settings
                                                .AllowCommentingOfExternalReports,
                                            expression:
                                              "settings.AllowCommentingOfExternalReports",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            "AllowCommentingOfExternalReports",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.settings
                                              .AllowCommentingOfExternalReports
                                          )
                                            ? _vm._i(
                                                _vm.settings
                                                  .AllowCommentingOfExternalReports,
                                                null
                                              ) > -1
                                            : _vm.settings
                                                .AllowCommentingOfExternalReports,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.settings
                                                  .AllowCommentingOfExternalReports,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowCommentingOfExternalReports",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowCommentingOfExternalReports",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.settings,
                                                "AllowCommentingOfExternalReports",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: {
                                          id:
                                            "AllowCommentingOfExternalReportsLabel",
                                          for:
                                            "AllowCommentingOfExternalReports",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("localized")(
                                          "Review.View.Administration.16"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.settings
                                                .AllowTechCommentSignature,
                                            expression:
                                              "settings.AllowTechCommentSignature",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "AllowTechCommentSignature",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.settings
                                              .AllowTechCommentSignature
                                          )
                                            ? _vm._i(
                                                _vm.settings
                                                  .AllowTechCommentSignature,
                                                null
                                              ) > -1
                                            : _vm.settings
                                                .AllowTechCommentSignature,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.settings
                                                  .AllowTechCommentSignature,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowTechCommentSignature",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "AllowTechCommentSignature",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.settings,
                                                "AllowTechCommentSignature",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: {
                                          id: "AllowTechCommentSignatureLabel",
                                          for: "AllowTechCommentSignature",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              !_vm.isDesktopMode
                                ? [
                                    _c("h5", { staticClass: "mt-4" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("localized")(
                                              "Review.View.Administration.4"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-4" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Administration.7"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-6" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.autoLogoutTimeout,
                                              expression: "autoLogoutTimeout",
                                            },
                                          ],
                                          staticClass: "custom-range",
                                          attrs: {
                                            id: "AutoLogoutTimeout",
                                            type: "range",
                                            min: "1",
                                            max: Math.min(
                                              1440,
                                              _vm.tokenLifeTime
                                            ),
                                          },
                                          domProps: {
                                            value: _vm.autoLogoutTimeout,
                                          },
                                          on: {
                                            __r: function ($event) {
                                              _vm.autoLogoutTimeout =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-2" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.autoLogoutTimeout) +
                                            "\n                  " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Administration.6"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h5", { staticClass: "mt-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.Administration.21"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.Administration.22"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-1 text-muted" }, [
                            _c("p", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("localized")(
                                      "Review.View.Administration.23"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("localized")(
                                      "Review.View.Administration.24"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-11" },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass:
                                    "drag-area row rounded no-gutters",
                                  class: {
                                    "drop-target alert-success border":
                                      _vm.dragging === "visible" ||
                                      (_vm.dragging === "hidden" &&
                                        _vm.visible.length < 4),
                                  },
                                  attrs: {
                                    group: {
                                      name: "filter",
                                      put:
                                        _vm.visible.length < 4 ||
                                        _vm.dragging === "visible",
                                      pull: _vm.visible.length > 1,
                                    },
                                  },
                                  on: {
                                    start: function ($event) {
                                      return _vm.drag("visible", true)
                                    },
                                    end: function ($event) {
                                      return _vm.drag("visible", false)
                                    },
                                  },
                                  model: {
                                    value: _vm.visible,
                                    callback: function ($$v) {
                                      _vm.visible = $$v
                                    },
                                    expression: "visible",
                                  },
                                },
                                [
                                  _vm._l(_vm.visible, function (f, idx) {
                                    return [
                                      _vm.showFilter(f)
                                        ? _c(
                                            "div",
                                            {
                                              key: `${f}-${idx}`,
                                              staticClass: "col-6 col-lg-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "bg-white m-2 rounded",
                                                },
                                                [
                                                  _c("FilterPlaceholder", {
                                                    attrs: {
                                                      label:
                                                        _vm.filterLabels[f],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-1 text-muted" }, [
                            _c("p", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("localized")(
                                      "Review.View.Administration.25"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("localized")(
                                      "Review.View.Administration.26"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-11" },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass:
                                    "drag-area rounded row no-gutters",
                                  class: {
                                    "drop-target alert-success border":
                                      _vm.dragging && _vm.visible.length > 1,
                                  },
                                  attrs: { group: "filter" },
                                  on: {
                                    start: function ($event) {
                                      return _vm.drag("hidden", true)
                                    },
                                    end: function ($event) {
                                      return _vm.drag("hidden", false)
                                    },
                                  },
                                  model: {
                                    value: _vm.hidden,
                                    callback: function ($$v) {
                                      _vm.hidden = $$v
                                    },
                                    expression: "hidden",
                                  },
                                },
                                [
                                  _vm._l(_vm.hidden, function (f, idx) {
                                    return [
                                      _vm.showFilter(f)
                                        ? _c(
                                            "div",
                                            {
                                              key: `${f}-${idx}`,
                                              staticClass: "col-6 col-lg-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "bg-white m-2 rounded",
                                                },
                                                [
                                                  _c("FilterPlaceholder", {
                                                    attrs: {
                                                      label:
                                                        _vm.filterLabels[f],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end border-top p-2 bg-white",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary mr-1",
                        attrs: { id: "btn-cancel-settings" },
                        on: { click: _vm.navigate },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("localized")(
                                "Review.View.Administration.8"
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: {
                          id: "btn-save-settings",
                          disabled: _vm.isSaving,
                        },
                        on: { click: _vm.saveSettings },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("localized")(
                                "Review.View.Administration.9"
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
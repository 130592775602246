<template>
  <div class="form-check form-check-inline">
    <input
      class="form-check-input"
      type="checkbox"
      :checked="checked"
      @change="setTestReviewStatus"
      id="checkbox-test-review"
    />
    <label class="form-check-label" for="checkbox-test-review">
      {{ label | localized }}
    </label>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { NOTIFICATION_DURATION_ERROR } from "@/common/constants";
import { FETCH_VISIT } from "@/store/actions.type";

export default {
  name: "TestReview",
  computed: {
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    workflowConfigurations() {
      return this.$store.getters.activeWorkflowConfigurations;
    },
    checked() {
      return !this.workflowConfigurations.find(
        (c) => c.VisitStatus === this.selectedVisit.ReviewStatus
      ).UseAutoTrigger;
    },
    label() {
      const visit = this.selectedVisit;
      const i = this.workflowConfigurations.findIndex(
        (c) => c.VisitStatus === visit.ReviewStatus
      );
      if (this.workflowConfigurations[i].UseAutoTrigger) {
        return this.workflowConfigurations[i + 1]._key;
      }
      return this.$store.getters.workflowConfiguration(visit.ReviewStatus)._key;
    },
  },
  methods: {
    setTestReviewStatus() {
      const visit = this.selectedVisit;
      const c = this.workflowConfigurations;
      const i = c.findIndex((c) => c.VisitStatus === visit.ReviewStatus);
      let newStatus;
      if (c[i].UseAutoTrigger) {
        newStatus = c[i + 1].VisitStatus;
      } else {
        newStatus = c[i - 1].VisitStatus;
      }
      ApiService.testReviewStatus(visit.ID, newStatus)
        .then((data) => {
          this.$store.dispatch(FETCH_VISIT, {
            visitID: visit.ID,
            addToStore: true,
          });
        })
        .catch((e) => {
          this.$notify({
            type: "danger",
            duration: NOTIFICATION_DURATION_ERROR,
            text: "Review.View.Messages.13", // TODO: own text?
          });
        });
    },
  },
};
</script>

<style scoped></style>

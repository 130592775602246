<template>
  <div>
    <label :for="label" class="small text-muted">
      {{ label | localized }}
    </label>
    <div>
      <div class="input-group input-group-sm">
        <b-dropdown
          class="input-group-prepend dropdown-filter"
          variant="outline-secondary"
          slot="prepend"
          ><template slot="button-content">
            <template v-if="filterIsInactive">
              <div class="fas fa-calendar-alt"></div>
            </template>
            <template v-if="relativeIsToday">
              <div class="fas fa-calendar-day"></div>
            </template>
            <template v-if="relativeIsWeek">
              <div class="fas fa-calendar-week"></div>
            </template>
            <template v-if="relativeIsMonth">
              <div class="fas fa-calendar"></div>
            </template>
            <template v-if="relativeIsLast7Days">
              <b>7</b>
            </template>
            <template v-if="relativeIsLast30Days">
              <b>30</b>
            </template>
            <template v-if="relativeIsExact">
              <div class="fas fa-sliders-h"></div>
            </template>
          </template>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="clearRange()"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="filterIsInactive" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <i class="fas fa-calendar-alt"></i>
              </div>
              <div>{{ "Review.View.CreateReport.7" | localized }}</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="setRelative('today')"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="relativeIsToday" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <i class="fas fa-calendar-day"></i>
              </div>
              <div>{{ "Review.View.ReportSearch.22" | localized }}</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="setRelative('week')"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="relativeIsWeek" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <i class="fas fa-calendar-week"></i>
              </div>
              <div>{{ "Review.View.ReportSearch.21" | localized }}</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="setRelative('month')"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="relativeIsMonth" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <i class="fas fa-calendar"></i>
              </div>
              <div>{{ "Review.View.ReportSearch.20" | localized }}</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="setRelative('last7Days')"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="relativeIsLast7Days" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <b>7</b>
              </div>
              <div>{{ "Review.View.ReportSearch.52" | localized }}</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="setRelative('last30Days')"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="relativeIsLast30Days" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <b>30</b>
              </div>
              <div>{{ "Review.View.ReportSearch.53" | localized }}</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            class="dropdown-selectable-item"
            @click.native="setRelative('exact')"
          >
            <div class="d-flex dropdown-item-content">
              <div class="d-flex dropdown-item-check-wrapper">
                <i v-if="relativeIsExact" class="fas fa-check"></i>
              </div>
              <div class="d-flex dropdown-item-icon-wrapper">
                <i class="fas fa-sliders-h"></i>
              </div>
              <div>{{ "Review.View.ReportSearch.54" | localized }}</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <v-date-picker
          v-model="from"
          :locale="locale"
          :popover="{ visibility: 'click' }"
          :attributes="pickerAttributes"
          v-on:dayclick="setFromRange"
          class="form-control form-control-sm"
          ><input
            id="input-date-from"
            type="text"
            slot-scope="{ inputProps, inputEvents, updateValue }"
            class="w-100 h-100 border-0 bv-no-focus-ring"
            style="background-color: transparent; color: inherit"
            v-bind="inputProps"
            v-on="inputEvents"
            @keypress.13.prevent="updateValue()"
            v-on:change="setRelative('exact')"
          />
        </v-date-picker>
        <b-input-group-append is-text>
          {{ "Review.View.ReportSearch.45" | localized }}
        </b-input-group-append>
        <v-date-picker
          v-model="to"
          :locale="locale"
          :popover="{ visibility: 'click' }"
          :attributes="pickerAttributes"
          v-on:dayclick="setToRange"
          class="form-control form-control-sm input-group-append"
          ><input
            id="input-date-to"
            type="text"
            slot-scope="{ inputProps, inputEvents, updateValue }"
            class="w-100 h-100 border-0 bv-no-focus-ring"
            style="background-color: transparent; color: inherit"
            v-bind="inputProps"
            v-on="inputEvents"
            @keypress.13.prevent="updateValue()"
            v-on:change="setRelative('exact')"
          />
        </v-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  DATE_FORMAT,
  DATE_RELATIVE_EXACT,
  DATE_RELATIVE_MONTH,
  DATE_RELATIVE_TODAY,
  DATE_RELATIVE_WEEK,
  DATE_RELATIVE_LAST_30_DAYS,
  DATE_RELATIVE_LAST_7_DAYS,
} from "@/common/constants";
import { convertMomentToDate } from "../../../store/util";

export default {
  name: "FilterDate",
  props: {
    label: String,
    initialValue: Object,
  },
  data() {
    return {
      relative: null,
      from: null,
      to: null,
    };
  },
  computed: {
    pickerAttributes() {
      return [
        {
          highlight: { class: "range", fillMode: "none" },
          fillmode: "light",
          dates: [{ start: this.from, end: this.to }],
        },
      ];
    },
    locale() {
      // use the user language for date picker locale
      return {
        id: this.$store.getters.localizedLanguage.toLowerCase(),
        masks: {
          input: moment.localeData()._longDateFormat[DATE_FORMAT],
          weekdays: "WW",
        },
      };
    },
    filterIsInactive() {
      return this.relative == null;
    },
    relativeIsToday() {
      return this.relative === DATE_RELATIVE_TODAY;
    },
    relativeIsWeek() {
      return this.relative === DATE_RELATIVE_WEEK;
    },
    relativeIsMonth() {
      return this.relative === DATE_RELATIVE_MONTH;
    },
    relativeIsLast7Days() {
      return this.relative === DATE_RELATIVE_LAST_7_DAYS;
    },
    relativeIsLast30Days() {
      return this.relative === DATE_RELATIVE_LAST_30_DAYS;
    },
    relativeIsExact() {
      return this.relative === DATE_RELATIVE_EXACT;
    },
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(newValue) {
        this.from = convertMomentToDate(moment(newValue.from));
        this.to = convertMomentToDate(moment(newValue.to));
        this.relative = newValue.relative;
      },
    },
  },
  methods: {
    setToRange(day) {
      this.to = new Date(day.year, day.month - 1, day.day);
      this.setRelative(DATE_RELATIVE_EXACT);
    },
    setFromRange(day) {
      this.from = new Date(day.year, day.month - 1, day.day);
      this.setRelative(DATE_RELATIVE_EXACT);
    },
    setRelative(val) {
      this.$emit("update", {
        relative: val,
        from: this.from,
        to: this.to,
      });
    },
    clearRange() {
      this.$emit("update", {
        relative: null,
        from: null,
        to: null,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../_custom.scss";

.dropdown-item-check-wrapper {
  min-width: 32px;
  justify-content: center;
  align-items: center;
  color: map-get($theme-colors, "primary");
}

.dropdown-item-icon-wrapper {
  min-width: 32px;
  justify-content: center;
  align-items: center;
}
.dropdown-selectable-item {
  padding: 0;
}
.dropdown-item-content {
  padding: 4px 8px 4px 0;
}

div.vc-highlights:has(div.vc-highlight-base-end) {
  width: 10px;
}

.filter-group {
  background-color: $input-bg;
}
.range {
  border-color: $input-bg !important;
  border-radius: 100%;
}

.vc-reset {
  border-color: inherit !important;
  border-width: 1px !important;
}

.dropdown-filter {
  background-color: $input-bg !important;
}
.overlay-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.vc-bg-blue-600 {
  background-color: map-get($theme-colors, "primary") !important;
}
.vc-bg-blue-200 {
  background-color: $input-bg !important;
}

/* Conditional CSS for IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vc-popover-content-wrapper {
    top: 10px !important;
  }
  .vc-popover-content {
    padding-top: 10px;
  }
}
/* disable highligting of empty days from other month */
.vc-opacity-0 .vc-highlight {
  border-color: transparent !important;
}
/* Conditional CSS for IE https://github.com/nathanreyes/v-calendar/issues/303 */
.vc-container {
  .vc-grid-container {
    width: 100%;
    display: -ms-grid;
    &.vc-weeks {
      padding: 5px 6px 7px 6px;
    }
    .vc-day-content {
      min-height: 1.8rem;
      width: 2.1rem;
      height: 1.8rem;
      margin: 0.1rem auto;
    }
    .vc-highlight {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { lazy: "" },
      on: {
        show: _vm.onShow,
        hide: function ($event) {
          return _vm.$emit("hide", $event)
        },
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("localized")("Review.View.Sign.1")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-cancel-sign-modal", type: "button" },
            on: { click: _vm.handleCancel },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.Sign.3")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: {
              id: "btn-confirm-sign-modal",
              type: "button",
              disabled: _vm.busy,
            },
            on: { click: _vm.handleOK },
          },
          [
            _vm.busy
              ? _c("Spinner", {
                  attrs: { type: "border", small: "", color: "white" },
                })
              : _vm._e(),
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.Sign.2")) +
                "\n    "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showLogin
        ? _c("div", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleOK.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("ErrorMessage", {
                      attrs: { error: _vm.error, dismiss: _vm.hideError },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-3 col-form-label col-form-label-sm",
                          attrs: { for: "input-username-sign-modal" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("localized")("Review.View.Login.1"))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-9" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.username,
                              expression: "username",
                            },
                          ],
                          ref: "usernameInput",
                          staticClass: "form-control form-control-sm",
                          attrs: {
                            id: "input-username-sign-modal",
                            type: "text",
                            autocomplete: "username",
                          },
                          domProps: { value: _vm.username },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.username = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-3 col-form-label col-form-label-sm",
                          attrs: { for: "input-password-sign-modal" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("localized")("Review.View.Login.3"))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-9" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: {
                            id: "input-password-sign-modal",
                            type: "password",
                            autocomplete: "current-password",
                          },
                          domProps: { value: _vm.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.password = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _c("div", [
            _c("div", [
              _vm._v(
                _vm._s(_vm._f("localized")("Review.View.Sign.9")) +
                  " " +
                  _vm._s(_vm.username) +
                  "."
              ),
            ]),
          ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(_vm._s(_vm._f("localized")("Review.View.Sign.8")) + "."),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm._f("localized")("Review.View.Sign.7")))]),
      _vm._v(" "),
      _c("ReportStatusSelection", {
        attrs: {
          "initial-status": _vm.status,
          "available-states": _vm.availableStates,
        },
        on: {
          update: function ($event) {
            _vm.status = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
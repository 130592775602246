<template>
  <div>
    <label :for="label" class="small text-muted">
      {{ label | localized }}
    </label>
    <input
      :id="label"
      v-model="proxiedValue"
      type="text"
      class="form-control form-control-sm"
    />
  </div>
</template>

<script>
export default {
  name: "FilterText",
  props: {
    label: String,
    initialValue: String,
  },
  computed: {
    proxiedValue: {
      get() {
        return this.initialValue;
      },
      set(val) {
        this.$emit("update", val);
      },
    },
  },
};
</script>

<style scoped></style>

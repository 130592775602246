import moment from "moment";
import Vue from "vue";

import ApiService from "@/common/api.service";

import {
  RESET,
  FETCH_CONFIGURATION_PENDING,
  FETCH_CONFIGURATION_SUCCESS,
  FETCH_CONFIGURATION_ERROR,
  SET_WORKFLOW_CONFIGURATIONS,
  SET_SETTINGS,
  SET_FILTER_VALUES,
  SET_SEARCH_FILTER,
  SET_REPORT_TEMPLATES,
  INIT_DONE,
  SET_CURRENT_FILTER,
  ACTIVATE_BATCH_MODE,
  DEACTIVATE_BATCH_MODE,
  SET_LAST_AUTO_INTERPRETATION,
  SET_FILTER_CONFIGURATION,
  ACTIVATE_PATIENT_MODE,
  DEACTIVATE_PATIENT_MODE,
  SET_VERSION,
  ACTIVATE_DESKTOP_MODE,
  SET_WRAPPER_INFO,
  ACTIVATE_SPECIAL_REPORT_MODE,
  SET_SORT_ORDER,
  SET_SORT_BY,
  SET_CUSTOM_REPORT_LISTS,
  SET_TOKEN_LIFE_TIME,
  SET_SWITCH_TO_REPORT,
} from "@/store/mutations.type";
import {
  FETCH_VERSION,
  FETCH_CONFIGURATION,
  STORE_SEARCH,
  UPDATE_CURRENT_FILTER,
  CLEAR_CURRENT_FILTER,
  START_BATCH_MODE,
  STOP_BATCH_MODE,
  UPDATE_LAST_AUTO_INTERPRETATION,
  UPDATE_SETTINGS,
  INIT_COMPLETE,
  START_PATIENT_MODE,
  STOP_PATIENT_MODE,
  FETCH_VISITS,
  START_DESKTOP_MODE,
  START_SPECIAL_REPORT_MODE,
  SORT_BY,
  SORT_ORDER,
  ENABLE_SWITCH_TO_REPORT,
  DISABLE_SWITCH_TO_REPORT,
} from "@/store/actions.type";
import {
  FILTER_DATE,
  FILTER_FIRST_NAME,
  FILTER_HIS_ORDER_ID,
  FILTER_HIS_VISIT_ID,
  FILTER_LAST_NAME,
  FILTER_LOCATION,
  FILTER_OPERATOR,
  FILTER_EXTERNAL_PATIENT_ID,
  FILTER_PATIENT_TYPE,
  FILTER_PHYSICIAN,
  FILTER_REF_PHYSICIAN,
  FILTER_REPORT_STATUS_FINAL,
  FILTER_REPORT_STATUS_PRELIMINARY,
  FILTER_REPORT_STATUS_CORRECTION,
  FILTER_VISIT_STATUS,
  REPORT_STATUS_FINAL,
  REPORT_STATUS_CORRECTION,
  REPORT_STATUS_PRELIMINARY,
  NOTIFICATION_DURATION_ERROR,
  FILTER_SORT_ORDER,
  FILTER_SORT_BY,
  DATE_RELATIVE_MONTH,
  DATE_RELATIVE_TODAY,
  DATE_RELATIVE_WEEK,
  DATE_RELATIVE_EXACT,
  DATE_RELATIVE_LAST_7_DAYS,
  DATE_RELATIVE_LAST_30_DAYS,
} from "@/common/constants";
import { convertFilterToBackend, convertFilterToFrontend } from "@/store/util";

const initialState = () => {
  return {
    version: {
      SentrySuite: "dev",
      Revision: "dev",
      Build: "dev",
      MobileReview: true,
    },
    desktopMode: false,
    wrapperInfo: {
      version: 0, // version 1 is introduced in SeS 3.20
    },
    // null: not active, <patientID>: active with the set patient id
    patientMode: null,
    specialReportMode: false,
    initialized: false,
    // current state of filter components
    currentFilter: {
      [FILTER_DATE]: {
        relative: null,
        from: null,
        to: null,
      },
      [FILTER_PHYSICIAN]: null,
      [FILTER_LOCATION]: null,
      [FILTER_EXTERNAL_PATIENT_ID]: null,
      [FILTER_LAST_NAME]: null,
      [FILTER_FIRST_NAME]: null,
      [FILTER_PATIENT_TYPE]: null,
      [FILTER_OPERATOR]: null,
      [FILTER_REF_PHYSICIAN]: null,
      [FILTER_VISIT_STATUS]: [],
      [FILTER_REPORT_STATUS_PRELIMINARY]: null,
      [FILTER_REPORT_STATUS_FINAL]: null,
      [FILTER_REPORT_STATUS_CORRECTION]: null,
      [FILTER_HIS_VISIT_ID]: null,
      [FILTER_HIS_ORDER_ID]: null,
      [FILTER_SORT_ORDER]: "Descending",
      [FILTER_SORT_BY]: "LocalDate",
    },
    // used by the current search
    searchFilter: {
      [FILTER_DATE]: {
        relative: null,
        from: null,
        to: null,
      },
      [FILTER_PHYSICIAN]: null,
      [FILTER_LOCATION]: null,
      [FILTER_EXTERNAL_PATIENT_ID]: null,
      [FILTER_LAST_NAME]: null,
      [FILTER_FIRST_NAME]: null,
      [FILTER_PATIENT_TYPE]: null,
      [FILTER_OPERATOR]: null,
      [FILTER_REF_PHYSICIAN]: null,
      [FILTER_VISIT_STATUS]: [],
      [FILTER_REPORT_STATUS_PRELIMINARY]: null,
      [FILTER_REPORT_STATUS_FINAL]: null,
      [FILTER_REPORT_STATUS_CORRECTION]: null,
      [FILTER_HIS_VISIT_ID]: null,
      [FILTER_HIS_ORDER_ID]: null,
    },
    filterValues: {
      Operators: [],
      Physicians: [],
      RefPhysicians: [],
      Locations: [],
    },
    settings: {
      AllowCommentingOfExternalReports: false,
      AllowExternalReportUpload: false,
      AllowSendToHISWithoutComment: false,
      AllowSignReportWithoutComment: false,
      AllowTechCommentSignature: false,
      AllowDeleteSentReport: false,
      AutoLogoutTimeout: 0,
      AutoSendToHIS: false,
      HISStatusSequence: "F-C", // ["Final", "Correction"],
      HISActiveForMobile: false,
      HISSoftwareOption: false,
      HISDesktopSetting: false,
      IsFreezeEnabled: false,
      IsTestReviewEnabled: false,
      MaxImportFileSize: 0,
      UseLocationFilter: false,
      CommentTemplateSearch: "TypeAhead",
      IsArtiQActive: false,
      IsArtiQProvidePDF: true,
      ArtiQDisplayName: "ArtiQ_PFT",
    },
    tokenLifeTime: 0,
    lastAutoInterpretation: "",
    filterConfiguration: {
      Visible: [
        FILTER_DATE,
        FILTER_PHYSICIAN,
        FILTER_LOCATION,
        FILTER_EXTERNAL_PATIENT_ID,
      ],
      Hidden: [
        FILTER_LAST_NAME,
        FILTER_FIRST_NAME,
        FILTER_PATIENT_TYPE,
        FILTER_OPERATOR,
        FILTER_REF_PHYSICIAN,
        FILTER_REPORT_STATUS_PRELIMINARY,
        FILTER_REPORT_STATUS_FINAL,
        FILTER_REPORT_STATUS_CORRECTION,
        FILTER_VISIT_STATUS,
        FILTER_HIS_VISIT_ID,
        FILTER_HIS_ORDER_ID,
      ],
    },
    reportTemplates: [],
    customReportLists: {},
    workflowConfigurations: [],
    batchMode: false,
    switchToReport: false,
  };
};

const configurationNameRegex = /{([^,]*),[0-9]*,([0-9]*),/;

// getters
const getters = {
  isInitialized: (state) => state.initialized,
  version: (state) => {
    const { SentrySuite, Revision, Build } = state.version;
    return `Version: ${SentrySuite}, Revision: ${Revision}, Build: ${Build}`;
  },
  commentTemplateSearch: (state) => state.settings.CommentTemplateSearch,
  settings: (state) => state.settings,
  filterConfiguration: (state) => state.filterConfiguration,
  isDesktopMode: (state) => {
    return state.desktopMode;
  },
  wrapperVersion: (state) => {
    return state.wrapperInfo.version;
  },
  isMobileReviewEnabled: (state) => state.version.MobileReview,
  isPatientMode: (state) => state.patientMode,
  isSpecialReportMode: (state) => state.specialReportMode,
  isBatchMode: (state) => state.batchMode,
  visibleFilters: (state) => state.filterConfiguration.Visible,
  hiddenFilters: (state) => state.filterConfiguration.Hidden,
  availableLocations: (state) => state.filterValues.Locations,
  availableOperators: (state) => state.filterValues.Operators,
  availablePhysicians: (state) => state.filterValues.Physicians,
  availableReportTemplates: (state) => state.availableReportTemplates,
  availableRefPhysicians: (state) => state.filterValues.RefPhysicians,
  autoLogoutTimeout: (state) => state.settings.AutoLogoutTimeout,
  workflowConfiguration: (state) => (id) =>
    state.workflowConfigurations.find((c) => c.VisitStatus === id),
  activeWorkflowConfigurations: (state) => {
    return state.workflowConfigurations.filter((c) => c.IsUsed);
  },
  HISStatusSequence: (state) => {
    let hisStatusSequence = [REPORT_STATUS_FINAL, REPORT_STATUS_CORRECTION];
    if (state.settings.HISStatusSequence === "P-F-C") {
      hisStatusSequence.unshift(REPORT_STATUS_PRELIMINARY);
    }
    return hisStatusSequence;
  },
  isHISAvailable: (state, getters) => {
    if (getters.isDesktopMode) {
      return (
        state.settings.HISSoftwareOption && state.settings.HISDesktopSetting
      );
    }
    return (
      state.settings.HISSoftwareOption && state.settings.HISActiveForMobile
    );
  },
  isFreezeEnabled: (state) => state.settings.IsFreezeEnabled,
  isCommentingOfExternalReportsAllowed: (state) =>
    state.settings.AllowCommentingOfExternalReports,
  isSignReportWithoutCommentAllowed: (state) =>
    state.settings.AllowSignReportWithoutComment,
  isTechnicianSignAllowed: (state) => state.settings.AllowTechCommentSignature,
  isSendToHISWithoutCommentAllowed: (state) =>
    state.settings.AllowSendToHISWithoutComment,
  isExternalReportUploadAllowed: (state) =>
    state.settings.AllowExternalReportUpload,
  isDeleteReportAllowed: (state) => state.settings.AllowDeleteSentReport,
  isTestReviewEnabled: (state) => state.settings.IsTestReviewEnabled,
  isAutoSendToHISEnabled: (state) => state.settings.AutoSendToHIS,
  maxImportFileSize: (state) => state.settings.MaxImportFileSize,
  currentFilter: (state) => state.currentFilter,
  searchFilter: (state) => state.searchFilter,
  sortBy: (state) => state.currentFilter[FILTER_SORT_BY],
  sortOrder: (state) => state.currentFilter[FILTER_SORT_ORDER],
  currentFilterCount: (state) => {
    let count = 0;
    if (state.searchFilter[FILTER_DATE].relative) {
      count++;
    }
    if (state.searchFilter[FILTER_PHYSICIAN]) {
      count++;
    }
    if (state.searchFilter[FILTER_LOCATION]) {
      count++;
    }
    if (state.searchFilter[FILTER_EXTERNAL_PATIENT_ID]) {
      count++;
    }
    if (state.searchFilter[FILTER_LAST_NAME]) {
      count++;
    }
    if (state.searchFilter[FILTER_FIRST_NAME]) {
      count++;
    }
    if (state.searchFilter[FILTER_PATIENT_TYPE]) {
      count++;
    }
    if (state.searchFilter[FILTER_OPERATOR]) {
      count++;
    }
    if (state.searchFilter[FILTER_REF_PHYSICIAN]) {
      count++;
    }
    if (
      state.searchFilter[FILTER_VISIT_STATUS] &&
      state.searchFilter[FILTER_VISIT_STATUS].length > 0
    ) {
      count++;
    }
    if (state.searchFilter[FILTER_REPORT_STATUS_PRELIMINARY]) {
      count++;
    }
    if (state.searchFilter[FILTER_REPORT_STATUS_FINAL]) {
      count++;
    }
    if (state.searchFilter[FILTER_REPORT_STATUS_CORRECTION]) {
      count++;
    }
    if (state.searchFilter[FILTER_HIS_VISIT_ID]) {
      count++;
    }
    if (state.searchFilter[FILTER_HIS_ORDER_ID]) {
      count++;
    }
    return count;
  },
  reportTemplates: (state) => state.reportTemplates,
  customReportLists: (state) => state.customReportLists,
  lastAutoInterpretation: (state) => state.lastAutoInterpretation,
  tokenLifeTime: (state) => state.tokenLifeTime,
  switchToReport: (state) => state.switchToReport,
  isArtiQActive: (state) => state.settings.IsArtiQActive,
  isArtiQProvidePDF: (state) => state.settings.IsArtiQProvidePDF,
  artiQDisplayName: (state) => state.settings.ArtiQDisplayName,
};

// actions
const actions = {
  [FETCH_VERSION]({ commit }) {
    return ApiService.fetchVersion().then((data) => {
      const { MobileReview, SentrySuite, Build, Revision } = data;
      commit(SET_VERSION, {
        MobileReview,
        SentrySuite,
        Build,
        Revision,
      });
    });
  },
  [INIT_COMPLETE]({ commit }) {
    commit(INIT_DONE);
  },
  [FETCH_CONFIGURATION]({ commit }) {
    commit(FETCH_CONFIGURATION_PENDING);
    return ApiService.fetchConfiguration()
      .then((data) => {
        commit(SET_FILTER_VALUES, { filterValues: data.FilterValues });
        const currentFilter = convertFilterToFrontend(
          data.ActiveFilter,
          data.FilterValues.Locations,
          data.UseLocationFilter
        );
        commit(SET_SEARCH_FILTER, currentFilter);
        commit(SET_CURRENT_FILTER, currentFilter);

        // map array to object using the visit status as key
        data.WorkflowConfigurations.forEach((c) => {
          // convert name to text id and store as _key
          const match = configurationNameRegex.exec(c.Name);
          if (match) {
            // construct the correct language key
            c._key = `${match[1]}.${match[2]}`;
          } else {
            // otherwise use the Name property as the raw value
            // (this is required for custom texts in workflow configurations)
            c._key = c.Name;
          }
          // build img src
          c._src = c.Icon.replace("Organizer/", "");
          // build bg color
          c._bg = "#" + c.BgColor;
        });
        commit(SET_WORKFLOW_CONFIGURATIONS, {
          workflowConfigurations: data.WorkflowConfigurations,
        });
        commit(SET_FILTER_CONFIGURATION, {
          filterConfiguration: data.FilterConfiguration,
        });
        commit(SET_SETTINGS, {
          AllowCommentingOfExternalReports:
            data.AllowCommentingOfExternalReports,
          AllowExternalReportUpload: data.AllowExternalReportUpload,
          AllowSendToHISWithoutComment: data.AllowSendToHISWithoutComment,
          AllowSignReportWithoutComment: data.AllowSignReportWithoutComment,
          AllowTechCommentSignature: data.AllowTechCommentSignature,
          AllowDeleteSentReport: data.AllowDeleteSentReport,
          AutoLogoutTimeout: data.AutoLogoutTimeout,
          AutoSendToHIS: data.AutoSendToHIS,
          HISStatusSequence: data.HISStatusSequence,
          HISActiveForMobile: data.HISActiveForMobile,
          HISSoftwareOption: data.IsHISSoftwareOptionEnabled,
          HISDesktopSetting: data.IsHISDesktopSettingEnabled,
          IsFreezeEnabled: data.IsFreezeEnabled,
          IsTestReviewEnabled: data.IsTestReviewEnabled,
          MaxImportFileSize: data.MaxImportFileSize,
          UseLocationFilter: data.UseLocationFilter,
          CommentTemplateSearch: data.CommentTemplateSearch,
          IsArtiQActive: data.IsArtiQActive,
          IsArtiQProvidePDF: data.IsArtiQProvidePDF,
          ArtiQDisplayName: data.ArtiQDisplayName,
        });
        commit(SET_TOKEN_LIFE_TIME, data.TokenLifeTime);
        commit(SET_LAST_AUTO_INTERPRETATION, data.LastAutoInterpretation);
        const reportTemplates = data.ReportTemplates.sort((a, b) => {
          return a.Name.localeCompare(b.Name);
        });
        commit(SET_REPORT_TEMPLATES, reportTemplates);
        commit(SET_CUSTOM_REPORT_LISTS, data.CustomReportLists);
        commit(FETCH_CONFIGURATION_SUCCESS);
        console.log("fetching configuration success");
      })
      .catch((e) => {
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
        throw e;
      });
  },
  [STORE_SEARCH]({ commit, state }, search) {
    commit(SET_SEARCH_FILTER, search);
    const filter = convertFilterToBackend(
      search,
      state.filterValues.Locations,
      state.settings.UseLocationFilter
    );
    return ApiService.saveActiveFilter(filter)
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  [UPDATE_CURRENT_FILTER]({ commit }, filter) {
    commit(SET_CURRENT_FILTER, filter);
  },
  [CLEAR_CURRENT_FILTER]({ commit, state }) {
    commit(SET_CURRENT_FILTER, {
      [FILTER_DATE]: {
        relative: null,
        from: null,
        to: null,
      },
      [FILTER_PHYSICIAN]: null,
      [FILTER_LOCATION]: null,
      [FILTER_EXTERNAL_PATIENT_ID]: null,
      [FILTER_LAST_NAME]: null,
      [FILTER_FIRST_NAME]: null,
      [FILTER_PATIENT_TYPE]: null,
      [FILTER_OPERATOR]: null,
      [FILTER_REF_PHYSICIAN]: null,
      [FILTER_VISIT_STATUS]: [],
      [FILTER_REPORT_STATUS_PRELIMINARY]: null,
      [FILTER_REPORT_STATUS_FINAL]: null,
      [FILTER_REPORT_STATUS_CORRECTION]: null,
      [FILTER_HIS_VISIT_ID]: null,
      [FILTER_HIS_ORDER_ID]: null,
      [FILTER_SORT_ORDER]: state.currentFilter[FILTER_SORT_ORDER],
      [FILTER_SORT_BY]: state.currentFilter[FILTER_SORT_BY],
    });
  },
  [START_BATCH_MODE]({ commit }) {
    commit(ACTIVATE_BATCH_MODE);
  },
  [STOP_BATCH_MODE]({ commit }) {
    commit(DEACTIVATE_BATCH_MODE);
  },
  [START_PATIENT_MODE]({ commit, dispatch }, patientID) {
    commit(ACTIVATE_PATIENT_MODE, patientID);
    dispatch(FETCH_VISITS, { paging: false });
  },
  [STOP_PATIENT_MODE]({ commit, dispatch }) {
    commit(DEACTIVATE_PATIENT_MODE);
    dispatch(FETCH_VISITS, { paging: false });
  },
  [UPDATE_LAST_AUTO_INTERPRETATION]({ commit }, name) {
    commit(SET_LAST_AUTO_INTERPRETATION, name);
  },
  [UPDATE_SETTINGS]({ commit, state }, { settings, filterConfiguration }) {
    // remove HISActiveForMobile if desktop mode
    if (state.desktopMode) {
      delete settings.HISActiveForMobile;
    }
    return ApiService.saveSettings({ settings, filterConfiguration })
      .then((d) => {
        commit(SET_SETTINGS, settings);
        commit(SET_FILTER_CONFIGURATION, { filterConfiguration });
        Vue.notify({
          type: "success",
          text: "Review.View.Messages.14",
        });
      })
      .catch((e) => {
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
        throw e;
      });
  },
  [START_DESKTOP_MODE]({ commit }, { version }) {
    commit(ACTIVATE_DESKTOP_MODE);
    commit(SET_WRAPPER_INFO, { version });
  },
  [START_SPECIAL_REPORT_MODE]({ commit }) {
    commit(ACTIVATE_SPECIAL_REPORT_MODE);
  },
  [SORT_BY]({ commit }, { field }) {
    commit(SET_SORT_BY, field);
  },
  [SORT_ORDER]({ commit }, { order }) {
    commit(SET_SORT_ORDER, order);
  },
  [ENABLE_SWITCH_TO_REPORT]({ commit }) {
    commit(SET_SWITCH_TO_REPORT, true);
  },
  [DISABLE_SWITCH_TO_REPORT]({ commit }) {
    commit(SET_SWITCH_TO_REPORT, false);
  },
};

// mutations
const mutations = {
  [RESET](state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      // skip version
      if (key !== "version") {
        state[key] = s[key];
      }
    });
  },
  [SET_VERSION](state, version) {
    state.version = version;
  },
  [FETCH_CONFIGURATION_PENDING](state) {
    console.log(`fetching configuration`);
    state.initialized = false;
  },
  [FETCH_CONFIGURATION_SUCCESS](state) {
    state.initialized = true;
  },
  [FETCH_CONFIGURATION_ERROR](state, err) {
    console.log("fetching configuration failed:", err);
    state.initialized = true;
  },
  [SET_FILTER_VALUES](state, { filterValues }) {
    // sort filter value entries
    filterValues.Physicians.sort((a, b) => a.Name.localeCompare(b.Name));
    filterValues.RefPhysicians.sort((a, b) => a.Name.localeCompare(b.Name));
    filterValues.Operators.sort((a, b) => a.Name.localeCompare(b.Name));
    state.filterValues = filterValues;
  },
  [SET_WORKFLOW_CONFIGURATIONS](state, { workflowConfigurations }) {
    state.workflowConfigurations = workflowConfigurations;
  },
  [SET_SETTINGS](state, settings) {
    state.settings = {
      ...settings,
    };
  },
  [SET_TOKEN_LIFE_TIME](state, tokenLifeTime) {
    state.tokenLifeTime = tokenLifeTime;
  },
  [SET_FILTER_CONFIGURATION](state, { filterConfiguration }) {
    state.filterConfiguration = filterConfiguration;
    if (!state.filterConfiguration) {
      state.filterConfiguration = initialState().filterConfiguration;
    }
  },
  [SET_SEARCH_FILTER](state, filter) {
    // don't save SortOrder and SortBy in the search filter so we don't send it to the backend
    const { SortOrder, SortBy, ...f } = filter;
    state.searchFilter = { ...f };
  },
  [SET_CURRENT_FILTER](state, filter) {
    switch (filter[FILTER_DATE].relative) {
      case DATE_RELATIVE_TODAY:
        filter[FILTER_DATE].from = moment().startOf("day").format();
        filter[FILTER_DATE].to = moment().endOf("day").format();
        break;
      case DATE_RELATIVE_WEEK:
        filter[FILTER_DATE].from = moment().startOf("week").format();
        filter[FILTER_DATE].to = moment().endOf("week").format();
        break;
      case DATE_RELATIVE_MONTH:
        filter[FILTER_DATE].from = moment().startOf("month").format();
        filter[FILTER_DATE].to = moment().endOf("month").format();
        break;
      case DATE_RELATIVE_LAST_7_DAYS:
        filter[FILTER_DATE].from = moment()
          .subtract(6, "days")
          .startOf("day")
          .format();
        filter[FILTER_DATE].to = moment().endOf("day").format();
        break;
      case DATE_RELATIVE_LAST_30_DAYS:
        filter[FILTER_DATE].from = moment()
          .subtract(29, "days")
          .startOf("day")
          .format();
        filter[FILTER_DATE].to = moment().endOf("day").format();
        break;
      case DATE_RELATIVE_EXACT:
        filter[FILTER_DATE].from = moment(filter[FILTER_DATE].from)
          .startOf("day")
          .format();
        filter[FILTER_DATE].to = moment(filter[FILTER_DATE].to)
          .endOf("day")
          .format();
        break;
    }
    state.currentFilter = { ...filter };
    if (filter[FILTER_SORT_BY] === "") {
      state.currentFilter[FILTER_SORT_BY] = "LocalDate";
    }
    if (filter[FILTER_SORT_ORDER] === "") {
      state.currentFilter[FILTER_SORT_ORDER] = "Descending";
    }
  },
  [SET_REPORT_TEMPLATES](state, rts) {
    state.reportTemplates = rts;
  },
  [SET_CUSTOM_REPORT_LISTS](state, crl) {
    state.customReportLists = crl;
  },
  [INIT_DONE](state) {
    state.initialized = true;
  },
  [ACTIVATE_BATCH_MODE](state) {
    state.batchMode = true;
  },
  [DEACTIVATE_BATCH_MODE](state) {
    state.batchMode = false;
  },
  [ACTIVATE_PATIENT_MODE](state, patientID) {
    state.patientMode = patientID;
  },
  [DEACTIVATE_PATIENT_MODE](state) {
    state.patientMode = null;
  },
  [SET_LAST_AUTO_INTERPRETATION](state, name) {
    state.lastAutoInterpretation = name;
  },
  [ACTIVATE_DESKTOP_MODE](state) {
    state.desktopMode = true;
  },
  [SET_WRAPPER_INFO](state, { version }) {
    state.wrapperInfo = { version };
  },
  [ACTIVATE_SPECIAL_REPORT_MODE](state) {
    state.specialReportMode = true;
  },
  [SET_SORT_BY](state, field) {
    Vue.set(state.currentFilter, FILTER_SORT_BY, field);
  },
  [SET_SORT_ORDER](state, order) {
    Vue.set(state.currentFilter, FILTER_SORT_ORDER, order);
  },
  [SET_SWITCH_TO_REPORT](state, switchToReport) {
    state.switchToReport = switchToReport;
  },
};

export default {
  state: initialState(),
  actions,
  mutations,
  getters,
};

<template>
  <div class="container-fluid bg-white">
    <div class="d-flex w-100 justify-content-between">
      <img id="review-logo" src="@/assets/img/logo-header.png" />
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <i class="fas fa-user"></i> {{ account.USER_DISPLAY_NAME }}
        </div>
        <button
          id="btn-settings"
          class="mr-1 btn btn-sm btn-outline-secondary"
          @click.prevent="goToSettings"
        >
          <span class="fas fa-cog"></span>
        </button>
        <button
          id="btn-logout"
          class="btn btn-sm btn-outline-secondary"
          @click="logout"
        >
          <span class="fas fa-sign-out-alt"></span>
          <timer :time="logoutTime" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from "@/components/Timer";
import { LOGOUT, CLEAR_ALL } from "@/store/actions.type";

export default {
  name: "Header",
  components: { Timer },
  props: {
    lastActivity: {
      type: Number,
    },
  },
  data() {
    return {
      logoutTimer: 0,
      logoutTime: this.autoLogoutTimeout,
    };
  },
  watch: {
    lastActivity() {
      this.logoutTime = this.autoLogoutTimeout;
      // clear last interval before setting a new one
      clearInterval(this.logoutTimer);
      this.logoutTimer = setInterval(this.autoLogout, 1000);
    },
  },
  mounted() {
    this.logoutTime = this.autoLogoutTimeout;
    this.logoutTimer = setInterval(this.autoLogout, 1000);
  },
  beforeDestroy() {
    clearInterval(this.logoutTimer);
  },
  computed: {
    autoLogoutTimeout() {
      // return milliseconds instead of minutes
      return this.$store.getters.autoLogoutTimeout * 60 * 1000;
    },
    account() {
      return this.$store.getters.tokenData;
    },
  },
  methods: {
    autoLogout() {
      this.logoutTime -= 1000;
      if (this.logoutTime <= 0) {
        this.logout();
      }
    },
    goToSettings() {
      this.$router.push({ name: "settings" }).catch((err) => console.log(err));
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "login" }).catch((err) => console.log(err));
      });
      this.$store.dispatch(CLEAR_ALL, null, { root: true });
    },
  },
};
</script>

<style scoped></style>

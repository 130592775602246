<template>
  <span>{{ display }}</span>
</template>

<script>
import padStart from "lodash/padStart";
import moment from "moment";

export default {
  name: "Timer",
  props: {
    time: {
      type: Number,
    },
  },
  computed: {
    display() {
      const duration = moment.duration(this.time);
      // duration.get("hours") returns values from 0-23, but we want to
      // display 24:00:00 instead of 00:00:00
      const hours = Math.trunc(duration.asHours());
      const minutes = duration.get("minutes");
      const seconds = duration.get("seconds");

      return `(${this.format(hours)}:${this.format(minutes)}:${this.format(
        seconds
      )})`;
    },
  },
  methods: {
    format(x) {
      return padStart(x, 2, "0");
    },
  },
};
</script>

<style scoped></style>

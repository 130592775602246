<template>
  <b-modal
    ref="modal"
    lazy
    id="save-filter-modal"
    @show="clear"
    @shown="focusInput"
    @hide="$emit('hide', $event)"
  >
    <template slot="modal-title">
      <h5 class="modal-title">
        {{ "Review.View.ReportSearch.38" | localized }}
      </h5>
    </template>
    <template slot="modal-footer">
      <button
        id="btn-cancel-save-filter"
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.ReportSearch.28" | localized }}
      </button>
      <button
        id="btn-confirm-save-filter"
        type="button"
        class="btn btn-sm btn-primary"
        :class="{ disabled: disabled }"
        @click="handleOK"
      >
        {{ "Review.View.ReportSearch.33" | localized }}
      </button>
    </template>
    <!-- content -->
    <div>
      <form @submit.prevent="handleOK">
        <label
          class="m-0 w-100 col-form-label-sm text-muted"
          for="input-save-filter"
        >
          {{ "Review.View.ReportSearch.34" | localized }}
        </label>
        <input
          id="input-save-filter"
          v-model="filterName"
          ref="saveFilter"
          type="text"
          class="form-control"
        />
        <div v-if="nameExists" class="mt-2 alert alert-warning">
          {{ "Review.View.ReportSearch.27" | localized }}
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { FETCH_SAVED_FILTERS, SAVE_FILTER } from "@/store/actions.type";

export default {
  name: "FilterModalSave",
  data() {
    return {
      filterName: "",
      // prevent flickering of "nameExists" message
      saved: false,
    };
  },
  computed: {
    currentFilter() {
      return this.$store.getters.currentFilter;
    },
    nameExists() {
      if (this.saved) {
        return false;
      }
      const filters = this.$store.getters.sortedSavedFilterKeys;
      return filters.includes(this.filterName);
    },
    disabled() {
      return this.filterName.trim() === "";
    },
  },
  methods: {
    show() {
      this.$store.dispatch(FETCH_SAVED_FILTERS);
      this.$refs.modal.show();
    },
    handleOK() {
      // Make sure we cannot save on 'Enter' while disabled.
      if (this.disabled) {
        return;
      }
      this.saved = true;
      this.$store.dispatch(SAVE_FILTER, this.filterName);
      this.$refs.modal.hide("ok");
    },
    handleCancel() {
      this.$refs.modal.hide("cancel");
    },
    focusInput() {
      this.$refs.saveFilter.focus();
    },
    clear() {
      this.saved = false;
      this.filterName = "";
    },
  },
};
</script>

<style scoped></style>

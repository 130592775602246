<template>
  <div class="d-flex align-items-center">
    <div id="batch-count" class="mr-2" style="border: #333333">
      {{ currentVisitIndex + 1 }}/{{ visits.length }}
    </div>
    <div class="btn-group btn-group-sm">
      <button
        id="btn-previous-batch"
        class="btn btn-outline-secondary"
        @click="onPrevious"
        :disabled="!prevVisit || disabled"
      >
        <i class="fas fa-arrow-left"></i>
      </button>
      <button
        id="btn-next-batch"
        class="btn btn-outline-secondary"
        @click="onNext"
        :disabled="!nextVisit || disabled"
      >
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BatchControls",
  props: {
    disabled: { Type: Boolean },
  },
  methods: {
    onPrevious() {
      this.$router
        .replace({
          name: "report",
          params: {
            visitID: this.prevVisit.ID,
            reportID: this.prevVisit.Reports[0].ID,
          },
        })
        .catch((err) => console.log(err));
    },
    onNext() {
      this.$router
        .replace({
          name: "report",
          params: {
            visitID: this.nextVisit.ID,
            reportID: this.nextVisit.Reports[0].ID,
          },
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    selectedVisitID() {
      return this.$store.getters.selection.visitID;
    },
    visits() {
      return this.$store.getters.batchModeVisits;
    },
    nextVisit() {
      return this.$store.getters.nextVisit;
    },
    prevVisit() {
      return this.$store.getters.prevVisit;
    },
    currentVisitIndex() {
      return this.visits.findIndex((v) => v.ID === this.selectedVisitID);
    },
  },
};
</script>

<style scoped></style>

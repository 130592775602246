var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex position-relative h-100",
      style: { minHeight: _vm.height + "px", maxHeight: _vm.height + "px" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "h-100 w-100 position-absolute bg-light d-flex justify-content-center align-items-center",
              staticStyle: { "z-index": "11", opacity: "0.4" },
            },
            [_c("Spinner", { attrs: { type: "border" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "editor-container flex-grow-1 h-100",
          style: { minHeight: _vm.height + "px", maxHeight: _vm.height + "px" },
          attrs: { id: `container-${_vm.id}` },
        },
        [
          _c(
            "div",
            {
              staticClass: "editor-toolbar",
              attrs: { id: `toolbar-${_vm.id}` },
            },
            [
              _c("button", {
                staticClass: "ql-bold",
                attrs: { id: `btn-toolbar-${_vm.id}-bold` },
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "ql-italic",
                attrs: { id: `btn-toolbar-${_vm.id}-italic` },
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "ql-underline",
                attrs: { id: `btn-toolbar-${_vm.id}-underline` },
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _vm.interpretations
                    ? [
                        _vm.isArtiQActive
                          ? _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.window",
                                    modifiers: { hover: true, window: true },
                                  },
                                ],
                                staticClass:
                                  "no-outline d-flex align-items-center justify-content-center",
                                attrs: {
                                  title: _vm._f("localized")(
                                    "Review.View.Report.42"
                                  ),
                                },
                                on: { click: _vm.insertArtiQ },
                              },
                              [_c("span", { staticClass: "fab fa-artiq" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lastAutoInterpretation
                          ? _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.window",
                                    modifiers: { hover: true, window: true },
                                  },
                                ],
                                staticClass:
                                  "no-outline d-flex align-items-center justify-content-center",
                                attrs: {
                                  title: _vm._f("localized")(
                                    "Review.View.Report.23"
                                  ),
                                },
                                on: { click: _vm.insertLastAutoInterpretation },
                              },
                              [_c("span", { staticClass: "fas fa-paste" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.window",
                                modifiers: { hover: true, window: true },
                              },
                            ],
                            staticClass:
                              "no-outline d-flex align-items-center justify-content-center",
                            class: { "ql-active": _vm.expand === 1 },
                            attrs: {
                              title: _vm._f("localized")(
                                "Review.View.Report.20"
                              ),
                            },
                            on: { click: _vm.toggleAutoInterpretations },
                          },
                          [_c("span", { staticClass: "fas fa-graduation-cap" })]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.window",
                          modifiers: { hover: true, window: true },
                        },
                      ],
                      staticClass:
                        "no-outline d-flex align-items-center justify-content-center",
                      class: { "ql-active": _vm.expand === 0 },
                      attrs: {
                        title: _vm._f("localized")("Review.View.Report.24"),
                      },
                      on: { click: _vm.toggleComments },
                    },
                    [_c("span", { staticClass: "fas fa-list-alt" })]
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            ref: "editor",
            on: {
              click: _vm.clickAway,
              keydown: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 49)
                  return null
                if (!$event.ctrlKey) return null
                if ($event.shiftKey || $event.altKey || $event.metaKey)
                  return null
                $event.preventDefault()
                return (() => {}).apply(null, arguments)
              },
              keyup: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 49)
                  return null
                if (!$event.ctrlKey) return null
                if ($event.shiftKey || $event.altKey || $event.metaKey)
                  return null
                $event.preventDefault()
                return _vm.toggleComments.apply(null, arguments)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.expand === 0
        ? _c("EditorInsertPanel", {
            key: "commentTemplates",
            ref: "insertPanel",
            attrs: { quill: _vm.quill, values: _vm.commentTemplates },
            on: { hide: _vm.clickAway, selected: _vm.insertComment },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.expand === 1
        ? _c("EditorInsertPanel", {
            key: "autoInterpretations",
            attrs: { quill: _vm.quill, values: _vm.autoInterpretations },
            on: { hide: _vm.clickAway, selected: _vm.insertAutoInterpretation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("select", { staticClass: "ql-font" }, [
      _c("option", { attrs: { value: "Arial", selected: "" } }, [
        _vm._v("Arial"),
      ]),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "Comic Sans MS, ChalkboardSE-Regular" } },
        [_vm._v("\n          Comic Sans MS\n        ")]
      ),
      _vm._v(" "),
      _c("option", { attrs: { value: "Courier New" } }, [
        _vm._v("Courier New"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Impact, DINCondensed-Bold" } }, [
        _vm._v("Impact"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Lucida Grande, Lucida Sans Unicode" } }, [
        _vm._v("\n          Lucida Grande\n        "),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Palatino" } }, [_vm._v("Palatino")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tahoma, Helvetica" } }, [
        _vm._v("Tahoma"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Times New Roman" } }, [
        _vm._v("Times New Roman"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Trebuchet MS" } }, [
        _vm._v("Trebuchet MS"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Verdana" } }, [_vm._v("Verdana")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("select", { staticClass: "ql-size" }, [
      _c("option", { attrs: { value: "8px" } }, [_vm._v("8")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "9px" } }, [_vm._v("9")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "10px" } }, [_vm._v("10")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "11px" } }, [_vm._v("11")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "12px", selected: "" } }, [_vm._v("12")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "14px" } }, [_vm._v("14")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "16px" } }, [_vm._v("16")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "18px" } }, [_vm._v("18")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "20px" } }, [_vm._v("20")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "22px" } }, [_vm._v("22")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "24px" } }, [_vm._v("24")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "26px" } }, [_vm._v("26")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "28px" } }, [_vm._v("28")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "36px" } }, [_vm._v("36")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "48px" } }, [_vm._v("48")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "72px" } }, [_vm._v("72")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
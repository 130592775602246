<template>
  <div>
    <b-modal
      ref="modal"
      lazy
      id="load-filter-modal"
      @show="clear"
      @hide="$emit('hide', $event)"
    >
      <template slot="modal-title">
        <h5 class="modal-title">
          {{ "Review.View.ReportSearch.39" | localized }}
        </h5>
      </template>
      <template slot="modal-footer">
        <button
          id="btn-cancel-load-filter"
          type="button"
          class="btn btn-sm btn-outline-secondary"
          @click="handleCancel"
        >
          {{ "Review.View.ReportSearch.28" | localized }}
        </button>
        <button
          id="btn-confirm-load-filter"
          type="button"
          class="btn btn-sm btn-primary"
          :disabled="selection === '' || savedFilters.length === 0"
          @click="handleOK"
        >
          {{ "Review.View.ReportSearch.30" | localized }}
        </button>
      </template>
      <!-- content -->
      <div class="list-group">
        <a
          v-for="filter in savedFilters"
          :key="filter"
          :id="`btn-load-filter-${filter}`"
          class="list-group-item list-group-item-action d-flex p-2 pl-3 justify-content-between align-items-center"
          :class="{ 'text-white active': selection === filter }"
          @click="select(filter)"
        >
          {{ filter }}
          <button
            :id="`btn-delete-filter-${filter}`"
            class="btn btn-sm"
            :class="{
              'btn-primary': selection === filter,
              'btn-outline-secondary': selection !== filter,
            }"
            @click.stop="showDeleteFilterModal(filter)"
          >
            <span id="btn-delete-filter" class="fas fa-trash-alt"></span>
          </button>
        </a>
      </div>
      <div v-if="savedFilters.length === 0" class="text-center text-muted">
        <small>{{ "Review.View.ReportSearch.32" | localized }}</small>
      </div>
    </b-modal>

    <FilterModalDelete ref="deleteFilterModal" @hide="handleDeleteFilter" />
  </div>
</template>

<script>
import {
  DELETE_FILTER,
  FETCH_SAVED_FILTERS,
  FETCH_VISITS,
  STORE_SEARCH,
  UPDATE_CURRENT_FILTER,
} from "@/store/actions.type";

import FilterModalDelete from "@/components/views/visits/FilterModalDelete";

export default {
  name: "FilterModalLoad",
  components: {
    FilterModalDelete,
  },
  data() {
    return {
      selection: "",
      deleteSelection: "",
    };
  },
  computed: {
    savedFilters() {
      return this.$store.getters.sortedSavedFilterKeys;
    },
    savedFilterValue() {
      return this.$store.getters.savedFilterValue(this.selection);
    },
  },
  methods: {
    show() {
      this.$store.dispatch(FETCH_SAVED_FILTERS);
      this.$refs.modal.show();
    },
    handleOK() {
      this.$store
        .dispatch(UPDATE_CURRENT_FILTER, { ...this.savedFilterValue })
        .then(() => {
          return this.$store.dispatch(STORE_SEARCH, {
            ...this.savedFilterValue,
          });
        })
        .then(() => {
          return this.$store.dispatch(FETCH_VISITS, {
            paging: false,
          });
        });
      this.$refs.modal.hide("ok");
    },
    handleCancel() {
      this.$refs.modal.hide("cancel");
    },
    select(filterName) {
      this.selection = filterName;
    },
    handleDeleteFilter($event) {
      console.log("handleDeleteFilter", $event);
      if ($event.trigger === "ok") {
        this.$store.dispatch(DELETE_FILTER, this.deleteSelection);
      }
      this.deleteSelection = "";
    },
    clear() {
      this.selection = "";
      this.deleteSelection = "";
    },
    showDeleteFilterModal(filter) {
      this.deleteSelection = filter;
      this.$refs.deleteFilterModal.show();
    },
  },
};
</script>

<style scoped></style>

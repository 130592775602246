import Vue from "vue";

import ApiService from "@/common/api.service";
import { NOTIFICATION_DURATION_ERROR } from "@/common/constants";
import {
  DELETE_FILTER_SUCCESS,
  DELETE_FILTER_ERROR,
  FETCH_SAVED_FILTERS_PENDING,
  FETCH_SAVED_FILTERS_SUCCESS,
  FETCH_SAVED_FILTERS_ERROR,
  SAVE_FILTER_ERROR,
  SAVE_FILTER_SUCCESS,
  RESET,
} from "@/store/mutations.type";
import {
  DELETE_FILTER,
  FETCH_SAVED_FILTERS,
  SAVE_FILTER,
} from "@/store/actions.type";
import { convertFilterToBackend, convertFilterToFrontend } from "./util";

const initialState = () => {
  return {
    loading: false,
    savedFilters: {},
  };
};

// getters
const getters = {
  sortedSavedFilterKeys: (state) =>
    Object.keys(state.savedFilters).sort((a, b) => {
      return a.localeCompare(b);
    }),
  savedFilterValue: (state, getters) => (key) => {
    const locations = getters.availableLocations;
    const useLocationFilter = getters.settings.UseLocationFilter;
    const f = state.savedFilters[key];
    if (!f) {
      return null;
    }
    return convertFilterToFrontend(f, locations, useLocationFilter);
  },
};

// store last promise to allow cancellation
let fetchFiltersPromise;
// actions
const actions = {
  [FETCH_SAVED_FILTERS]({ commit }) {
    commit(FETCH_SAVED_FILTERS_PENDING);
    fetchFiltersPromise = ApiService.fetchFilters()
      .then((data) => {
        commit(FETCH_SAVED_FILTERS_SUCCESS, { filters: data });
      })
      .catch((err) => {
        commit(FETCH_SAVED_FILTERS_ERROR, err);
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
      });
  },
  [SAVE_FILTER]({ commit, getters }, name) {
    const filter = getters.currentFilter;
    const locations = getters.availableLocations;
    const useLocationFilter = getters.settings.UseLocationFilter;
    const f = convertFilterToBackend(filter, locations, useLocationFilter);
    return ApiService.saveFilter(name, f)
      .then((data) => {
        commit(SAVE_FILTER_SUCCESS, { filters: data });
        Vue.notify({
          type: "success",
          title: name,
          text: "Review.View.ReportSearch.36",
        });
      })
      .catch((err) => {
        commit(SAVE_FILTER_ERROR, err);
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: name,
          text: "Review.View.ReportSearch.35",
        });
      });
  },
  [DELETE_FILTER]({ commit }, name) {
    ApiService.deleteFilter(name)
      .then(() => {
        commit(DELETE_FILTER_SUCCESS, name);
      })
      .catch((e) => {
        commit(DELETE_FILTER_ERROR, e);
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: name,
          text: "Review.View.ReportSearch.29",
        });
      });
  },
};

// mutations
const mutations = {
  [RESET](state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  [FETCH_SAVED_FILTERS_PENDING](state) {
    console.log(`fetching saved filters`);
    state.loading = true;
  },
  [FETCH_SAVED_FILTERS_SUCCESS](state, { filters }) {
    state.savedFilters = filters;
    state.loading = false;
  },
  [FETCH_SAVED_FILTERS_ERROR](state, err) {
    console.log("fetching saved filters failed:", err);
    state.loading = false;
  },
  [SAVE_FILTER_SUCCESS](state, { filters }) {
    state.savedFilters = filters;
  },
  [SAVE_FILTER_ERROR](state, err) {
    console.log("failed to save filter:", err);
  },
  [DELETE_FILTER_SUCCESS](state, name) {
    Vue.delete(state.savedFilters, name);
  },
  [DELETE_FILTER_ERROR](state, err) {
    console.log("failed to delete filter:", err);
  },
};

export default {
  state: initialState(),
  actions,
  mutations,
  getters,
};

<template>
  <div
    class="bg-white border-bottom pb-2 container-fluid"
    style="border-bottom-width: 2px !important"
  >
    <form @submit.prevent="search">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-10">
              <FilterForm :filters="visibleFilters" v-model="currentFilter" />
            </div>
            <div class="col-2">
              <label class="small text-muted">
                <b>{{ activeFilterCount }}</b>
                {{ "Review.View.Visit.12" | localized }}
              </label>
              <div class="btn-group d-flex">
                <button
                  id="btn-search"
                  class="w-100 btn btn-sm btn-outline-secondary"
                  @click.prevent="search"
                >
                  <span class="fas fa-search"></span>
                </button>
                <button
                  id="btn-toggle-filter"
                  class="w-100 btn btn-sm btn-outline-secondary"
                  @click.prevent="toggleAdvanced"
                >
                  <span
                    class="fas"
                    :class="{
                      'fa-plus': !showAdvanced,
                      'fa-minus': showAdvanced,
                    }"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="showAdvanced">
        <div class="col-10">
          <FilterForm :filters="hiddenFilters" v-model="currentFilter" />
        </div>
        <div class="col-2">
          <label class="small text-muted">
            {{ "Review.View.ReportSearch.24" | localized }}
          </label>
          <button
            id="btn-use-and-hide"
            class="w-100 mb-2 btn btn-sm btn-outline-secondary"
            @click.prevent="useAndHide"
          >
            {{ "Review.View.ReportSearch.26" | localized }}
          </button>
          <button
            id="btn-clear-all"
            class="w-100 btn btn-sm btn-outline-secondary"
            @click.prevent="clear"
          >
            {{ "Review.View.ReportSearch.25" | localized }}
          </button>
        </div>
      </div>
    </form>
    <div class="row mt-2">
      <div class="col-4">
        <slot name="pagination"></slot>
      </div>
      <div class="col-8 text-right">
        <div class="d-inline-block">
          <div class="form-inline">
            <Sort />
            <button
              id="btn-batch-mode"
              class="ml-2 btn btn-sm btn-primary"
              :disabled="isBatchModeDisabled"
              @click.prevent="startBatchMode"
            >
              <span class="fas fa-exchange-alt"></span>
              {{ "Review.View.ReportSearch.42" | localized }}
            </button>
            <div
              class="ml-1 btn-group btn-group-sm"
              role="group"
              aria-label="saved-filters"
            >
              <button
                id="btn-save-filter"
                class="btn btn-outline-secondary"
                @click="showSaveFilterModal"
              >
                {{ "Review.View.ReportSearch.33" | localized }}
              </button>
              <button
                id="btn-load-filter"
                class="btn btn-outline-secondary"
                @click="showLoadFilterModal"
              >
                {{ "Review.View.ReportSearch.39" | localized }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FilterModalSave ref="saveFilterModal" />
    <FilterModalLoad ref="loadFilterModal" />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

import FilterModalSave from "@/components/views/visits/FilterModalSave";
import FilterModalLoad from "@/components/views/visits/FilterModalLoad";

import {
  FETCH_VISITS,
  CLEAR_CURRENT_FILTER,
  STORE_SEARCH,
  START_BATCH_MODE,
} from "@/store/actions.type";
import FilterForm from "@/components/views/visits/FilterForm";
import Sort from "@/components/views/visits/Sort";

export default {
  name: "SearchBar",
  components: {
    FilterForm,
    FilterModalSave,
    FilterModalLoad,
    Sort,
  },
  data() {
    return {
      showAdvanced: false,
    };
  },
  computed: {
    currentFilter() {
      return cloneDeep(this.$store.getters.currentFilter);
    },
    visibleFilters() {
      return this.$store.getters.visibleFilters;
    },
    hiddenFilters() {
      return this.$store.getters.hiddenFilters;
    },
    activeFilterCount() {
      return this.$store.getters.currentFilterCount;
    },
    isBatchModeDisabled() {
      return this.$store.getters.batchModeVisits.length === 0;
    },
  },
  methods: {
    toggleAdvanced() {
      this.showAdvanced = !this.showAdvanced;
    },
    clear() {
      // clear the current search
      this.$store.dispatch(CLEAR_CURRENT_FILTER);
    },
    search() {
      // store the current search and fetch the visits
      this.$store.dispatch(STORE_SEARCH, { ...this.currentFilter }).then(() => {
        return this.$store.dispatch(FETCH_VISITS, {
          paging: false,
        });
      });
    },
    useAndHide() {
      this.showAdvanced = false;
      this.search();
    },
    startBatchMode() {
      this.$store.dispatch(START_BATCH_MODE);
      const visits = this.$store.getters.batchModeVisits;
      const visit = visits[0];
      this.$router
        .push({
          name: "report",
          params: { visitID: visit.ID, reportID: visit.Reports[0].ID },
        })
        .catch((err) => console.log(err));
    },
    showLoadFilterModal() {
      this.$refs.loadFilterModal.show();
    },
    showSaveFilterModal() {
      this.$refs.saveFilterModal.show();
    },
  },
};
</script>

<style scoped></style>

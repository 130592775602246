var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { size: "lg", lazy: "", id: "delete-report-modal" },
      on: {
        show: _vm.onShow,
        shown: _vm.focusInput,
        hide: function ($event) {
          return _vm.$emit("hide", $event)
        },
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("localized")("Review.View.DeleteReport.2")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-cancel-delete-report", type: "button" },
            on: { click: _vm.handleCancel },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.DeleteReport.5")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: {
              id: "btn-confirm-delete-report",
              type: "button",
              disabled: _vm.okButtonDisabled,
            },
            on: { click: _vm.handleOK },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.DeleteReport.6")) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("ErrorMessage", {
        attrs: { error: _vm.error, dismiss: _vm.hideError },
      }),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("localized")("Review.View.DeleteReport.3")) +
            "\n    " +
            _vm._s(`"${_vm.selectedReport.Filename}"`) +
            " ?\n  "
        ),
      ]),
      _vm._v(" "),
      !_vm.isDefaultUser
        ? _c("div", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleOK.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label",
                    attrs: { for: "input-password-delete-report" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("localized")("Review.View.DeleteReport.4")
                        ) +
                        ":\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    ref: "input",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "password",
                      id: "input-password-delete-report",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
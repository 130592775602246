import axios from "axios";

import JwtService from "@/common/jwt.service";

export default {
  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  fetchVersion() {
    const url = "api/version";
    return axios.get(url).then((response) => response.data);
  },
  login(username, password, token, workstationID) {
    const url = "api/login";
    return axios
      .post(url, {
        username,
        password,
        token,
        workstationID,
      })
      .then((response) => response.data);
  },
  changePasswordAllowed() {
    const url = "api/changePasswordAllowed";
    return axios.get(url).then((response) => response.data);
  },
  changePassword(username, password, newPassword) {
    const url = "api/changePassword";
    return axios
      .post(url, {
        username,
        password,
        newPassword,
      })
      .then((response) => response.data);
  },
  logout() {
    const url = "api/logout";
    return axios.get(url).then((response) => response.data);
  },
  fetchConfiguration() {
    const url = "api/configuration";
    return axios.get(url).then((response) => response.data);
  },
  fetchTexts(language, country) {
    const url = "api/texts";
    return axios
      .post(url, { language, country })
      .then((response) => response.data);
  },
  fetchVisits(filter) {
    const url = "api/visits";
    return axios.post(url, filter).then((response) => response.data);
  },
  fetchReportPDF(visitID, reportID) {
    const url = `api/visits/${visitID}/reports/${reportID}/pdf`;
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) => response.data);
  },
  fetchVisit(visitID) {
    const url = `api/visits/${visitID}`;
    return axios.get(url).then((response) => response.data);
  },
  fetchComments(visitID) {
    const url = `api/visits/${visitID}/comments`;
    return axios.get(url).then((response) => {
      return response.data.map((c) => {
        // The rtf only stores the first font (replace single font with fontstack)
        c.Text = c.Text.replace(
          /font-family:.?Comic Sans MS.?/g,
          "font-family:Comic Sans MS, ChalkboardSE-Regular;"
        );
        c.Text = c.Text.replace(
          /font-family:.?Impact.?/g,
          "font-family:Impact, DINCondensed-Bold;"
        );
        c.Text = c.Text.replace(
          /font-family:.?Lucida Grande.?/g,
          "font-family:Lucida Grande, Lucida Sans Unicode;"
        );
        c.Text = c.Text.replace(
          /font-family:.?Tahoma.?/g,
          "font-family:Tahoma, Helvetica;"
        );
        // Disable links for IE in preview
        c.Text = c.Text.replace(/(<a href="[^"]*")/g, "$1 disabled");
        return c;
      });
    });
  },
  freezeVisit(visitID, visitStatus) {
    const url = "api/visits:freezeStatus";
    return axios
      .post(url, { VisitID: visitID, VisitStatus: visitStatus })
      .then((response) => response.data);
  },
  unfreezeVisit(visitID) {
    const url = "api/visits:unfreezeStatus";
    return axios
      .post(url, { VisitID: visitID })
      .then((response) => response.data);
  },
  generateReport(visitID, reportName, reportStatus) {
    const url = `api/visits/${visitID}/reports:generate`;
    return axios
      .post(url, { ReportName: reportName, ReportStatus: reportStatus })
      .then((response) => response.data);
  },
  updateReport(visitID, reportName, reportStatus) {
    const url = `api/visits/${visitID}/reports:update`;
    return axios
      .post(url, { ReportName: reportName, ReportStatus: reportStatus })
      .then((response) => response.data);
  },
  deleteReport(visitID, reportID) {
    const url = `api/visits/${visitID}/reports/${reportID}`;
    return axios.delete(url).then((response) => response.data);
  },
  sendReport(visitID, reportID) {
    const url = `api/visits/${visitID}/reports/${reportID}:send`;
    return axios.post(url).then((response) => response.data);
  },
  uploadReport(visitID, file) {
    const url = `api/visits/${visitID}/reports:upload`;
    const formData = new FormData();
    formData.append("report", file);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  },
  checkReportOperation(visitID, reportName) {
    const url = `api/visits/${visitID}/reports:checkOperation`;
    return axios
      .post(url, { ReportName: reportName })
      .then((response) => response.data);
  },
  saveSettings(settings) {
    const url = "api/settings";
    return axios.post(url, settings).then((response) => response.data);
  },
  saveActiveFilter(filter) {
    const url = "api/activeFilter";
    return axios.post(url, filter).then((response) => response.data);
  },
  fetchFilters() {
    const url = `api/filters`;
    return axios.get(url).then((response) => response.data);
  },
  saveFilter(name, filter) {
    const url = `api/filters`;
    return axios
      .post(url, { Name: name, Filter: filter })
      .then((response) => response.data);
  },
  deleteFilter(name) {
    const url = `api/filters?filterName=${encodeURIComponent(name)}`;
    return axios.delete(url).then((response) => response.data);
  },
  fetchLevels(visitID) {
    const url = `api/visits/${visitID}/levels`;
    return axios.get(url).then((response) => response.data);
  },
  fetchRecommendedReportTemplates(visitID) {
    const url = `api/visits/${visitID}/recommendedReportTemplates`;
    return axios.get(url).then((response) => response.data);
  },
  fetchCommentTemplates() {
    const url = `api/commentTemplates`;
    return axios.get(url).then((response) => response.data);
  },
  evaluateCommentTemplate(visitID, levelID, patientID, templateID) {
    const url = `api/commentTemplates:evaluate`;
    return axios
      .post(url, {
        VisitID: visitID,
        LevelID: levelID,
        PatientID: patientID,
        TemplateID: templateID,
      })
      .then((response) => response.data);
  },
  fetchAutoInterpretations() {
    const url = `api/autoInterpretations`;
    return axios.get(url).then((response) => response.data);
  },
  evaluateAutoInterpretation(name, visitID, levelID, patientID, trialIndex) {
    const url = `api/autoInterpretations:evaluate`;
    return axios
      .post(url, {
        Name: name,
        VisitID: visitID,
        LevelID: levelID,
        PatientID: patientID,
        TrialIndex: trialIndex,
      })
      .then((response) => response.data);
  },
  savePhysicianComment(visitID, reportID, text) {
    const url = `api/visits/${visitID}:savePhysicianComment`;
    if (text !== "") {
      text = `<span style="font-size: 12px; font-family: Arial;">${text}</span>`;
    }
    return axios
      .post(url, { ReportID: reportID, Text: text })
      .then((response) => response.data);
  },
  signAsPhysician(
    visitID,
    reportID,
    newState,
    token = JwtService.getToken() // sign as current user if no token is provided
  ) {
    const url = `api/visits/${visitID}:signAsPhysician`;
    return axios.post(
      url,
      { ReportID: reportID, NewState: newState },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  saveTechnicianComment(visitID, reportID, levelID, text) {
    const url = `api/visits/${visitID}/levels/${levelID}:saveTechnicianComment`;
    if (text !== "") {
      text = `<span style="font-size: 12px; font-family: Arial;">${text}</span>`;
    }
    return axios
      .post(url, { ReportID: reportID, Text: text })
      .then((response) => response.data);
  },
  signAsTechnician(visitID, reportID, token = JwtService.getToken()) {
    const url = `api/visits/${visitID}:signAsTechnician`;
    return axios.post(
      url,
      { ReportID: reportID },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  fetchVisitMetaData(visitID) {
    const url = `api/visits/${visitID}/metadata`;
    return axios.get(url).then((response) => response.data);
  },
  testReviewStatus(visitID, newStatus) {
    const url = `api/visits/${visitID}:testReview`;
    return axios.post(url, { NewStatus: newStatus });
  },
  directLink(create, patientID, visitDate, reportName) {
    const url = `api/directLink`;
    return axios
      .post(url, {
        Create: create,
        ExternalID: patientID,
        VisitDate: visitDate,
        ReportName: reportName,
      })
      .then((response) => response.data);
  },
};

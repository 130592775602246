var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { lazy: "", id: "replace-report-modal" },
      on: {
        hide: function ($event) {
          return _vm.$emit("hide", $event)
        },
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("localized")("Review.View.Messages.10")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { type: "button" },
            on: { click: _vm.handleCancel },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.Report.6")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.handleOK },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.Messages.12")) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(_vm._s(_vm._f("localized")("Review.View.Messages.11"))),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
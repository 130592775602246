var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    !_vm.isInterpretationReport
      ? _c("div", [
          _vm._v(
            "\n    " +
              _vm._s(_vm._f("localized")(_vm.reportStatus.label)) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.his
      ? _c("div", { staticClass: "w-100" }, [
          _vm._v("\n    " + _vm._s(_vm._f("localized")(_vm.hisKey)) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
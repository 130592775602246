<template>
  <div class="w-100 btn-group btn-group-toggle btn-group-sm d-flex">
    <label
      class="w-100 btn btn-outline-secondary"
      :class="{
        active: proxiedStatus === s.status,
        'cursor-not-allowed disabled': s.disabled || disabled,
        'cursor-pointer': !s.disabled || !disabled,
      }"
      v-for="(s, idx) in sequence"
      :id="`btn-report-status-selection-${s.status}`"
      :key="idx"
    >
      <input
        type="radio"
        :value="s.status"
        :disabled="s.disabled || disabled"
        :class="{ disabled: s.disabled }"
        v-model="proxiedStatus"
      />
      {{ s.label | localized }}
    </label>
  </div>
</template>

<script>
export default {
  name: "ReportStatusSelection",
  props: {
    initialStatus: String,
    availableStates: Array,
    disabled: Boolean,
  },
  computed: {
    sequence() {
      // expand with disabled flag
      const sequence = this.$store.getters.HISStatusSequence;
      return sequence.map((s) => {
        const status = {
          status: s.status,
          label: s.label,
        };
        const availableState = this.availableStates.find(
          (as) => as.status === s.status
        );
        if (!availableState) {
          status.disabled = true;
        }
        return status;
      });
    },
    proxiedStatus: {
      get() {
        return this.initialStatus;
      },
      set(val) {
        this.$emit("update", val);
      },
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="position-relative">
    <label :for="label" class="small text-muted">
      {{ label | localized }}
    </label>
    <Typeahead
      :options="options"
      :initial-value="initialValue"
      @input="$emit('update', $event)"
    />
  </div>
</template>

<script>
import Typeahead from "@/components/Typeahead";
export default {
  name: "FilterTypeahead",
  components: { Typeahead },
  props: {
    label: String,
    initialValue: String,
    options: Array,
  },
};
</script>

<style type="scss" scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseLayout", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("iframe", {
              ref: "pdfViewer",
              staticClass: "w-100 h-100",
              attrs: {
                id: "embedded-viewer",
                src: "static/web/viewer.html#zoom=auto",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-modal
    ref="modal"
    size="lg"
    lazy
    id="delete-report-modal"
    @show="onShow"
    @shown="focusInput"
    @hide="$emit('hide', $event)"
  >
    <template slot="modal-title">
      <h5 class="modal-title">
        {{ "Review.View.DeleteReport.2" | localized }}
      </h5>
    </template>
    <template slot="modal-footer">
      <button
        id="btn-cancel-delete-report"
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.DeleteReport.5" | localized }}
      </button>
      <button
        id="btn-confirm-delete-report"
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="okButtonDisabled"
        @click="handleOK"
      >
        {{ "Review.View.DeleteReport.6" | localized }}
      </button>
    </template>
    <!-- content -->
    <ErrorMessage :error="error" :dismiss="hideError" />
    <div>
      {{ "Review.View.DeleteReport.3" | localized }}
      {{ `"${selectedReport.Filename}"` }} ?
    </div>
    <div v-if="!isDefaultUser">
      <form @submit.prevent="handleOK">
        <label for="input-password-delete-report" class="col-form-label">
          {{ "Review.View.DeleteReport.4" | localized }}:
        </label>
        <div>
          <input
            ref="input"
            type="password"
            class="form-control form-control-sm"
            id="input-password-delete-report"
            v-model="password"
          />
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import ApiService from "@/common/api.service";
import ErrorMessage from "@/components/ErrorMessage";
import { DELETE_REPORT } from "@/store/actions.type";

export default {
  name: "ReportModalDelete",
  components: {
    ErrorMessage,
  },
  data() {
    return {
      password: null,
      error: null,
      loading: false,
    };
  },
  computed: {
    isDefaultUser() {
      return this.$store.getters.isDefaultUser;
    },
    selectedReport() {
      return this.$store.getters.selectedReport;
    },
    username() {
      return this.$store.getters.username;
    },
    workstationID() {
      return this.$store.getters.workstationID;
    },
    selection() {
      return this.$store.getters.selection;
    },
    okButtonDisabled() {
      return !this.isDefaultUser && (this.loading || this.password === "");
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    focusInput() {
      this.$refs.input.focus();
    },
    handleOK() {
      const reportName = this.selectedReport.Filename;
      // if we're the default user, we skip the password confirmation
      if (this.isDefaultUser) {
        this.$store.dispatch(DELETE_REPORT, {
          visitID: this.selection.visitID,
          reportID: this.selection.reportID,
          reportName,
        });
        this.$refs.modal.hide("ok");
        return;
      }
      this.loading = true;
      ApiService.login(this.username, this.password, "", this.workstationID)
        .then(() => {
          // everything ok
          this.$store.dispatch(DELETE_REPORT, {
            visitID: this.selection.visitID,
            reportID: this.selection.reportID,
            reportName,
          });
          this.$refs.modal.hide("ok");
          this.loading = false;
          this.password = "";
        })
        .catch((e) => {
          this.error = "Review.View.Login.5";
          this.password = "";
          this.loading = false;
        });
    },
    handleCancel() {
      this.$refs.modal.hide("cancel");
    },
    onShow() {
      this.password = "";
      this.error = "";
    },
    hideError() {
      this.error = "";
    },
  },
};
</script>

<style scoped></style>

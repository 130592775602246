var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseLayout", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v(" ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column w-100 h-100 justify-content-center align-items-center",
              },
              [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/logo-login.png") },
                  }),
                ]),
                _vm._v(" "),
                _vm.isMobileReviewEnabled
                  ? _c(
                      "div",
                      {
                        staticClass: "w-100",
                        staticStyle: { "max-width": "300px" },
                      },
                      [
                        _c("ErrorMessage", {
                          attrs: {
                            error: _vm.error,
                            args: _vm.errorArgs,
                            dismiss: _vm.hideError,
                          },
                        }),
                        _vm._v(" "),
                        !_vm.showChangePassword
                          ? _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.login.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "input-username" } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Login.1"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.username,
                                          expression: "username",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        id: "input-username",
                                        type: "text",
                                        placeholder: "Login name",
                                        autofocus: "",
                                      },
                                      domProps: { value: _vm.username },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.username = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "input-password" } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Login.3"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.password,
                                          expression: "password",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        id: "input-password",
                                        autocomplete: "current-password",
                                        type: "password",
                                        placeholder: "Password",
                                      },
                                      domProps: { value: _vm.password },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.password = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group d-flex justify-content-between",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-link ml-0 pl-0",
                                          attrs: {
                                            id: "btn-show-change-password",
                                            type: "button",
                                            tabindex: "-1",
                                            disabled:
                                              _vm.busy ||
                                              !_vm.isChangePasswordAllowed,
                                          },
                                          on: {
                                            click: _vm.toggleShowChangePassword,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("localized")(
                                                  "Review.View.Login.10"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-primary",
                                          attrs: {
                                            id: "btn-login",
                                            disabled: _vm.loginButtonDisabled,
                                            type: "submit",
                                          },
                                        },
                                        [
                                          _vm.busy
                                            ? _c("Spinner", {
                                                attrs: {
                                                  type: "border",
                                                  small: "",
                                                  color: "white",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("localized")(
                                                  "Review.View.Login.2"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.changePassword.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "input-username" } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Login.1"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.username,
                                          expression: "username",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        id: "input-username",
                                        type: "text",
                                        placeholder: "Login name",
                                        autofocus: "",
                                      },
                                      domProps: { value: _vm.username },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.username = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "input-old-password" } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Login.102"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.password,
                                          expression: "password",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        id: "input-old-password",
                                        autocomplete: "current-password",
                                        type: "password",
                                        placeholder: "Current Password",
                                      },
                                      domProps: { value: _vm.password },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.password = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "input-new-password" } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Login.103"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.newPassword,
                                          expression: "newPassword",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        id: "input-new-password",
                                        autocomplete: "new-password",
                                        type: "password",
                                        placeholder: "New Password",
                                      },
                                      domProps: { value: _vm.newPassword },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.newPassword = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "input-confirm-password",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.Login.104"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.newPasswordConfirm,
                                          expression: "newPasswordConfirm",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        id: "input-confirm-password",
                                        autocomplete: "new-password",
                                        type: "password",
                                        placeholder: "New Password",
                                      },
                                      domProps: {
                                        value: _vm.newPasswordConfirm,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.newPasswordConfirm =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group d-flex justify-content-end",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary ml-0",
                                          attrs: {
                                            id: "btn-cancel-change-password",
                                            type: "button",
                                            disabled: _vm.busy,
                                          },
                                          on: {
                                            click: _vm.toggleShowChangePassword,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("localized")(
                                                  "Review.View.Login.105"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-primary ml-2",
                                          attrs: {
                                            id: "btn-change-password",
                                            disabled:
                                              _vm.changePasswordButtonDisabled,
                                            type: "submit",
                                          },
                                        },
                                        [
                                          _vm.busy
                                            ? _c("Spinner", {
                                                attrs: {
                                                  type: "border",
                                                  small: "",
                                                  color: "white",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("localized")(
                                                  "Review.View.Login.101"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "alert alert-warning mt-3",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("i", { staticClass: "fas fa-info-circle" }),
                        _vm._v(
                          "\n        Mobile Review is not a licensed option! Program is aborted.\n      "
                        ),
                      ]
                    ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
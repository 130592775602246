<template>
  <BaseLayout>
    <template v-slot:content>
      <div
        class="d-flex flex-grow-1 flex-column"
        :style="{ flex: '1 1 0% !important', minHeight: 0 }"
      >
        <SearchBar v-if="!isPatientMode">
          <template slot="pagination">
            <Pagination :select-page="selectPage" />
          </template>
        </SearchBar>
        <PatientBar :patient="patient" v-else>
          <template slot="pagination">
            <Pagination :select-page="selectPage" />
          </template>
        </PatientBar>
        <div
          class="d-flex"
          :style="{ flex: '1 1 0% !important', minHeight: 0 }"
          v-if="!isFetchingVisits"
        >
          <div
            id="visit-list"
            class="flex-grow-1"
            ref="visit-list"
            style="overflow-y: scroll"
            v-if="sortedVisits.length > 0"
          >
            <VisitListItem
              v-for="v in sortedVisits"
              :id="`visit-${v.ID}`"
              :visit="v"
              :key="v.ID"
              :isPatientMode="isPatientMode"
              :onPatientDetails="handlePatientDetails"
              :selected="v.ID === selection.visitID"
              :onUpdateReport="handleUpdateReport"
              :onDeleteReport="handleDeleteReport"
              :onCreateReport="handleCreateReport"
              :onSendReport="handleSendReport"
              @uploadReport="handleUploadReport"
            />
          </div>
          <div
            id="empty-visit-list"
            class="d-flex flex-grow-1 justify-content-center align-items-center"
            v-else
          >
            0 {{ "Review.View.Visit.11" | localized }}
          </div>
          <LevelList
            class="bg-white"
            id="level-area"
            v-if="levels.length > 0 && selection.visitID"
          />
        </div>
        <div
          class="pt-5 d-flex justify-content-center align-items-center"
          style="flex: 1 1 0% !important"
          v-else
        >
          <Spinner type="grow" />
        </div>

        <ReportModalCreate
          ref="createReportModal"
          @report-created="handleReportCreated"
        />
        <ReportModalUpdate
          v-if="selectedReport"
          ref="updateReportModal"
          id="update-report-modal"
          :visitID="selection.visitID"
          :reportID="selectedReport.ID"
          :reportName="selectedReport.Filename"
          :reportStatus="selectedReport.Status"
        />
        <ReportModalDelete ref="deleteReportModal" />
        <ReportModalUpload
          ref="uploadReportModal"
          @hide="onUploadReportModalHide"
        />
      </div>
    </template>
  </BaseLayout>
</template>
<script>
import {
  FETCH_VISITS,
  SEND_REPORT,
  UPLOAD_REPORT,
  SELECT_PAGE,
  START_PATIENT_MODE,
  STOP_BATCH_MODE,
} from "@/store/actions.type";

import ReportModalUpdate from "@/components/ReportModalUpdate";
import ReportModalCreate from "@/components/ReportModalCreate";
import BaseLayout from "@/components/BaseLayout";
import Pagination from "@/components/Pagination";
import Spinner from "@/components/Spinner";

import SearchBar from "@/components/views/visits/SearchBar";
import PatientBar from "@/components/views/visits/PatientBar";
import VisitListItem from "@/components/views/visits/VisitListItem";
import LevelList from "@/components/views/visits/LevelList";
import ReportModalUpload from "@/components/views/visits/ReportModalUpload";
import ReportModalDelete from "@/components/views/visits/ReportModalDelete";

export default {
  name: "VisitList",
  components: {
    BaseLayout,
    Pagination,
    LevelList,
    PatientBar,
    SearchBar,
    VisitListItem,
    Spinner,
    ReportModalUpload,
    ReportModalDelete,
    ReportModalUpdate,
    ReportModalCreate,
  },
  mounted() {
    if (this.isBatchMode) {
      this.$store.dispatch(STOP_BATCH_MODE);
      this.$store.dispatch(FETCH_VISITS, {
        paging: false,
      });
    }
    const { visitID } = this.selection;
    // get element according to selection and adapt scroll offset with respect to pagination etc.
    const el = document.getElementById(`visit-${visitID}`);
    if (!el) {
      return;
    }
    // get distance from top of viewport
    const { top } = this.$refs["visit-list"].getBoundingClientRect();
    // we add an additional offset to make sure a small part of the element above is still visible
    this.$refs["visit-list"].scrollTop = el.offsetTop - (top + 50);
  },
  beforeRouteEnter(to, from, next) {
    console.log("route entered", to, from);
    // if we got here from the login page or on the initial load, fetch the visits
    next((vm) => {
      if (vm.$store.getters.requiresFetchVisits) {
        vm.$store.dispatch(FETCH_VISITS, {
          paging: false,
        });
      }
    });
  },
  computed: {
    patient() {
      if (this.sortedVisits.length === 0) {
        return {};
      }
      return this.sortedVisits[0].Patient;
    },
    isBatchMode() {
      return this.$store.getters.isBatchMode;
    },
    isPatientMode() {
      return this.$store.getters.isPatientMode;
    },
    isInitialized() {
      return this.$store.getters.isInitialized;
    },
    username() {
      return this.$store.getters.username;
    },
    isFetchingVisits() {
      return this.$store.getters.isFetchingVisits;
    },
    selection() {
      return this.$store.getters.selection;
    },
    sortedVisits() {
      return this.$store.getters.sortedVisits;
    },
    selectedReport() {
      return this.$store.getters.selectedReport;
    },
    levels() {
      return this.$store.getters.levels;
    },
  },
  methods: {
    handleUpdateReport() {
      this.$refs.updateReportModal.show();
    },
    handleDeleteReport() {
      this.$refs.deleteReportModal.show();
    },
    handleCreateReport() {
      this.$refs.createReportModal.show();
    },
    handleReportCreated(event) {
      const { visitID, reportName, background } = event;
      if (background) {
        return;
      }
      const r = this.$store.getters.visits[visitID].Reports.find(
        (r) => r.Filename === reportName
      );
      if (!r) {
        console.log(
          "handleReportCreated(): report not found for visit",
          visitID,
          reportName
        );
        return;
      }
      // navigate
      this.$router
        .push({
          name: "report",
          params: {
            visitID,
            reportID: r.ID,
          },
        })
        .catch((err) => console.log(err));
    },
    handleSendReport() {
      const reportID = this.selectedReport.ID;
      const reportName = this.selectedReport.Filename;
      const visitID = this.selection.visitID;
      this.$store.dispatch(SEND_REPORT, { visitID, reportID, reportName });
    },
    handleUploadReport(file) {
      this.externalReportFile = file;
      const selectedVisit = this.$store.getters.selectedVisit;
      if (!!selectedVisit.Reports.find((r) => r.Filename === file.name)) {
        // report already exists
        this.$refs.uploadReportModal.show();
        return;
      }
      this.uploadReport();
    },
    onUploadReportModalHide($event) {
      if ($event.trigger !== "ok") {
        this.externalReportFile = null;
        return;
      }
      this.uploadReport();
    },
    uploadReport() {
      const { visitID } = this.selection;
      this.$store.dispatch(UPLOAD_REPORT, {
        visitID,
        file: this.externalReportFile,
      });
    },
    handlePatientDetails(patientID) {
      this.$router
        .push({
          name: "patient",
          params: { patientID: patientID },
        })
        .catch((err) => console.log(err));
      this.$store.dispatch(START_PATIENT_MODE, patientID);
    },
    selectPage(n) {
      this.$store.dispatch(SELECT_PAGE, {
        pageNumber: n,
      });
    },
  },
};
</script>

<style scoped>
#level-area {
  width: 280px;
  min-width: 280px;
}
</style>

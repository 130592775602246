var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { lazy: "" },
      on: {
        hide: function ($event) {
          return _vm.$emit("hide", $event)
        },
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm._f("localized")("Review.View.Visit.4"))),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-cancel-update-report", type: "button" },
            on: { click: _vm.handleCancel },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.Common.1")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: { id: "btn-confirm-update-report", type: "button" },
            on: { click: _vm.handleOK },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.Common.2")) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(
        "\n  " +
          _vm._s(_vm._f("localized")("Review.View.Visit.1")) +
          "\n  " +
          _vm._s(`"${_vm.selectedReport.Filename}"`) +
          " ?\n"
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <BaseLayout>
    <template v-slot:content>
      <div
        class="flex-grow-1 d-flex flex-column border-top"
        style="min-height: 0; border-width: 2px !important; overflow-y: hidden"
      >
        <div class="container-fluid flex-fill overflow-auto">
          <div class="p-2 mt-2 bg-white">
            <template v-if="hasAdminRight">
              <h5>{{ "Review.View.Administration.10" | localized }}</h5>
              <div class="row" v-if="!isDesktopMode">
                <div class="col-4">
                  {{ "Review.View.Administration.20" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="HISActiveForMobile"
                      v-model="settings.HISActiveForMobile"
                    />
                    <label
                      class="custom-control-label"
                      id="HISActiveForMobileLabel"
                      for="HISActiveForMobile"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.11" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="AutoSendToHIS"
                      v-model="settings.AutoSendToHIS"
                      :disabled="!isDesktopMode && !settings.HISActiveForMobile"
                    />
                    <label
                      class="custom-control-label"
                      id="AutoSendToHISLabel"
                      for="AutoSendToHIS"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.12" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="AllowSendToHISWithoutComment"
                      v-model="settings.AllowSendToHISWithoutComment"
                      :disabled="!isDesktopMode && !settings.HISActiveForMobile"
                    />
                    <label
                      class="custom-control-label"
                      id="AllowSendToHISWithoutCommentLabel"
                      for="AllowSendToHISWithoutComment"
                    ></label>
                  </div>
                </div>
              </div>
              <h5 class="mt-4">
                {{ "Review.View.Administration.2" | localized }}
              </h5>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.3" | localized }}
                </div>
                <div class="col-8">
                  <select
                    v-model="settings.HISStatusSequence"
                    id="HISStatusSequence"
                    class="form-control form-control-sm"
                  >
                    <option
                      v-for="(option, oidx) in hisStatusSequences"
                      :key="oidx"
                      :id="`HISStatusSequenceOption${oidx}`"
                      :value="option.id"
                    >
                      {{ option.label | localized }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row pt-2 pb-1">
                <div class="col-4">
                  {{ "Review.View.Administration.27" | localized }}
                </div>
                <div class="col-8">
                  <select
                    v-model="settings.CommentTemplateSearch"
                    id="CommentTemplateSearch"
                    class="form-control form-control-sm"
                  >
                    <option
                      v-for="(option, oidx) in commentTemplateSearchOptions"
                      :key="oidx"
                      :id="`CommentTemplateSearchOption${oidx}`"
                      :value="option.id"
                    >
                      {{ option.label | localized }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.13" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="AllowSignReportWithoutComment"
                      v-model="settings.AllowSignReportWithoutComment"
                    />
                    <label
                      class="custom-control-label"
                      id="AllowSignReportWithoutCommentLabel"
                      for="AllowSignReportWithoutComment"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.14" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="AllowExternalReportUpload"
                      v-model="settings.AllowExternalReportUpload"
                    />
                    <label
                      class="custom-control-label"
                      id="AllowExternalReportUploadLabel"
                      for="AllowExternalReportUpload"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.15" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="AllowCommentingOfExternalReports"
                      v-model="settings.AllowCommentingOfExternalReports"
                    />
                    <label
                      class="custom-control-label"
                      id="AllowCommentingOfExternalReportsLabel"
                      for="AllowCommentingOfExternalReports"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ "Review.View.Administration.16" | localized }}
                </div>
                <div class="col-8">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="AllowTechCommentSignature"
                      v-model="settings.AllowTechCommentSignature"
                    />
                    <label
                      class="custom-control-label"
                      id="AllowTechCommentSignatureLabel"
                      for="AllowTechCommentSignature"
                    ></label>
                  </div>
                </div>
              </div>
              <template v-if="!isDesktopMode">
                <h5 class="mt-4">
                  {{ "Review.View.Administration.4" | localized }}
                </h5>
                <div class="row">
                  <div class="col-4">
                    {{ "Review.View.Administration.7" | localized }}
                  </div>
                  <div class="col-6">
                    <input
                      id="AutoLogoutTimeout"
                      type="range"
                      min="1"
                      :max="Math.min(1440, tokenLifeTime)"
                      class="custom-range"
                      v-model="autoLogoutTimeout"
                    />
                  </div>
                  <div class="col-2">
                    {{ autoLogoutTimeout }}
                    {{ "Review.View.Administration.6" | localized }}
                  </div>
                </div>
              </template>
            </template>
            <h5 class="mt-4">
              {{ "Review.View.Administration.21" | localized }}
            </h5>
            <p>
              {{ "Review.View.Administration.22" | localized }}
            </p>
            <div class="row">
              <div class="col-1 text-muted">
                <p>
                  <b>{{ "Review.View.Administration.23" | localized }}</b>
                  <br />
                  {{ "Review.View.Administration.24" | localized }}
                </p>
              </div>
              <div class="col-11">
                <draggable
                  class="drag-area row rounded no-gutters"
                  v-model="visible"
                  :group="{
                    name: 'filter',
                    put: visible.length < 4 || dragging === 'visible',
                    pull: visible.length > 1,
                  }"
                  :class="{
                    'drop-target alert-success border':
                      dragging === 'visible' ||
                      (dragging === 'hidden' && visible.length < 4),
                  }"
                  @start="drag('visible', true)"
                  @end="drag('visible', false)"
                >
                  <template v-for="(f, idx) in visible">
                    <div
                      v-if="showFilter(f)"
                      class="col-6 col-lg-3"
                      :key="`${f}-${idx}`"
                    >
                      <div class="bg-white m-2 rounded">
                        <FilterPlaceholder
                          :label="filterLabels[f]"
                        ></FilterPlaceholder>
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-1 text-muted">
                <p>
                  <b>{{ "Review.View.Administration.25" | localized }}</b>
                  <br />
                  {{ "Review.View.Administration.26" | localized }}
                </p>
              </div>
              <div class="col-11">
                <draggable
                  class="drag-area rounded row no-gutters"
                  v-model="hidden"
                  group="filter"
                  :class="{
                    'drop-target alert-success border':
                      dragging && visible.length > 1,
                  }"
                  @start="drag('hidden', true)"
                  @end="drag('hidden', false)"
                >
                  <template v-for="(f, idx) in hidden">
                    <div
                      v-if="showFilter(f)"
                      class="col-6 col-lg-3"
                      :key="`${f}-${idx}`"
                    >
                      <div class="bg-white m-2 rounded">
                        <FilterPlaceholder
                          :label="filterLabels[f]"
                        ></FilterPlaceholder>
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end border-top p-2 bg-white">
          <button
            id="btn-cancel-settings"
            class="btn btn-sm btn-outline-secondary mr-1"
            @click="navigate"
          >
            {{ "Review.View.Administration.8" | localized }}
          </button>
          <button
            id="btn-save-settings"
            class="btn btn-sm btn-primary"
            @click="saveSettings"
            :disabled="isSaving"
          >
            {{ "Review.View.Administration.9" | localized }}
          </button>
        </div>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import draggable from "vuedraggable";

import {
  FILTER_HIS_ORDER_ID,
  FILTER_HIS_VISIT_ID,
  FILTER_LABELS,
} from "@/common/constants";
import { UPDATE_SETTINGS } from "@/store/actions.type";

import FilterPlaceholder from "@/components/views/settings/FilterPlaceholder";
import BaseLayout from "@/components/BaseLayout";

export default {
  name: "Settings",
  components: {
    BaseLayout,
    FilterPlaceholder,
    draggable,
  },
  data() {
    return {
      isSaving: false,
      dragging: false, // either false, "hidden" or "visible"
      prevRoute: null,
      filterLabels: FILTER_LABELS,
      // copy over initial settings / filterConfiguration from store
      settings: cloneDeep(this.$store.getters.settings),
      filterConfiguration: cloneDeep(this.$store.getters.filterConfiguration),
      hisStatusSequences: [
        { id: "P-F-C", label: "Review.View.Administration.17" },
        { id: "F-C", label: "Review.View.Administration.18" },
      ],
      commentTemplateSearchOptions: [
        { id: "TypeAhead", label: "Review.View.Administration.28" },
        { id: "StartsWith", label: "Review.View.Administration.29" },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // inject prev route
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    storeFilterConfiguration: {
      deep: true,
      handler() {
        this.filterConfiguration = cloneDeep(
          this.$store.getters.filterConfiguration
        );
      },
    },
    storeSettings: {
      deep: true,
      handler() {
        this.settings = cloneDeep(this.$store.getters.settings);
      },
    },
  },
  computed: {
    storeFilterConfiguration() {
      return this.$store.getters.filterConfiguration;
    },
    storeSettings() {
      return this.$store.getters.settings;
    },
    isDesktopMode() {
      return this.$store.getters.isDesktopMode;
    },
    hasAdminRight() {
      return this.$store.getters.hasAdminRight;
    },
    tokenLifeTime() {
      return this.$store.getters.tokenLifeTime;
    },
    visible: {
      get() {
        return this.filterConfiguration.Visible;
      },
      set(val) {
        this.filterConfiguration.Visible = val;
      },
    },
    hidden: {
      get() {
        return this.filterConfiguration.Hidden;
      },
      set(val) {
        this.filterConfiguration.Hidden = val;
      },
    },
    autoLogoutTimeout: {
      get() {
        return this.settings.AutoLogoutTimeout;
      },
      set(val) {
        this.settings.AutoLogoutTimeout = parseInt(val, 10);
      },
    },
  },
  methods: {
    showFilter(filter) {
      // duplicate logic of $store.getters.isHISAvailable so that filters are shown/hidden immediately when HIS is
      // enabled/disabled in the settings
      let isHISAvailable = false;
      if (this.isDesktopMode) {
        isHISAvailable =
          this.settings.HISSoftwareOption && this.settings.HISDesktopSetting;
      } else {
        isHISAvailable =
          this.settings.HISSoftwareOption && this.settings.HISActiveForMobile;
      }
      if (isHISAvailable) {
        return true;
      }
      // don't display HIS related filters if HIS is not available
      switch (filter) {
        case FILTER_HIS_VISIT_ID:
        case FILTER_HIS_ORDER_ID:
          return false;
        default:
          return true;
      }
    },
    drag(list, dragging) {
      if (dragging) {
        this.dragging = list;
      } else {
        this.dragging = false;
      }
    },
    navigate() {
      // if we had an previous route != login, navigate back to it. Otherwise navigate to "/"
      if (
        this.prevRoute &&
        this.prevRoute.name &&
        this.prevRoute.name !== "login"
      ) {
        return this.$router.go(-1);
      }
      // we had no previous route =>
      return this.$router
        .push({ name: "visits" })
        .catch((err) => console.log(err));
    },
    saveSettings() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      this.$store
        .dispatch(UPDATE_SETTINGS, {
          settings: this.settings,
          filterConfiguration: this.filterConfiguration,
        })
        .then(() => {
          this.navigate();
        })
        .catch(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "../../../custom";

.drag-area {
  background-color: $body-bg;
  border: 2px solid $body-bg;
  min-height: 30px;
}
.drop-target {
  border-width: 2px !important;
}
.sortable-ghost {
  opacity: 0.3;
}
</style>

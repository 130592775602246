import ApiService from "@/common/api.service";

import {
  FETCH_TEXTS_PENDING,
  FETCH_TEXTS_SUCCESS,
  FETCH_TEXTS_ERROR,
  SET_LOCALE,
} from "@/store/mutations.type";
import { CHANGE_LOCALE, FETCH_TEXTS } from "@/store/actions.type";

// initial state
const initialState = () => {
  return {
    loading: false,
    language: "En",
    country: "GBR",
    texts: {},
  };
};

const langKey = (language, country) => `${language}_${country}`;

// getters
const getters = {
  localizedText: (state) => (group) => {
    const key = langKey(state.language, state.country);
    const items = state.texts[key];
    if (!items) {
      return "";
    }
    const t = state.texts[key].find((t) => t.Group === group);
    if (!t) {
      return "";
    }
    return t.ShortText;
  },
  isFetchingTexts: (state) => state.loading,
  localizedLanguage: (state) => state.language,
};

// store last promise to allow cancellation
let fetchTextsPromise;
// actions
const actions = {
  [FETCH_TEXTS]({ commit }, { language, country }) {
    commit(FETCH_TEXTS_PENDING, {
      language,
      country,
    });
    const promise = ApiService.fetchTexts(language, country);
    fetchTextsPromise = promise;
    promise
      .then((data) => {
        if (fetchTextsPromise !== promise) {
          // outdated data skip it
          return;
        }
        const texts = [];
        data.UITexts.forEach((t) => {
          texts.push({
            Group: `${t.Group}.${t.Number}`,
            ShortText: t.ShortText,
          });
        });
        data.MeasurementDescriptions.forEach((t) => {
          texts.push({
            Group: `MeasurementDescription.${t.ID}`,
            ShortText: t.ShortText,
          });
        });
        commit(FETCH_TEXTS_SUCCESS, {
          language,
          country,
          texts,
        });
      })
      .catch((e) => {
        commit(FETCH_TEXTS_ERROR, e);
      });
  },
  [CHANGE_LOCALE]({ commit, state, dispatch }, { language, country }) {
    console.log(`switching to language: ${language} country: ${country}`);
    dispatch(FETCH_TEXTS, { language, country });
    commit(SET_LOCALE, {
      language,
      country,
    });
  },
};

// mutations
const mutations = {
  [FETCH_TEXTS_PENDING](state, { language, country }) {
    console.log(`fetching texts for ${language} ${country}`);
    state.loading = true;
  },
  [FETCH_TEXTS_SUCCESS](state, { language, country, texts }) {
    state.texts = Object.assign({}, state.texts, {
      [langKey(language, country)]: texts,
    });
    state.loading = false;
  },
  [FETCH_TEXTS_ERROR](state, err) {
    console.log("fetching texts failed:", err);
    state.loading = false;
  },
  [SET_LOCALE](state, { language, country }) {
    state.language = language;
    state.country = country;
  },
};

export default {
  state: initialState(),
  actions,
  mutations,
  getters,
};

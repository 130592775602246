export const MEASUREMENT_TYPES = {
  None: 0,
  Spirometry: 1,
  Body: 3,
  Diffusion_SB: 4,
  Compliance: 6,
  R_Occlusion: 7,
  CPET: 12,
  Blood_gases: 13,
  Cardiology: 14,
  IOS: 18,
  Capnography: 19,
  Baby_Body: 20,
  Baby_R_C: 21,
  Baby_Tidal_Squeeze: 39,
  Baby_RaisedVolume_RTC: 57,
  Tidal_breathing: 22,
  CustomerProgram: 23,
  Rhinomanometry: 25,
  ParameterInput_1: 15,
  ParameterInput_2: 33,
  ParameterInput_3: 34,
  ParameterInput_4: 35,
  ParameterInput_5: 16,
  ParameterInput_6: 37,
  Inhaler: 36,
  ECG_HIRES: 43,
  ECG_REST: 44,
  ECG_STRESS: 45,
  ECG2012_REST: 46,
  ECG2012_STRESS: 47,
  Sleep: 53,
  Diffusion_SB_Intrabreath: 59,
  N2_Washout: 62,
  NO_Diffusion_Membrane: 63,
  MIPMEP: 65,
  P01: 66,
  SNIP: 67,
  FRC_Rebreathing: 68,
  Diffusion_Rebreathing: 69,
  Bronchial_Challenge: 70,
  Single_Breath_O2: 71,
  Exhaled_Temperature: 72,
  Six_Minute_Walk_Test: 73,
  Skin_Fold_Information: 74,
  Fenom: 75,
};

export const MEASUREMENT_METHODS = {
  Sequence_Body_Linked: 131174,
  Sequence_Body_Standard: 131173,
  Sequence_Body_Complete: 131172,
  Sequence_Body_Imported: 131175,
  Sequence_Body_Separated: 131179,
  Sequence_Baby_Multiple_Resistance: 131972,
  Sequence_Baby_Multiple_FRC: 131973,
  Sequence_Baby_Multiple_Resistance_Multiple_FRC: 131974,
  Sequence_Baby_Single_Resistance_Single_FRC: 131975,
  Spirometry_FVC: 65538,
  Spirometry: 65540,
  Spirometry_MVV: 65539,
  Spirometry_Slow: 65537,
  Body_Slow_Spirometry: 65658,
  Body_Resistance: 65656,
  Body_FRC_Pleth: 65657,
  Body_FVC: 65659,
  Diffusion_SB_COHe: 67238,
  Diffusion_SB_Realtime: 67236,
  Dynamic_Compliance: 66137,
  Catheter_Positioning: 66136,
  Static_Compliance: 66138,
  R_Occlusion: 65936,
  CPET: 67036,
  CPET_Canopy: 67041,
  CPET_Ventilator_Mixing_Chamber: 67043,
  CPET_POesophagus: 67056,
  CPET_Exercise_HighLow_FiO2: 67044,
  CPET_Oxycon_Delta_Mode: 67057,
  CPET_Exercise: 67038,
  CPET_Respiratory_Drive: 67045,
  CPET_BxB: 67039,
  CPET_Rest: 67037,
  CPET_Exercise_Mixing_Chamber: 67040,
  ECG_Stress: 68236,
  ECG_Resting: 68237,
  ECG_HighResolution: 68238,
  Ventilator_BxB: 67042,
  Bloodgas_CPET: 68138,
  Bloodgas_PFT: 68137,
  IOS_VC: 66237,
  IOS: 66236,
  Baby_Resistance: 66436,
  Baby_FRC: 66437,
  BabyRC_Double_Occlusion: 66637,
  BabyRC_Single_Occlusion: 66636,
  Tidal_breathing: 65736,
  Rhino: 66036,
  Baby_Rapid_Thoracic_Compression: 66536,
  Baby_RV_Rapid_Thoracic_Compression: 66537,
  Diffusion_SB_Intrabreath: 67237,
  N2_Slow_Spirometry: 66736,
  FRC_N2_Washout: 66737,
  NO_Diffusion_Membrane: 67536,
  MIP: 67136,
  MEP: 67137,
  P_01_Rest: 67336,
  P_01_Max: 67337,
  P_01_CO2_Low: 67338,
  P_01_CO2_High: 67339,
  SNIP: 67436,
  FRC_Rebreathing: 67736,
  Diffusion_Rebreathing: 67836,
  Provocation: 67636,
  Single_Breath_O2: 67936,
  Exhaled_temperature: 68036,
  Calibration_Volume_Calibration: 66336,
  Calibration_Volume: 66337,
  Calibration_Volume_Verification: 66338,
  Calibration_Filling_Sensor: 66339,
  Calibration_Volume_Automatic: 66340,
  Calibration_Box_Complete_: 66936,
  Calibration_Tau_Verification: 66937,
  Calibration_Shift_Volume: 66938,
  Calibration_O2CO2_N2Washout: 66836,
  Calibration_O2CO2_CPET: 66837,
  Calibration_COCH4_DLCO_RT: 66838,
  Calibration_COCH4C2H2_DLCO_IB: 66839,
  Calibration_O2CO2_MSCPX: 66840,
  Calibration_O2_MSPFT: 66841,
  Calibration_COHE: 66842,
  Calibration_HE_MSPFT: 66843,
  Calibration_COCH4_MSPFT: 66844,
  Calibration_COCH4C2H2_MSPFT: 66845,
  Calibration_All_Gases: 66846,
  Calibration_VyntusONE_ALL_Gases: 66848,
  Calibration_VyntusONE__ALL_Gases_US: 66849,
  Calibration_VyntusONE_O2CO2: 66850,
  Calibration_VyntusONE_DLCO: 66851,
  Calibration_VyntusONE_DLCO_US: 66852,
  Calibration_VyntusCPX_O2CO2: 66853,
};

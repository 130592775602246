var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        size: "lg",
        lazy: "",
        id: "create-report-modal",
        "hide-footer": _vm.isProcessing && _vm.switchToReport,
      },
      on: {
        show: _vm.onShow,
        shown: _vm.focusInput,
        hide: _vm.onHide,
        hidden: _vm.onHidden,
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h5", { staticClass: "modal-title pl-2" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("localized")("Review.View.CreateReport.3")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c("div", { staticClass: "form-check form-check-inline mr-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.switchToReport,
                expression: "switchToReport",
              },
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: "switchToReport" },
            domProps: {
              checked: Array.isArray(_vm.switchToReport)
                ? _vm._i(_vm.switchToReport, null) > -1
                : _vm.switchToReport,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.switchToReport,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.switchToReport = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.switchToReport = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.switchToReport = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { id: "switchToReportLabel", for: "switchToReport" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("localized")("Review.View.CreateReport.11")) +
                  "\n      "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-cancel-create-report", type: "button" },
            on: { click: _vm.handleCancel },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.CreateReport.2")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: {
              id: "btn-confirm-create-report",
              type: "button",
              disabled: !_vm.selectedReport,
            },
            on: {
              click: function ($event) {
                return _vm.handleOK(_vm.selectedTemplateIndex)
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.CreateReport.1")) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.isProcessing && _vm.switchToReport
          ? _c("div", { staticClass: "row m-2" }, [
              _c(
                "div",
                { staticClass: "col-12 text-center" },
                [
                  _c("Spinner", { attrs: { type: "border", small: "" } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.selectedReport.name)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("localized")("Review.View.CreateReport.12")
                        ) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "row mb-2", staticStyle: { height: "45vh" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col-6 h-100 d-flex",
                        staticStyle: { "flex-direction": "column" },
                      },
                      [
                        _c("label", { staticClass: "small text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.CreateReport.6"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "reportTemplateLists",
                            staticClass: "h-100 border",
                            staticStyle: {
                              "overflow-y": "auto",
                              "overflow-x": "hidden",
                            },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "selection-list" },
                              _vm._l(_vm.reportLists, function (l, idx) {
                                return _c(
                                  "li",
                                  {
                                    key: l.label,
                                    staticClass: "p-2",
                                    class: {
                                      "bg-active":
                                        _vm.selectedListIndex === idx,
                                    },
                                    attrs: { id: `report-list-${idx}` },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectReportList(idx)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { height: "24px" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ellipsis",
                                            staticStyle: {
                                              width: "calc(100% - 40px)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(l.label) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "badge badge-secondary badge-pill float-right",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(l.list.length) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-6 h-100 d-flex",
                        staticStyle: { "flex-direction": "column" },
                      },
                      [
                        _c("label", { staticClass: "small text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.CreateReport.5"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "min-height": "32px",
                              height: "32px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group input-group-sm" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.search,
                                      expression: "search",
                                    },
                                  ],
                                  ref: "reportCreateFilter",
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "input-search-create-report",
                                    type: "text",
                                    autocomplete: "off",
                                  },
                                  domProps: { value: _vm.search },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.search = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-outline-secondary",
                                        attrs: {
                                          id: "btn-clear-search-create-report",
                                          type: "button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.search = ""
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("localized")(
                                                "Review.View.CreateReport.9"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "reportTemplates",
                            staticClass: "h-100 mt-2 border",
                            staticStyle: {
                              "overflow-x": "hidden",
                              "overflow-y": "auto",
                              position: "relative",
                            },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "selection-list" },
                              _vm._l(_vm.filteredTemplates, function (rt, idx) {
                                return _c(
                                  "li",
                                  {
                                    key: idx,
                                    staticClass: "p-2",
                                    class: {
                                      "bg-active":
                                        _vm.selectedTemplateIndex === idx,
                                      "text-muted":
                                        _vm.pendingReportNames[rt.name],
                                    },
                                    attrs: { id: `report-template-${idx}` },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectTemplate(idx)
                                      },
                                      dblclick: function ($event) {
                                        return _vm.handleOK(idx)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex",
                                        staticStyle: { height: "24px" },
                                      },
                                      [
                                        _vm.pendingReportNames[rt.name]
                                          ? _c("Spinner", {
                                              staticClass: "mt-1",
                                              attrs: {
                                                color: "muted",
                                                type: "border",
                                                small: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass:
                                            "mx-1 flex-fill ellipsis",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.highlight(rt.name)
                                            ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        rt.id
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-secondary float-right",
                                                staticStyle: {
                                                  width: "20px",
                                                  "font-size": "12px",
                                                  "margin-top": "3px",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-sync",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("label", { staticClass: "small text-muted" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("localized")("Review.View.CreateReport.4")
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("ReportStatusSelection", {
                  attrs: {
                    "initial-status": _vm.selectedStatus,
                    "available-states": _vm.availableStates,
                    disabled: _vm.isReportStatusSelectionDisabled,
                  },
                  on: {
                    update: function ($event) {
                      _vm.selectedStatus = $event
                    },
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="d-flex position-relative h-100"
    :style="{ minHeight: height + 'px', maxHeight: height + 'px' }"
  >
    <div
      class="h-100 w-100 position-absolute bg-light d-flex justify-content-center align-items-center"
      style="z-index: 11; opacity: 0.4"
      v-if="loading"
    >
      <Spinner type="border" />
    </div>
    <div
      :id="`container-${id}`"
      class="editor-container flex-grow-1 h-100"
      :style="{ minHeight: height + 'px', maxHeight: height + 'px' }"
    >
      <div :id="`toolbar-${id}`" class="editor-toolbar">
        <!-- Add a bold button -->
        <button :id="`btn-toolbar-${id}-bold`" class="ql-bold"></button>
        <button :id="`btn-toolbar-${id}-italic`" class="ql-italic"></button>
        <button
          :id="`btn-toolbar-${id}-underline`"
          class="ql-underline"
        ></button>
        <!-- Add font selection -->
        <select class="ql-font">
          <option value="Arial" selected>Arial</option>
          <option value="Comic Sans MS, ChalkboardSE-Regular">
            Comic Sans MS
          </option>
          <option value="Courier New">Courier New</option>
          <option value="Impact, DINCondensed-Bold">Impact</option>
          <option value="Lucida Grande, Lucida Sans Unicode">
            Lucida Grande
          </option>
          <option value="Palatino">Palatino</option>
          <option value="Tahoma, Helvetica">Tahoma</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Trebuchet MS">Trebuchet MS</option>
          <option value="Verdana">Verdana</option>
        </select>
        <!-- Add font size dropdown -->
        <select class="ql-size">
          <option value="8px">8</option>
          <option value="9px">9</option>
          <option value="10px">10</option>
          <option value="11px">11</option>
          <option value="12px" selected>12</option>
          <option value="14px">14</option>
          <option value="16px">16</option>
          <option value="18px">18</option>
          <option value="20px">20</option>
          <option value="22px">22</option>
          <option value="24px">24</option>
          <option value="26px">26</option>
          <option value="28px">28</option>
          <option value="36px">36</option>
          <option value="48px">48</option>
          <option value="72px">72</option>
        </select>
        <div class="float-right">
          <template v-if="interpretations">
            <button
              @click="insertArtiQ"
              v-b-tooltip.hover.window
              :title="'Review.View.Report.42' | localized"
              class="no-outline d-flex align-items-center justify-content-center"
              v-if="isArtiQActive"
            >
              <span class="fab fa-artiq"></span>
            </button>
            <button
              @click="insertLastAutoInterpretation"
              v-b-tooltip.hover.window
              :title="'Review.View.Report.23' | localized"
              class="no-outline d-flex align-items-center justify-content-center"
              v-if="lastAutoInterpretation"
            >
              <span class="fas fa-paste"></span>
            </button>
            <button
              @click="toggleAutoInterpretations"
              class="no-outline d-flex align-items-center justify-content-center"
              :class="{ 'ql-active': expand === 1 }"
              v-b-tooltip.hover.window
              :title="'Review.View.Report.20' | localized"
            >
              <span class="fas fa-graduation-cap"></span>
            </button>
          </template>
          <button
            @click="toggleComments"
            class="no-outline d-flex align-items-center justify-content-center"
            :class="{ 'ql-active': expand === 0 }"
            v-b-tooltip.hover.window
            :title="'Review.View.Report.24' | localized"
          >
            <span class="fas fa-list-alt"></span>
          </button>
        </div>
      </div>
      <div
        ref="editor"
        @click="clickAway"
        @keydown.ctrl.49.exact.prevent="() => {}"
        @keyup.ctrl.49.exact.prevent="toggleComments"
      ></div>
    </div>
    <EditorInsertPanel
      ref="insertPanel"
      :quill="quill"
      :values="commentTemplates"
      @hide="clickAway"
      @selected="insertComment"
      v-if="expand === 0"
      key="commentTemplates"
    />
    <EditorInsertPanel
      :quill="quill"
      :values="autoInterpretations"
      @hide="clickAway"
      @selected="insertAutoInterpretation"
      v-if="expand === 1"
      key="autoInterpretations"
    />
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

let Toolbar = Quill.import("modules/toolbar");
let Snow = Quill.import("themes/snow");
let Bold = Quill.import("formats/bold");
let Italic = Quill.import("formats/italic");
let Inline = Quill.import("blots/inline");
let Font = Quill.import("attributors/style/font");
let Size = Quill.import("attributors/style/size");

import ApiService from "@/common/api.service";
import { NOTIFICATION_DURATION_ERROR } from "@/common/constants";
import {
  INSERT_ARTIQ,
  UPDATE_LAST_AUTO_INTERPRETATION,
} from "@/store/actions.type";

import Spinner from "@/components/Spinner";
import EditorInsertPanel from "@/components/EditorInsertPanel";

// Disable the link tooltip - used in templates
class LinkBlot extends Inline {
  constructor(props) {
    super(props);
  }
  static create(value) {
    let node = super.create();
    node.setAttribute("href", value.href);
    // required for IE support
    node.setAttribute("disabled", "");
    // custom attribute for macros
    if (value["data-ses-macro"]) {
      node.setAttribute("data-ses-macro", value["data-ses-macro"]);
    }
    return node;
  }

  static formats(node) {
    return {
      "data-ses-macro": node.getAttribute("data-ses-macro"),
      href: node.getAttribute("href"),
    };
  }

  format(name, value) {
    if (value !== "#0000ff") {
      super.format(name, value);
    }
  }
}
LinkBlot.blotName = "link";
LinkBlot.tagName = "a";

// Change underline tool from tag to inline style
class UnderlineBlot extends Inline {
  static create() {
    let node = super.create();
    node.setAttribute("style", "text-decoration:underline");
    return node;
  }

  static formats(node) {
    let style = node.getAttribute("style");
    super.formats(node);
    if (style === null) {
      return undefined;
    }
    if (style.indexOf("color:#0000FF") !== -1) {
      // Is link
      return undefined;
    }
    if (style.indexOf("underline") !== -1) {
      // Is underlined
      return true;
    }
  }
}
UnderlineBlot.blotName = "underline";
UnderlineBlot.tagName = "span";

// Overwrite font whitelist
Font.whitelist = [
  "Arial",
  "Comic Sans MS, ChalkboardSE-Regular",
  "Courier New",
  "Impact, DINCondensed-Bold",
  "Lucida Grande, Lucida Sans Unicode",
  "Palatino",
  "Tahoma, Helvetica",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];

// Overwrite font size whitelist
Size.whitelist = [
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "36px",
  "48px",
  "72px",
];

Quill.register(
  {
    "modules/toolbar": Toolbar,
    "themes/snow": Snow,
    "formats/bold": Bold,
    "formats/italic": Italic,
    "formats/underline": UnderlineBlot,
    "formats/link": LinkBlot,
    "formats/font": Font,
    "formats/size": Size,
  },
  true
);

Quill.prototype.getHtml = function () {
  return this.container.querySelector(".ql-editor").innerHTML;
};

export default {
  components: { EditorInsertPanel, Spinner },
  props: {
    content: { type: String },
    levelID: { type: String },
    id: { type: String },
    height: { type: Number },
    commentTemplates: { type: Object },
    autoInterpretations: { type: Object },
    interpretations: { type: Boolean, default: false },
  },

  data() {
    return {
      expand: -1,
      loading: false,
      quill: null,
    };
  },

  computed: {
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    placeholder() {
      return this.$store.getters.localizedText("Review.View.Report.18");
    },
    lastAutoInterpretation() {
      return this.$store.getters.lastAutoInterpretation;
    },
    isArtiQActive() {
      return this.$store.getters.isArtiQActive;
    },
  },

  watch: {
    placeholder(val) {
      if (val !== this.quill.root.dataset.placeholder) {
        this.quill.root.dataset.placeholder = val;
      }
    },
  },

  mounted() {
    // Create new instance of Quill
    this.quill = new Quill(this.$refs.editor, {
      theme: "snow",
      modules: { toolbar: `#toolbar-${this.id}` },
      placeholder: this.$options.filters.localized("Review.View.Report.18"),
      formats: ["bold", "italic", "underline", "link", "font", "size", "image"],
    });
    this.quill.root.setAttribute("spellcheck", false);

    // insert html
    this.quill.pasteHTML(this.content);

    // Gather and emit changes to parent
    this.quill.on("text-change", () => {
      let editorContent =
        this.quill.getHtml() === "<p><br></p>" ? "" : this.quill.getHtml();
      this.$emit("input", editorContent);
    });

    document.addEventListener("click", this.handleClickOutside);
  },

  beforeDestroy() {
    this.quill = null;
    delete this.quill;
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        // reset expand
        this.expand = -1;
      }
    },
    clickAway() {
      this.expand = -1;
      this.quill.focus();
    },
    toggleComments() {
      if (this.expand === 0) {
        this.expand = -1;
        return;
      }
      this.expand = 0;
    },
    toggleAutoInterpretations() {
      if (this.expand === 1) {
        this.expand = -1;
        return;
      }
      this.expand = 1;
    },
    insertComment(templateID) {
      if (this.loading) {
        return;
      }
      console.log("inserting commentTemplate", this.levelID, templateID);
      this.loading = true;
      ApiService.evaluateCommentTemplate(
        this.selectedVisit.ID,
        this.levelID,
        this.selectedVisit.Patient.ID,
        templateID
      )
        .then((d) => {
          this.loading = false;
          if (this.quill) {
            const range = this.quill.getSelection(true);
            this.quill.deleteText(range.index, range.length);
            this.quill.clipboard.dangerouslyPasteHTML(range.index, d);
          }
          this.$nextTick(() => {
            this.$refs.insertPanel.focus();
          });
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$notify({
            type: "danger",
            duration: NOTIFICATION_DURATION_ERROR,
            text: "Review.View.Messages.13",
          });
        });
    },
    insertArtiQ() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch(INSERT_ARTIQ)
        .then(() => {
          this.insertInterpretationReportText();
          this.$emit("artiq-inserted");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    insertInterpretationReportText() {
      const text = this.selectedVisit.InterpretationReportText;
      if (this.quill) {
        const range = this.quill.getSelection(true);
        this.quill.deleteText(range.index, range.length);
        // interpretation texts are returned as plain text, so use insertText instead of dangerouslyPasteHTML
        // to preserve new lines.
        this.quill.insertText(range.index, text);
        // close editor insert panels
        this.$nextTick(() => {
          this.clickAway();
        });
      }
    },
    insertLastAutoInterpretation() {
      this.insertAutoInterpretation(this.lastAutoInterpretation);
    },
    insertAutoInterpretation(name) {
      if (this.loading) {
        return;
      }
      console.log("inserting autoInterpretation", name);
      this.loading = true;
      const trialIndex = undefined;
      const levelID = undefined;
      ApiService.evaluateAutoInterpretation(
        name,
        this.selectedVisit.ID,
        levelID,
        this.selectedVisit.Patient.ID,
        trialIndex
      )
        .then((d) => {
          this.$store.dispatch(UPDATE_LAST_AUTO_INTERPRETATION, name);
          this.loading = false;
          if (this.quill) {
            const range = this.quill.getSelection(true);
            this.quill.deleteText(range.index, range.length);
            // auto-interpretations are returned as plain text, so use insertText instead of dangerouslyPasteHTML
            // to preserve new lines.
            this.quill.insertText(range.index, d);
          }
          this.$nextTick(() => {
            this.clickAway();
          });
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$notify({
            type: "danger",
            duration: NOTIFICATION_DURATION_ERROR,
            text: "Review.View.Messages.13",
          });
        });
    },
  },
};
</script>

<style lang="scss">
// Fix wrong tooltip positioning. Only necessary if boundary is other than 'scrollParent'.
// See: https://github.com/bootstrap-vue/bootstrap-vue/issues/1732
.tooltip {
  top: 0;
}

.no-outline {
  outline: 0 !important;
}

.editor-container {
  height: 100%;
  overflow: auto;
  padding-top: 40px;
  position: relative;
}
.editor-toolbar {
  height: 40px;
  margin-top: -40px;
}
.ql-editor {
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.ql-snow {
  /* Change the width so that the font names fit on one line. */
  .ql-picker.ql-font {
    width: 160px;
  }

  .ql-picker.ql-size {
    width: 60px;
  }

  /* Scrollable font and size list */
  .ql-font.ql-picker.ql-expanded .ql-picker-options,
  .ql-size.ql-picker.ql-expanded .ql-picker-options {
    overflow-y: scroll;
    height: 140px;
  }

  .ql-picker-item[data-label="Arial"]::before,
  .ql-picker-label[data-label="Arial"]::before {
    font-family: Arial, Helvetica, sans-serif;
  }

  .ql-picker-item[data-label="Comic Sans MS"]::before,
  .ql-picker-label[data-label="Comic Sans MS"]::before {
    font-family: "Comic Sans MS", ChalkboardSE-Regular, cursive, sans-serif;
  }

  .ql-picker-item[data-label="Courier New"]::before,
  .ql-picker-label[data-label="Courier New"]::before {
    font-family: "Courier New", Courier, monospace;
  }

  .ql-picker-item[data-label="Impact"]::before,
  .ql-picker-label[data-label="Impact"]::before {
    font-family: Impact, DINCondensed-Bold, Charcoal, sans-serif;
  }

  .ql-picker-item[data-label="Lucida Grande"]::before,
  .ql-picker-label[data-label="Lucida Grande"]::before {
    font-family: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  }

  .ql-picker-item[data-label="Palatino"]::before,
  .ql-picker-label[data-label="Palatino"]::before {
    font-family: Palatino, "Palatino Linotype", "Book Antiqua", serif;
  }

  .ql-picker-item[data-label="Tahoma"]::before,
  .ql-picker-label[data-label="Tahoma"]::before {
    font-family: Tahoma, Helvetica, Geneva, sans-serif;
  }

  .ql-picker-item[data-label="Times New Roman"]::before,
  .ql-picker-label[data-label="Times New Roman"]::before {
    font-family: "Times New Roman", Times, serif;
  }

  .ql-picker-item[data-label="Trebuchet MS"]::before,
  .ql-picker-label[data-label="Trebuchet MS"]::before {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
  }

  .ql-picker-item[data-label="Verdana"]::before,
  .ql-picker-label[data-label="Verdana"]::before {
    font-family: Verdana, Geneva, sans-serif;
  }
}
</style>

import Vue from "vue";
import unionBy from "lodash/unionBy";

import ApiService from "@/common/api.service";
import {
  NOTIFICATION_DURATION_ERROR,
  REPORT_STATUS_NONE,
} from "@/common/constants";
import { ErrCodeReportInProcess, ErrCodeReportNotFound } from "@/common/errors";
import {
  convertIntToReportStatus,
  convertReportStatusToInt,
  availableReportStates,
  nextReportStatus,
  makeID,
} from "@/common/utils";
import {
  DELETE_REPORT,
  FETCH_VISIT,
  FETCH_VISIT_META_DATA,
  FETCH_VISITS,
  FREEZE_VISIT,
  INSERT_ARTIQ,
  NEW_REPORT,
  SELECT_PAGE,
  SELECT_VISIT,
  SEND_REPORT,
  SIGN_REPORT_PHYSICIAN,
  SIGN_REPORT_TECHNICIAN,
  UNFREEZE_VISIT,
  UPDATE_REPORT,
  UPLOAD_REPORT,
} from "@/store/actions.type";
import {
  APPEND_PLACEHOLDER_REPORT,
  CLEAR_VISITS,
  FETCH_VISIT_META_DATA_SUCCESS,
  UPDATE_VISIT,
  FETCH_VISITS_ERROR,
  FETCH_VISITS_PENDING,
  FETCH_VISITS_SUCCESS,
  REMOVE_REPORT,
  REPORT_OPERATION_DONE,
  REPORT_OPERATION_PENDING,
  RESET,
  SET_CURRENT_PAGE,
  SET_ITEMS_PER_PAGE,
  SET_SELECTION,
  SET_VISIT_STATUS,
  VISIT_OPERATION_DONE,
  VISIT_OPERATION_PENDING,
} from "@/store/mutations.type";
import { checkReportOperation, convertFilterToBackend } from "@/store/util";

// initial state
const initialState = () => {
  return {
    requiresFetchVisits: true,
    fetchingVisits: false,
    itemsPerPage: 100,
    currentPage: 1,
    visitsCount: 0,
    visits: {},
    selection: {
      reportID: null,
      visitID: null,
    },
    visitOperations: {},
    reportOperations: {},
    metaData: {},
  };
};

// getters
const getters = {
  sortedVisits: (state, getters) => {
    const items = Object.values(state.visits).sort((a, b) => {
      if (
        getters.sortBy === "LastName" ||
        getters.sortBy === "Birthdate" ||
        getters.sortBy === "ExternalID"
      ) {
        // compare patient object
        a = a.Patient;
        b = b.Patient;
      }
      if (a[getters.sortBy] > b[getters.sortBy]) {
        return 1;
      }
      if (a[getters.sortBy] < b[getters.sortBy]) {
        return -1;
      }
      return 0;
    });

    if (getters.sortOrder === "Descending") {
      items.reverse();
    }

    return items;
  },
  visits: (state) => state.visits,
  batchModeVisits: (state, getters) => {
    return getters.sortedVisits.filter((v) => v.Reports.length > 0);
  },
  nextVisit: (state, getters) => {
    const bmVisits = getters.batchModeVisits;
    const idx = bmVisits.findIndex((v) => v.ID === getters.selection.visitID);
    // not found / no next
    if (idx === -1 || idx === bmVisits.length) {
      return null;
    }
    return bmVisits[idx + 1];
  },
  prevVisit: (state, getters) => {
    const bmVisits = getters.batchModeVisits;
    const idx = bmVisits.findIndex((v) => v.ID === getters.selection.visitID);
    // not found / no prev
    if (idx <= 0) {
      return null;
    }
    return bmVisits[idx - 1];
  },
  selectedVisit: (state) => state.visits[state.selection.visitID],
  isFetchingVisits: (state) => state.fetchingVisits,
  currentPage: (state) => state.currentPage,
  itemsPerPage: (state) => state.itemsPerPage,
  visitsCount: (state) => state.visitsCount,
  selection: (state) => state.selection,
  selectedReport: (state) => {
    if (!state.selection.reportID || !state.selection.visitID) {
      return false;
    }
    const v = state.visits[state.selection.visitID];
    if (!v) {
      return false;
    }
    const report = v.Reports.find((r) => r.ID === state.selection.reportID);
    if (!report) {
      return false;
    }
    return report;
  },
  availableReportStates: (state, getters) => {
    const report = getters.selectedReport;
    if (!report) {
      return false;
    }
    return availableReportStates(
      convertIntToReportStatus(report.Status).status,
      getters.HISStatusSequence
    );
  },
  nextReportStatus: (state, getters) => {
    const report = getters.selectedReport;
    if (!report) {
      return false;
    }
    return nextReportStatus(convertIntToReportStatus(report.Status).status);
  },
  isVisitOperationPending: (state) => (id) => !!state.visitOperations[id],
  isReportOperationPending: (state) => (id) => !!state.reportOperations[id],
  pendingReportNamesBySelectedVisit: (state) => {
    const res = {};
    Object.values(state.reportOperations).forEach((el) => {
      if (el.visitID === state.selection.visitID) {
        res[el.reportName] = true;
      }
    });
    return res;
  },
  hasSignedPhysicianComment: (state) => {
    if (!state.selection.visitID) {
      return false;
    }
    const meta = state.metaData[state.selection.visitID];
    if (!meta) {
      return false;
    }
    return meta.HasSignedPhysicianComment;
  },
  requiresFetchVisits: (state) => state.requiresFetchVisits,
};

// store last promises to allow cancellation
let fetchVisitsPromise;
// actions
const actions = {
  [DELETE_REPORT]({ commit, dispatch }, { visitID, reportID, reportName }) {
    commit(REPORT_OPERATION_PENDING, { reportID, visitID, reportName });
    return ApiService.deleteReport(visitID, reportID)
      .then((data) => {
        commit(REMOVE_REPORT, { visitID, reportID });
        dispatch(FETCH_VISIT, { visitID });
        Vue.notify({
          type: "success",
          title: reportName,
          text: "Review.View.Messages.16",
        });
      })
      .catch((e) => {
        const notification = {
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: reportName,
          text: "Review.View.Messages.17",
        };
        if (e.response.data.Code === ErrCodeReportInProcess) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID });
      });
  },
  // When silent is true, there is no notification and no temporary report in the report list
  // (with a spinner to indicate it's in progress).
  [UPDATE_REPORT](
    { commit, dispatch },
    { visitID, reportID, reportName, silent = false }
  ) {
    if (!silent) {
      commit(REPORT_OPERATION_PENDING, { reportID, visitID, reportName });
    }
    return ApiService.updateReport(visitID, reportName)
      .then(() => {
        return checkReportOperation(visitID, reportName);
      })
      .then(() => {
        if (!silent) {
          Vue.notify({
            type: "success",
            title: reportName,
            text: "Review.View.Messages.18",
          });
        }
        // return promise to caller
        return dispatch(FETCH_VISIT, { visitID });
      })
      .catch((e) => {
        if (silent) {
          throw e;
        }

        const notification = {
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: reportName,
          text: "Review.View.Messages.19",
        };
        if (e.response.data.Code === ErrCodeReportNotFound) {
          notification.text = "Review.View.DirectLink.5";
        }
        if (e.response.data.Code === ErrCodeReportInProcess) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);

        throw e;
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID });
      });
  },
  [INSERT_ARTIQ]({ getters, dispatch, rootGetters }) {
    const isArtiQActive = rootGetters.isArtiQActive;
    const isArtiQProvidePDF = rootGetters.isArtiQProvidePDF;
    const artiQDisplayName = rootGetters.artiQDisplayName;

    if (!isArtiQActive) {
      return;
    }
    // check if an ArtiQ report exists
    const { ID: visitID, Reports: reports } = getters.selectedVisit;
    const report = reports.find((r) => r.Filename === artiQDisplayName);
    let pr;
    if (report) {
      pr = dispatch(UPDATE_REPORT, {
        visitID,
        reportName: artiQDisplayName,
        reportID: report.ID,
        silent: !isArtiQProvidePDF,
      });
    } else {
      // use the first available state
      const reportStatus = availableReportStates(
        REPORT_STATUS_NONE.status,
        getters.HISStatusSequence
      )[0].status;

      pr = dispatch(NEW_REPORT, {
        reportStatus,
        visitID,
        reportName: artiQDisplayName,
        silent: !isArtiQProvidePDF,
      });
    }
    return pr;
  },
  // When silent is true, there is no notification and no temporary report in the report list
  // (with a spinner to indicate it's in progress).
  [NEW_REPORT](
    { commit, getters, dispatch, rootGetters },
    { visitID, reportName, reportStatus, reportID, silent = false }
  ) {
    let id = reportID;
    if (!silent) {
      if (!reportID) {
        // generate random id
        id = makeID();
        // Type always exist because we can only select a report when it exists in reportTemplates
        const { Type } = rootGetters.reportTemplates.find(
          (rt) => rt.Name === reportName
        );
        commit(APPEND_PLACEHOLDER_REPORT, {
          report: {
            ID: id,
            Filename: reportName,
            Type,
            Status: convertReportStatusToInt(reportStatus),
          },
          visitID,
        });
      }
      commit(REPORT_OPERATION_PENDING, { reportID: id, visitID, reportName });
    }
    return ApiService.generateReport(visitID, reportName, reportStatus)
      .then(() => {
        return checkReportOperation(visitID, reportName);
      })
      .then(() => {
        if (!silent) {
          Vue.notify({
            type: "success",
            title: reportName,
            text: "Review.View.Messages.20",
          });
        }
        // return FETCH_VISIT promise to allow the caller to proceed
        return dispatch(FETCH_VISIT, { visitID });
      })
      .catch((e) => {
        if (silent) {
          // forward exception
          throw e;
        }
        const notification = {
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: reportName,
          text: "Review.View.Messages.21",
        };
        if (!reportID) {
          commit(REMOVE_REPORT, { visitID, reportID: id });
        }
        if (e.response.data.Code === ErrCodeReportInProcess) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);

        // forward exception
        throw e;
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID: id });
      });
  },
  [SEND_REPORT]({ commit, dispatch }, { visitID, reportID, reportName }) {
    commit(REPORT_OPERATION_PENDING, { reportID, visitID, reportName });
    ApiService.sendReport(visitID, reportID)
      .then((data) => {
        Vue.notify({
          type: "success",
          title: reportName,
          text: "Review.View.Messages.5",
        });
        dispatch(FETCH_VISIT, { visitID });
      })
      .catch((e) => {
        const notification = {
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: reportName,
          text: "Review.View.Messages.6",
          data: { message: e.response.data.Message },
        };
        if (e.response.data.Code === ErrCodeReportInProcess) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID });
      });
  },
  [UPLOAD_REPORT]({ commit, getters, dispatch }, { visitID, file }) {
    const maxFileSize = getters.maxImportFileSize;
    if (file.size > maxFileSize) {
      Vue.notify({
        type: "danger",
        title: file.name,
        duration: NOTIFICATION_DURATION_ERROR,
        text: "Review.View.Messages.9",
      });
      return;
    }
    // get report id
    const report = getters.selectedVisit.Reports.find(
      (v) => v.Filename === file.name
    );
    let id = "";
    if (report) {
      id = report.ID;
    } else {
      // generate random id
      id = makeID();
      const reportStatus = getters.HISStatusSequence[0].status;
      commit(APPEND_PLACEHOLDER_REPORT, {
        report: {
          ID: id,
          Filename: file.name,
          Type: "ReportExternal",
          Status: convertReportStatusToInt(reportStatus),
        },
        visitID,
      });
    }
    commit(REPORT_OPERATION_PENDING, {
      reportID: id,
      visitID,
      reportName: file.name,
    });
    ApiService.uploadReport(visitID, file)
      .then(() => {
        Vue.notify({
          type: "success",
          title: file.name,
          text: "Review.View.Messages.8",
        });
        dispatch(FETCH_VISIT, { visitID });
      })
      .catch((e) => {
        // in case we get an error here, remove the report from the list
        commit(REMOVE_REPORT, { visitID, reportID: id });
        const notification = {
          type: "danger",
          title: file.name,
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.7",
        };
        if (e.response.data.Code === ErrCodeReportInProcess) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID: id });
      });
  },
  [FETCH_VISITS]({ commit, state, rootState }, { paging }) {
    commit(FETCH_VISITS_PENDING);
    // whenever we fetch the visits we reset the current selection
    commit(SET_SELECTION, { reportID: null, visitID: null });
    // reset pagination & visits if the event was not triggered by the pagination controls
    if (!paging) {
      commit(SET_CURRENT_PAGE, 1);
      commit(CLEAR_VISITS);
    }
    // if patientID is present, we're in the patient mode
    let search = {
      PatientID: rootState.configuration.patientMode,
    };
    if (!search.PatientID) {
      // convert filter
      search = convertFilterToBackend(
        rootState.configuration.searchFilter,
        rootState.configuration.filterValues.Locations,
        rootState.configuration.settings.UseLocationFilter
      );
    }
    // calculate start index
    const promise = ApiService.fetchVisits({
      MaxCount: state.itemsPerPage,
      StartIndex: state.itemsPerPage * (state.currentPage - 1),
      ...search,
    });
    fetchVisitsPromise = promise;
    return promise
      .then((data) => {
        if (fetchVisitsPromise !== promise) {
          console.log(`${FETCH_VISITS}: discarded old result set`);
          // outdated data skip it
          return;
        }
        // normalize into { visitId: { ... }, ... }
        const visits = {};
        data.Visits.forEach((v) => {
          visits[v.ID] = v;
          // sort reports
          v.Reports = v.Reports.concat().sort((a, b) =>
            a.Filename.localeCompare(b.Filename)
          );
        });
        commit(FETCH_VISITS_SUCCESS, { visitsCount: data.Count, visits });
      })
      .catch((e) => {
        if (fetchVisitsPromise !== promise) {
          console.log(`${FETCH_VISITS_ERROR}: discarded old error`);
          // outdated data skip it
          return;
        }
        commit(FETCH_VISITS_ERROR, e);
        commit(CLEAR_VISITS);
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
      });
  },
  // If addToStore is false the visit is only updated when it is still in the store.
  // If addToStore is true the visit is always added to the store (e.g. for page reload).
  // This behavior is important e.g. if the user or the program flow has changed the visit list and the visit is no longer visible.
  [FETCH_VISIT]({ commit, dispatch, state }, { visitID, addToStore = false }) {
    commit(VISIT_OPERATION_PENDING, { visitID });
    return ApiService.fetchVisit(visitID)
      .then((data) => {
        // In case the visit was already loaded, we might have pending reports, which are not yet returned by the API, so that we need
        // to merge them here.
        let reports = data.Reports;
        if (state.visits[visitID]) {
          reports = unionBy(
            data.Reports, // contains the reports returned by the backend
            state.visits[visitID].Reports,
            (r) => {
              // Due to the handling of the external reports, we need to use the same identifier for signed and
              // unsigned external reports, e.g. simply strip the " Signed" suffix.
              return r.Filename.replace(/ Signed$/, "");
            }
          );
        }
        // sort reports
        data.Reports = reports
          .concat()
          .sort((a, b) => a.Filename.localeCompare(b.Filename));

        if (addToStore || state.visits[data.ID]) {
          commit(UPDATE_VISIT, { visit: data });
        }
        commit(VISIT_OPERATION_DONE, { visitID });
        // the visit's meta data might be outdated now, hence we need to refresh it
        dispatch(FETCH_VISIT_META_DATA, { visitID });
      })
      .catch((e) => {
        commit(VISIT_OPERATION_DONE, { visitID });
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
      });
  },
  [SELECT_PAGE]({ commit, dispatch, getters }, { pageNumber }) {
    commit(SET_CURRENT_PAGE, pageNumber);
    return dispatch(FETCH_VISITS, { paging: true });
  },
  [SELECT_VISIT]({ commit, getters, dispatch }, { visitID, reportID }) {
    commit(SET_SELECTION, { visitID, reportID });
  },
  [FREEZE_VISIT]({ commit }, { visitID, visitStatus }) {
    commit(VISIT_OPERATION_PENDING, { visitID });
    return ApiService.freezeVisit(visitID, visitStatus)
      .then((data) => {
        commit(VISIT_OPERATION_DONE, { visitID });
        commit(SET_VISIT_STATUS, {
          visitID,
          newVisitStatus: data.NewVisitStatus,
          freeze: true,
        });
      })
      .catch((e) => {
        commit(VISIT_OPERATION_DONE, { visitID });
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
      });
  },
  [UNFREEZE_VISIT]({ commit }, { visitID }) {
    commit(VISIT_OPERATION_PENDING, { visitID });
    return ApiService.unfreezeVisit(visitID)
      .then((data) => {
        commit(VISIT_OPERATION_DONE, { visitID });
        commit(SET_VISIT_STATUS, {
          visitID,
          newVisitStatus: data.NewVisitStatus,
          freeze: false,
        });
      })
      .catch((e) => {
        commit(VISIT_OPERATION_DONE, { visitID });
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
      });
  },
  [SIGN_REPORT_PHYSICIAN](
    { commit, getters, dispatch },
    { visitID, reportID, newState, token, reportName }
  ) {
    commit(REPORT_OPERATION_PENDING, { reportID, visitID, reportName });
    return ApiService.signAsPhysician(visitID, reportID, newState, token)
      .then(() => {
        return checkReportOperation(visitID, reportName);
      })
      .then(() => {
        Vue.notify({
          type: "success",
          title: reportName,
          text: "Review.View.Messages.2",
        });
        // check if we need to proceed with sendReport
        if (!getters.isHISAvailable || !getters.isAutoSendToHISEnabled) {
          return;
        }
        // trim potential " Signed" suffix from the name, to make sure it works for already signed external reports.
        let normalizedName = reportName.replace(/ Signed$/, "");
        // If the report is external signing will create a new report with
        // a new ID and the suffix " Signed". In order to send the new report,
        // we need to fetch the visit here.
        return ApiService.fetchVisit(visitID).then((data) => {
          const report = data.Reports.find((r) => {
            const name = r.Filename.split(" Signed");
            return name[0] === normalizedName;
          });
          dispatch(SEND_REPORT, {
            visitID,
            reportID: report.ID,
            reportName: report.Filename,
          });
        });
      })
      .catch((e) => {
        const notification = {
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: reportName,
          text: "Review.View.Messages.1",
        };
        if (
          e.response &&
          e.response.data &&
          e.response.data.Code === ErrCodeReportInProcess
        ) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID });
        dispatch(FETCH_VISIT, { visitID });
      });
  },
  [SIGN_REPORT_TECHNICIAN](
    { commit },
    { visitID, reportID, token, reportName }
  ) {
    commit(REPORT_OPERATION_PENDING, { reportID, visitID, reportName });
    return ApiService.signAsTechnician(visitID, reportID, token)
      .then(() => {
        return checkReportOperation(visitID, reportName);
      })
      .then(() => {
        Vue.notify({
          type: "success",
          title: reportName,
          text: "Review.View.Messages.2",
        });
      })
      .catch((e) => {
        const notification = {
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          title: reportName,
          text: "Review.View.Messages.1",
        };
        if (
          e.response &&
          e.response.data &&
          e.response.data.Code === ErrCodeReportInProcess
        ) {
          notification.text = "Review.View.Messages.15";
        }
        Vue.notify(notification);
      })
      .finally(() => {
        commit(REPORT_OPERATION_DONE, { reportID });
      });
  },
  [FETCH_VISIT_META_DATA]({ commit }, { visitID }) {
    ApiService.fetchVisitMetaData(visitID)
      .then((data) => {
        commit(FETCH_VISIT_META_DATA_SUCCESS, { visitID, meta: data });
      })
      .catch((e) => {
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
      });
  },
};

// mutations
const mutations = {
  [RESET](state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  [FETCH_VISITS_PENDING](state) {
    console.log("fetching visits");
    state.fetchingVisits = true;
  },
  [FETCH_VISITS_SUCCESS](state, { visitsCount, visits }) {
    // copy & overwrite old visits
    state.visitsCount = visitsCount;
    state.visits = { ...visits };
    state.fetchingVisits = false;
    state.requiresFetchVisits = false;
  },
  [FETCH_VISITS_ERROR](state, err) {
    console.log("fetching visits failed:", err);
    state.fetchingVisits = false;
  },
  [UPDATE_VISIT](state, { visit }) {
    Vue.set(state.visits, visit.ID, visit);
  },
  [CLEAR_VISITS](state) {
    state.visits = {};
    state.visitsCount = 0;
  },
  [SET_ITEMS_PER_PAGE](state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage;
  },
  [SET_CURRENT_PAGE](state, currentPage) {
    state.currentPage = currentPage;
  },
  [SET_SELECTION](state, { reportID, visitID }) {
    state.selection.reportID = reportID;
    state.selection.visitID = visitID;
  },
  [VISIT_OPERATION_PENDING](state, { visitID }) {
    Vue.set(state.visitOperations, visitID, true);
  },
  [VISIT_OPERATION_DONE](state, { visitID }) {
    Vue.delete(state.visitOperations, visitID);
  },
  [REPORT_OPERATION_PENDING](state, { reportID, visitID, reportName }) {
    Vue.set(state.reportOperations, reportID, { visitID, reportName });
  },
  [REPORT_OPERATION_DONE](state, { reportID }) {
    Vue.delete(state.reportOperations, reportID);
  },
  [SET_VISIT_STATUS](state, { visitID, newVisitStatus, freeze }) {
    // update visit from list
    const v = state.visits[visitID];
    if (!v) {
      return;
    }
    v.ReviewStatusFreeze = freeze;
    v.ReviewStatus = newVisitStatus;
  },
  [APPEND_PLACEHOLDER_REPORT](state, { visitID, report }) {
    const v = state.visits[visitID];
    if (!v) {
      return;
    }
    v.Reports.push(report);
    v.Reports = v.Reports.concat().sort((a, b) =>
      a.Filename.localeCompare(b.Filename)
    );
  },
  [REMOVE_REPORT](state, { visitID, reportID }) {
    const v = state.visits[visitID];
    if (!v) {
      return;
    }
    const idx = v.Reports.findIndex((r) => r.ID === reportID);
    if (idx !== -1) {
      v.Reports.splice(idx, 1);
    }
  },
  [FETCH_VISIT_META_DATA_SUCCESS](state, { visitID, meta }) {
    Vue.set(state.metaData, visitID, meta);
  },
};

export default {
  state: initialState(),
  actions,
  mutations,
  getters,
};

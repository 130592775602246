var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._l(_vm.filters, function (f, idx) {
        return [
          f === "ExternalID"
            ? _c("FilterText", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "LastName"
            ? _c("FilterText", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "FirstName"
            ? _c("FilterText", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "HISVisitID" && _vm.isHISAvailable
            ? _c("FilterText", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "HISOrderID" && _vm.isHISAvailable
            ? _c("FilterText", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "Type"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.patientTypeOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "Location" && _vm.useLocationFilter
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.locationOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "Location" && !_vm.useLocationFilter
            ? _c("FilterTypeahead", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.locationOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "Operator"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.operatorOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "ReferringPhysician"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.refPhysicianOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "Physician"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.physicianOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "ReportStatusPreliminary"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.reportStatusOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "ReportStatusFinal"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.reportStatusOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "ReportStatusCorrection"
            ? _c("FilterSelection", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  options: _vm.reportStatusOptions,
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "VisitStatus"
            ? _c("FilterVisitStatus", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          f === "Date"
            ? _c("FilterDate", {
                key: `${f}-${idx}`,
                staticClass: "col-12 col-md-6 col-xl-3",
                attrs: {
                  "initial-value": _vm.currentFilter[f],
                  label: _vm.filterLabels[f],
                },
                on: {
                  update: function ($event) {
                    return _vm.updateCurrentFilter(f, $event)
                  },
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
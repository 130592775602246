<template>
  <BaseLayout>
    <template v-slot:content>
      <div
        class="bg-white d-flex flex-column"
        :style="{ flex: '1 1 0% !important', minHeight: 0 }"
      >
        <div class="container-fluid">
          <div
            class="row pt-2 border-bottom"
            style="border-width: 2px !important"
          >
            <div class="col-10" style="min-height: 35px">
              <ReportTabs
                :reports="selectedVisit.Reports"
                @new-report="handleNewReport"
                @select-report="handleSelectReport"
                v-if="selectedVisit && !loading"
              />
            </div>
            <div class="col-2 d-flex justify-content-end align-items-end">
              <div class="pb-1">
                <BatchControls :disabled="loading" />
              </div>
            </div>
          </div>
        </div>
        <div :style="{ flex: '1 1 0% !important', minHeight: 0 }">
          <div class="h-100">
            <Split
              class="h-100"
              @onDragStart="onDragStart"
              @onDragEnd="onDragEndVertical"
            >
              <SplitArea :size="split.pdf" :minSize="200">
                <div class="h-100 w-100 position-relative">
                  <div
                    class="h-100 w-100 position-absolute bg-light d-flex justify-content-center align-items-center"
                    style="z-index: 11; opacity: 0.4"
                    v-if="
                      selectedReport &&
                      pendingReportNamesBySelectedVisit[selectedReport.Filename]
                    "
                  >
                    <Spinner type="border" />
                  </div>
                  <iframe
                    class="w-100 h-100"
                    id="embedded-viewer"
                    src="static/web/viewer.html#zoom=auto"
                    ref="pdfViewer"
                    @load="iframeLoaded"
                    :style="iframeStyle"
                  ></iframe>
                </div>
              </SplitArea>
              <SplitArea
                class="h-100 bg-light"
                :size="split.commentArea"
                :minSize="480"
              >
                <div class="h-100 d-flex flex-column">
                  <div class="container-fluid" style="min-height: 60px">
                    <div class="row pb-3" v-if="selectedVisit">
                      <div class="col-3">
                        <div class="small text-muted">
                          {{ "Review.View.VisitList.1" | localized }}
                        </div>
                        {{ selectedVisit.LocalDate | datetime }}
                      </div>
                      <div class="col-3">
                        <div class="small text-muted">
                          {{ "Review.View.VisitList.3" | localized }}
                        </div>
                        <PatientName
                          :firstName="selectedVisit.Patient.FirstName"
                          :middleName="selectedVisit.Patient.MiddleName"
                          :lastName="selectedVisit.Patient.LastName"
                        />
                      </div>
                      <div class="col-3">
                        <div class="small text-muted">
                          {{ "Review.View.VisitList.8" | localized }}
                        </div>
                        {{ selectedVisit.Patient.ExternalID }}
                      </div>
                      <div class="col-3">
                        <div class="small text-muted">
                          {{ "Review.View.VisitList.11" | localized }}
                        </div>
                        <template v-if="!isVisitOperationPending">
                          <VisitStatusFreeze
                            v-if="isFreezeEnabled && hasFreezeRight"
                            :visit="selectedVisit"
                          />
                          <VisitStatus
                            :visitID="selectedVisit.ID"
                            :freeze="selectedVisit.ReviewStatusFreeze"
                            :status="selectedVisit.ReviewStatus"
                            v-else
                          />
                        </template>
                        <Spinner type="border" small v-else></Spinner>
                      </div>
                    </div>
                  </div>
                  <Split
                    class="h-100 bg-white"
                    direction="vertical"
                    v-if="isCommentingAllowed"
                    @onDragEnd="onDragEndHorizontal"
                  >
                    <SplitArea
                      :size="split.editor"
                      :minSize="200"
                      style="overflow-y: hidden"
                    >
                      <div
                        class="h-100 d-flex flex-column"
                        :style="{ flex: '1 1 0% !important', minHeight: 0 }"
                      >
                        <div style="height: 40px">
                          <div class="d-flex bg-light">
                            <div
                              class="p-2 w-100 text-center rounded-top comment-tab"
                              style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                              "
                              :class="{
                                active: selection === 'physician',
                              }"
                              @click="editPhysician"
                            >
                              <span class="fas fa-user-md mr-1"></span>
                              {{ "Review.View.Report.14" | localized }}
                            </div>
                            <div
                              class="p-2 w-100 text-center rounded-top comment-tab"
                              style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                              "
                              :class="{
                                active: selection === 'technician',
                              }"
                              @click="editTechnician"
                            >
                              <span class="fas fa-user-nurse mr-1"></span>
                              {{ "Review.View.Report.15" | localized }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex-grow-1 flex-shrink-1 position-relative"
                          style="height: calc(100% - 80px) !important"
                          v-if="selection === 'physician'"
                        >
                          <div class="h-100 p-3" v-if="!loading">
                            <Editor
                              :content="activePhysicianComment.Text"
                              @input="updatePhysicianComment($event)"
                              @artiq-inserted="handleArtiQInserted"
                              :key="selectedVisit.ID"
                              :comment-templates="commentTemplates"
                              :auto-interpretations="autoInterpretations"
                              interpretations
                              v-if="selectedVisit"
                            />
                          </div>
                          <div
                            v-else
                            class="h-100 p-3 d-flex align-items-center justify-content-center"
                          >
                            <Spinner type="grow"></Spinner>
                          </div>
                        </div>
                        <div
                          class="flex-grow-1 flex-shrink-1 position-relative"
                          style="
                            height: calc(100% - 80px) !important;
                            overflow-y: scroll;
                          "
                          v-else
                        >
                          <div class="h-100 p-3" v-if="!loading">
                            <div
                              class="d-flex mb-4"
                              v-for="(c, idx) in activeTechnicianComments"
                              :key="idx"
                            >
                              <div class="small text-right pr-2">
                                <span class="font-weight-bold">
                                  {{ levelLabels[c.levelType] | localized }}
                                  {{ c.levelSequence }}
                                </span>
                                <br />
                                {{ c.LocalDate | date }}
                                <br />
                                {{ c.Author }}
                              </div>
                              <div class="w-100" style="min-width: 0">
                                <Editor
                                  :height="190"
                                  :id="`editor-${c.LinkID}`"
                                  :levelID="c.LinkID"
                                  :content="c.Text"
                                  @input="updateTechnicianComment(idx, $event)"
                                  :comment-templates="commentTemplates"
                                  :key="c.LinkID"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="h-100 p-3 d-flex align-items-center justify-content-center"
                          >
                            <Spinner type="grow" />
                          </div>
                        </div>
                        <div
                          class="w-100 text-right bg-white"
                          style="height: 40px"
                        >
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col w-100">
                                <TestReview v-if="showTestReview"></TestReview>
                                <button
                                  id="btn-save-report"
                                  class="btn btn-sm btn-outline-secondary"
                                  :disabled="isSaveButtonDisabled"
                                  @click="onSave"
                                >
                                  <Spinner
                                    v-if="commentOperationPending"
                                    type="border"
                                    color="black"
                                    small
                                  />
                                  {{ "Review.View.Report.4" | localized }}
                                </button>
                                <button
                                  v-if="showSignButton"
                                  id="btn-sign-report"
                                  class="btn btn-sm btn-outline-secondary"
                                  :disabled="isSignButtonDisabled"
                                  @click="onSign"
                                >
                                  {{ "Review.View.Report.5" | localized }}
                                </button>
                                <button
                                  class="btn ml-2 btn-sm btn-outline-secondary"
                                  @click="onCancel"
                                >
                                  <template v-if="isBatchMode">
                                    {{ "Review.View.Report.7" | localized }}
                                  </template>
                                  <template v-else>
                                    {{ "Review.View.Report.8" | localized }}
                                  </template>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SplitArea>
                    <SplitArea :size="split.commentHistory" :minSize="50">
                      <CommentHistory
                        :comments="comments"
                        :showOpenTechnicianComments="selection === 'physician'"
                        :activeTechnicianComments="activeTechnicianComments"
                        v-if="!loading"
                      />
                      <div
                        v-else
                        class="h-100 p-3 d-flex align-items-center justify-content-center"
                      >
                        <Spinner type="grow" />
                      </div>
                    </SplitArea>
                  </Split>
                  <template v-else>
                    <div style="border-bottom: 8px solid #eee">
                      <div class="container-fluid bg-white">
                        <div class="row">
                          <div class="col w-100 pt-2 pb-2 text-right">
                            <button
                              class="btn ml-2 btn-sm btn-outline-secondary"
                              @click="onCancel"
                            >
                              <template v-if="isBatchMode">
                                {{ "Review.View.Report.7" | localized }}
                              </template>
                              <template v-else>
                                {{ "Review.View.Report.8" | localized }}
                              </template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CommentHistory
                      class="flex-grow-1 bg-white"
                      style="overflow-y: scroll"
                      :comments="comments"
                      :showOpenTechnicianComments="false"
                      :activeTechnicianComments="activeTechnicianComments"
                    />
                  </template>
                </div>
              </SplitArea>
            </Split>
          </div>

          <template v-if="selectedReport">
            <ReportModalCreate
              ref="createReportModal"
              @report-created="handleReportCreated"
            />

            <ReportModalUpdate ref="updateReportModal" @hide="navigate" />

            <ReportModalSignPhysician
              ref="physicianSignReportModal"
              :comment="activePhysicianComment.Text"
              @hide="onPhysicianSignReportModalHide"
            />

            <ReportModalSignTechnician
              ref="technicianSignReportModal"
              :comments="activeTechnicianComments"
              @hide="onTechnicianSignReportModalHide"
            />
          </template>
        </div>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import { FETCH_VISIT, FETCH_LEVELS, SELECT_VISIT } from "@/store/actions.type";
import ApiService from "@/common/api.service";
import {
  COMMENT_STATUS_OPEN,
  REPORT_TYPE_EXTERNAL,
  LEVEL_LANG_MAPPING,
  NOTIFICATION_DURATION_ERROR,
  REPORT_VIEW_DIVIDERS,
  REPORT_TYPE_INTERPRETATION,
  REPORT_TYPE_OUTPUT,
} from "@/common/constants";
import { displayLevelSequence, isHTMLEmpty } from "@/common/utils";
import { changeVisit } from "@/desktop";

import BaseLayout from "@/components/BaseLayout";
import Editor from "@/components/Editor";
import ReportModalCreate from "@/components/ReportModalCreate";
import ReportModalUpdate from "@/components/ReportModalUpdate";
import Spinner from "@/components/Spinner";
import VisitStatus from "@/components/VisitStatus";
import VisitStatusFreeze from "@/components/VisitStatusFreeze";

import BatchControls from "@/components/views/report/BatchControls";
import ReportTabs from "@/components/views/report/ReportTabs";
import TestReview from "@/components/views/report/TestReview";
import ReportModalSignPhysician from "@/components/views/report/ReportModalSignPhysician";
import ReportModalSignTechnician from "@/components/views/report/ReportModalSignTechnician";
import CommentHistory from "@/components/views/report/CommentHistory";

export default {
  name: "Report",
  components: {
    ReportModalCreate,
    BaseLayout,
    Spinner,
    CommentHistory,
    Editor,
    BatchControls,
    ReportTabs,
    ReportModalUpdate,
    VisitStatus,
    VisitStatusFreeze,
    ReportModalSignPhysician,
    ReportModalSignTechnician,
    TestReview,
  },
  data() {
    return {
      dragging: false,
      split: {
        pdf: 50,
        commentArea: 50,
        editor: 50,
        commentHistory: 50,
      },
      loaded: false,
      loading: false,
      commentOperationPending: false,
      activePhysicianComment: {
        Text: "",
        LocalDate: new Date().toISOString(),
        Status: COMMENT_STATUS_OPEN,
      },
      // comments ordered by level
      activeTechnicianComments: [],
      selection: "physician",
      commentTemplates: {},
      autoInterpretations: {},
      // raw comments response
      comments: [],
      prevRoute: null,
    };
  },
  computed: {
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    selectedReport() {
      return this.$store.getters.selectedReport;
    },
    levelLabels() {
      return LEVEL_LANG_MAPPING;
    },
    isVisitOperationPending() {
      return this.$store.getters.isVisitOperationPending(this.selectedVisit.ID);
    },
    isTechnicianSignAllowed() {
      return this.$store.getters.isTechnicianSignAllowed;
    },
    isSaveButtonDisabled() {
      // the save button is disabled if a comment operation is pending
      if (this.commentOperationPending) {
        return true;
      }
      // the save button is always disabled if the selected report is in processing
      if (
        this.pendingReportNamesBySelectedVisit[this.selectedReport.Filename]
      ) {
        return true;
      }
      // modify right applies to physician + technician
      return !this.$store.getters.hasModifyRight;
    },
    isSignButtonDisabled() {
      // the sign button is always disabled if the selected report is in processing
      if (
        this.pendingReportNamesBySelectedVisit[this.selectedReport.Filename]
      ) {
        return true;
      }
      if (this.selection === "physician") {
        // The sign button is disabled if
        //  - the user does not have the PHYSICIAN_SIGN right
        //  - the setting 'technician sign' is enabled and there are open technician comments (saved but not signed yet)
        //  - the setting 'sign without comment' is disabled and there is no physician comment
        const hasSignRight = this.$store.getters.hasPhysicianSignRight;
        const techSignAllowed = this.$store.getters.isTechnicianSignAllowed;
        const withoutCommentAllowed = this.$store.getters
          .isSignReportWithoutCommentAllowed;
        const isEditorEmpty = isHTMLEmpty(this.activePhysicianComment.Text);
        return (
          !hasSignRight ||
          (techSignAllowed && this.hasUnsignedTechnicianComments) ||
          (!withoutCommentAllowed && isEditorEmpty)
        );
      } else {
        // Sign Button is disabled if
        //  - user has no technician sign right
        //  - there is no technician comment
        const isCommentEmpty = this.activeTechnicianComments.every((c) => {
          return isHTMLEmpty(c.Text);
        });
        return !this.$store.getters.hasTechnicianSignRight || isCommentEmpty;
      }
    },
    hasUnsignedTechnicianComments() {
      return !this.activeTechnicianComments.every((c) => {
        return isHTMLEmpty(c.Text);
      });
    },
    isCommentingAllowed() {
      return (
        this.$store.getters.isCommentingOfExternalReportsAllowed ||
        (this.selectedReport &&
          this.selectedReport.Type !== REPORT_TYPE_EXTERNAL)
      );
    },
    isDesktopMode() {
      return this.$store.getters.isDesktopMode;
    },
    isFreezeEnabled() {
      return this.$store.getters.isFreezeEnabled;
    },
    hasFreezeRight() {
      return this.$store.getters.hasFreezeRight;
    },
    isBatchMode() {
      return this.$store.getters.isBatchMode;
    },
    nextVisit() {
      return this.$store.getters.nextVisit;
    },
    iframeStyle() {
      if (this.dragging) {
        return {
          pointerEvents: "none",
          position: "relative",
        };
      }
      return {};
    },
    showTestReview() {
      if (
        this.selection !== "technician" ||
        !this.$store.getters.hasSetTestReviewRight ||
        !this.$store.getters.isTestReviewEnabled
      ) {
        return false;
      }
      const visit = this.selectedVisit;
      const cfg = this.$store.getters.activeWorkflowConfigurations;
      const i = cfg.findIndex((c) => c.VisitStatus === visit.ReviewStatus);
      if (!cfg[i].UseAutoTrigger) {
        return true;
      }
      // no next workflow
      if (i + 1 >= cfg.length) {
        return false;
      }
      return !cfg[i + 1].UseAutoTrigger;
    },
    showSignButton() {
      if (
        this.selectedReport &&
        this.selectedReport.Type === REPORT_TYPE_INTERPRETATION
      ) {
        return false;
      }
      return this.selection !== "technician" || this.isTechnicianSignAllowed;
    },
    pendingReportNamesBySelectedVisit() {
      return this.$store.getters.pendingReportNamesBySelectedVisit;
    },
  },
  beforeRouteEnter(to, from, next) {
    // inject prev route
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute, oldRoute) {
        if (!oldRoute) {
          this.loadReport();
          this.loadVisit();
          return;
        }
        const { visitID, reportID } = newRoute.params;
        const { visitID: oldVisitID, reportID: oldReportID } = oldRoute.params;
        if (reportID !== oldReportID) {
          this.loadReport();
        }
        if (visitID !== oldVisitID) {
          this.loadVisit();
        }
      },
    },
  },
  mounted() {
    const split = JSON.parse(sessionStorage.getItem(REPORT_VIEW_DIVIDERS));
    if (split) {
      this.split = split;
    }
  },
  methods: {
    handleSelectReport(event) {
      const { reportID, reportName } = event;
      // no-op if operation pending
      if (this.pendingReportNamesBySelectedVisit[reportName]) {
        return;
      }
      const visitID = this.selectedVisit.ID;
      // update url and sync selection
      this.$router
        .replace({
          name: "report",
          params: { visitID, reportID },
        })
        .catch((err) => console.log(err));
      this.$store.dispatch(SELECT_VISIT, {
        visitID,
        reportID,
      });
    },
    handleNewReport() {
      this.$refs.createReportModal.show();
    },
    handleReportCreated(event) {
      const { visitID, reportName, background } = event;
      const r = this.selectedVisit.Reports.find(
        (r) => r.Filename === reportName
      );
      if (!r) {
        console.log(
          "handleReportCreated(): report not found for visit",
          visitID,
          reportName
        );
        return;
      }
      // simply re-load the report if it's already selected
      if (r.ID === this.selectedReport.ID) {
        this.loadReport();
        return;
      }
      // only try and redirect if the modal was visible why the report was created => hence
      // background is set to false
      if (!background) {
        // try to switch to report
        this.$router
          .replace({
            name: "report",
            params: {
              visitID,
              reportID: r.ID,
            },
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$store.dispatch(SELECT_VISIT, { visitID, reportID: r.ID });
          });
      }
    },
    updatePhysicianComment(body) {
      this.activePhysicianComment.Text = body;
    },
    updateTechnicianComment(idx, body) {
      this.activeTechnicianComments[idx].Text = body;
    },
    editPhysician() {
      this.selection = "physician";
    },
    editTechnician() {
      this.selection = "technician";
    },
    clear() {
      this.activePhysicianComment = {
        Text: "",
        LocalDate: new Date().toISOString(),
        Status: COMMENT_STATUS_OPEN,
      };
      this.activeTechnicianComments = [];
      this.comments = [];
    },
    loadVisit() {
      this.clear();
      const { visitID, reportID } = this.$route.params;
      this.loading = true;
      Promise.all([
        ApiService.fetchComments(visitID),
        ApiService.fetchCommentTemplates(),
        ApiService.fetchAutoInterpretations(),
        // fetch levels via store to make sure we're in sync when navigating back to visit list
        this.$store.dispatch(FETCH_LEVELS, { visitID }),
        this.$store.dispatch(FETCH_VISIT, {
          visitID: visitID,
          addToStore: true,
        }),
      ]).then((values) => {
        this.loading = false;
        // if we already navigated to another visit do nothing
        if (this.$route.params.visitID !== visitID) {
          console.log(
            "discarding old results",
            this.$route.params.visitID,
            visitID
          );
          return;
        }
        // update vuex store to reflect the selected visit when navigating back to the visit list
        this.$store.dispatch(SELECT_VISIT, { visitID, reportID });

        if (this.isDesktopMode) {
          changeVisit(visitID, this.selectedVisit.Patient.ID);
        }

        this.comments = values[0];
        this.commentTemplates = values[1];
        // convert auto interpretations to tree
        this.autoInterpretations = {
          Name: "autointerpretations",
          Children: [],
        };
        Object.keys(values[2]).forEach((k) => {
          if (k === "AAANoInterpretation") {
            return;
          }
          this.autoInterpretations.Children.push({
            Name: values[2][k],
            ID: k,
          });
        });
        // get active physician comment if available
        const activePhysicianComment = this.comments.find(
          (c) => c.Type === "Physician" && c.Status === COMMENT_STATUS_OPEN
        );
        if (activePhysicianComment) {
          this.activePhysicianComment = activePhysicianComment;
        }
        const commentsToLevel = {};
        this.comments.forEach((c) => {
          if (c.Type === "Technician") {
            commentsToLevel[c.LinkID] = c;
          }
        });
        const levels = this.$store.getters.levels;
        levels.forEach((lvl) => {
          const comment = commentsToLevel[lvl.ID];
          const displaySequence = displayLevelSequence(levels, lvl.Type);
          if (comment) {
            this.activeTechnicianComments.push({
              ...comment,
              levelType: lvl.Type,
              levelSequence: displaySequence ? lvl.Sequence : "",
            });
            return;
          }
          this.activeTechnicianComments.push({
            Text: "",
            LocalDate: new Date().toISOString(),
            LinkID: lvl.ID,
            levelType: lvl.Type,
            levelSequence: displaySequence ? lvl.Sequence : "",
          });
        });
      });
    },
    iframeLoaded() {
      this.loaded = true;
      this.loadReport();
    },
    handleArtiQInserted() {
      // only reload the selected report if it's an ArtiQ report and IsArtiQProvidePDF is set to true
      if (
        this.selectedReport.Filename !== this.$store.getters.artiQDisplayName ||
        !this.$store.getters.isArtiQProvidePDF
      ) {
        return;
      }
      this.loadReport();
    },
    loadReport() {
      if (!this.loaded) {
        return;
      }
      const { reportID, visitID } = this.$route.params;
      if (!reportID || !visitID) {
        return;
      }
      if (this.$refs.pdfViewer.contentWindow.PDFViewerApplication) {
        this.$refs.pdfViewer.contentWindow.PDFViewerApplication.close();
      }
      ApiService.fetchReportPDF(visitID, reportID).then((d) => {
        this.$refs.pdfViewer.contentWindow.PDFViewerApplication.open(d);
      });
    },
    onCancel() {
      // if we had an previous route != login and != directLink, navigate back to it. Otherwise navigate to "/"
      if (
        this.prevRoute &&
        this.prevRoute.name &&
        this.prevRoute.name !== "login" &&
        this.prevRoute.name !== "directLink"
      ) {
        this.$router.go(-1);
        return;
      }
      // we had no previous route =>
      this.$router.push({ name: "visits" }).catch((err) => console.log(err));
    },
    onDragStart() {
      this.dragging = true;
    },
    onDragEndVertical(size) {
      this.dragging = false;
      [this.split.pdf, this.split.commentArea] = size;
      sessionStorage.setItem(REPORT_VIEW_DIVIDERS, JSON.stringify(this.split));
    },
    onDragEndHorizontal(size) {
      [this.split.editor, this.split.commentHistory] = size;
      sessionStorage.setItem(REPORT_VIEW_DIVIDERS, JSON.stringify(this.split));
    },
    navigate() {
      if (this.isBatchMode && this.nextVisit) {
        // replace the route, to allow using history back for redirecting back on cancel
        this.$router
          .replace({
            name: "report",
            params: {
              visitID: this.nextVisit.ID,
              reportID: this.nextVisit.Reports[0].ID,
            },
          })
          .catch((err) => console.log(err));
        return;
      }
      this.onCancel();
    },

    /* saving related methods*/
    onSave() {
      if (this.commentOperationPending) {
        return;
      }
      if (this.selection === "physician") {
        this.savePhysicianComment();
      } else {
        this.saveTechnicianComments();
      }
    },
    savePhysicianComment() {
      this.commentOperationPending = true;
      ApiService.savePhysicianComment(
        this.selectedVisit.ID,
        this.selectedReport.ID,
        this.activePhysicianComment.Text
      )
        .then(() => {
          this.$notify({
            type: "success",
            text: "Review.View.Report.10",
          });
          this.showUpdateReportModalOrNavigate();
        })
        .catch(() => {
          this.$notify({
            type: "danger",
            duration: NOTIFICATION_DURATION_ERROR,
            text: "Review.View.Report.11",
          });
        })
        .finally(() => {
          this.commentOperationPending = false;
        });
    },
    saveTechnicianComments() {
      this.commentOperationPending = true;
      let promises = [];
      this.activeTechnicianComments.forEach((c) => {
        promises.push(
          ApiService.saveTechnicianComment(
            this.selectedVisit.ID,
            this.selectedReport.ID,
            c.LinkID,
            c.Text
          )
        );
      });
      Promise.all(promises)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Review.View.Report.10",
          });
          this.showUpdateReportModalOrNavigate();
        })
        .catch(() => {
          this.$notify({
            type: "danger",
            duration: NOTIFICATION_DURATION_ERROR,
            text: "Review.View.Report.11",
          });
        })
        .finally(() => {
          this.commentOperationPending = false;
        });
    },
    /*
    showUpdateReportModalOrNavigate checks whether the update report modal should be shown or not.
    The modal is shown for:
      - internal reports (Type === REPORT_TYPE_OUTPUT)
      - signed external reports (Type === REPORT_TYPE_EXTERNAL && endsWith: Signed)
      - signed Vmax reports (Type === REPORT_TYPE_OUTPUT && endsWith: Signed)

     (note: unsigned Vmax reports are of type REPORT_TYPE_EXTERNAL)
     */
    showUpdateReportModalOrNavigate() {
      if (
        this.selectedReport.Type === REPORT_TYPE_OUTPUT ||
        (this.selectedReport.Type === REPORT_TYPE_EXTERNAL &&
          this.selectedReport.Filename.endsWith("Signed"))
      ) {
        this.$refs.updateReportModal.show();
      } else {
        this.navigate();
      }
    },
    /* signing related methods */
    onSign() {
      if (this.selection === "physician") {
        this.$refs.physicianSignReportModal.show();
      } else {
        this.$refs.technicianSignReportModal.show();
      }
    },
    onPhysicianSignReportModalHide($event) {
      if ($event.trigger !== "ok") {
        // do nothing
        return;
      }
      this.navigate();
    },
    onTechnicianSignReportModalHide($event) {
      if ($event.trigger !== "ok") {
        // do nothing
        return;
      }
      this.navigate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../_custom.scss";

.comment-tab {
  border-right: 1px solid map-get($theme-colors, "light");
  cursor: pointer;

  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }

  &.active {
    border-bottom: none !important;
    background-color: #ffffff !important;
  }
}

#embedded-viewer {
  display: block;
  border: none;
  padding: 0;
  margin: 0;
}
</style>

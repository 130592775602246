<template>
  <div
    v-if="error"
    class="col-12 alert alert-danger alert-dismissible"
    role="alert"
  >
    {{ message }}
    <button
      id="btn-dismiss-error-message"
      type="button"
      class="close"
      @click="dismiss"
    >
      &times;
    </button>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    error: String,
    dismiss: Function,
    args: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    message() {
      let localized = this.$options.filters.localized(this.error);
      this.args.forEach((arg, i) => {
        localized = localized.replace(`{${i}}`, arg);
      });
      return localized;
    },
  },
};
</script>

<style scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "p-2 d-flex flex-column",
      staticStyle: { "overflow-y": "auto" },
    },
    [
      _c("div", { staticClass: "mb-1 small text-muted" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("localized")("Review.View.Levels.3")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.isFetchingLevels
        ? _c(
            "div",
            {
              staticClass:
                "flex-grow-1 d-flex justify-content-center align-items-center",
            },
            [_c("Spinner", { attrs: { type: "grow" } })],
            1
          )
        : _c(
            "div",
            { staticStyle: { flex: "1 1 0% !important" } },
            _vm._l(_vm.levels, function (lvl, idx) {
              return _c(
                "div",
                { key: idx },
                [
                  _c("div", { staticClass: "mb-1 pl-1 bg-hover" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("localized")(_vm.levelLabels[lvl.Type])) +
                        "\n        "
                    ),
                    _vm.displaySequence(lvl.Type)
                      ? _c("span", [_vm._v(_vm._s(lvl.Sequence))])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(lvl.Measurements, function (meas, idx) {
                    return _c("LevelListItem", {
                      key: idx,
                      attrs: { measurement: meas, levelID: lvl.ID },
                    })
                  }),
                ],
                2
              )
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="col-12">
    <div
      class="row bg-white report-line"
      v-for="report in reports"
      :key="report.ID"
    >
      <ReportListItem
        :visitID="visitID"
        :report="report"
        :onSelect="onSelect"
      />
    </div>
  </div>
</template>

<script>
import ReportListItem from "@/components/views/visits/ReportListItem";

export default {
  name: "Reports",
  components: { ReportListItem },
  props: { reports: Array, visitID: String, onSelect: Function },
};
</script>

<style lang="scss" scoped>
@import "../../../_custom.scss";

.report-line {
  transition: background-color 0.2s linear, border-left 0.2s linear;

  &:not(:last-child) {
    border-bottom: 1px dashed map-get($theme-colors, "light") !important;
  }

  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-check form-check-inline" }, [
    _c("input", {
      staticClass: "form-check-input",
      attrs: { type: "checkbox", id: "checkbox-test-review" },
      domProps: { checked: _vm.checked },
      on: { change: _vm.setTestReviewStatus },
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "checkbox-test-review" },
      },
      [_vm._v("\n    " + _vm._s(_vm._f("localized")(_vm.label)) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
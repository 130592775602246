<template>
  <div>
    <label :for="label" class="small text-muted">
      {{ label | localized }}
    </label>
    <div
      class="w-100 filter-group flex-wrap btn-group btn-group-toggle btn-group-sm"
    >
      <div
        class="pl-1 pr-1 btn btn-outline-primary custom-hover cursor-pointer"
        v-for="(option, oidx) in options"
        :id="`filter-visit-status-${option.id}`"
        :key="oidx"
        :class="{ active: proxiedValues.indexOf(option.id) !== -1 }"
        @click="select(option.id)"
        v-b-tooltip.hover
        :title="option._key | localized"
      >
        <img
          height="20px"
          :src="'static/' + option._src + '.png'"
          :style="{ backgroundColor: option._bg, marginTop: -3 + 'px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterVisitStatus",
  props: {
    label: String,
    initialValue: Array,
  },
  computed: {
    options() {
      const opts = this.$store.getters.activeWorkflowConfigurations;
      // convert to options
      if (opts.length === 0) {
        return [];
      }
      return opts.map((c) => {
        return {
          id: c.VisitStatus,
          _src: c._src,
          _bg: c._bg,
          _key: c._key,
        };
      });
    },
    proxiedValues: {
      get() {
        return this.initialValue;
      },
      set(val) {
        this.$emit("update", val);
      },
    },
  },
  methods: {
    select(id) {
      const idx = this.proxiedValues.indexOf(id);
      if (idx === -1) {
        this.$emit("update", [id].concat(this.proxiedValues));
      } else {
        this.$emit("update", [
          ...this.proxiedValues.slice(0, idx),
          ...this.proxiedValues.slice(idx + 1),
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../_custom.scss";

.filter-group {
  background-color: $input-bg;
}

.custom-hover:hover {
  background-color: inherit;
}
</style>

<template>
  <b-modal
    ref="modal"
    lazy
    id="delete-filter-modal"
    @hide="$emit('hide', $event)"
  >
    <template slot="modal-title">
      <h5 class="modal-title">
        {{ "Review.View.ReportSearch.41" | localized }}
      </h5>
    </template>
    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.DeleteReport.5" | localized }}
      </button>
      <button type="button" class="btn btn-sm btn-primary" @click="handleOK">
        {{ "Review.View.Visit.6" | localized }}
      </button>
    </template>
    <!-- content -->
    <div>{{ "Review.View.ReportSearch.40" | localized }}</div>
  </b-modal>
</template>

<script>
export default {
  name: "FilterModalDelete",
  methods: {
    show() {
      this.$refs.modal.show();
    },
    handleOK() {
      this.$refs.modal.hide("ok");
    },
    handleCancel() {
      this.$refs.modal.hide("cancel");
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="p-2 cursor-move">
    <label :for="label" class="small text-muted cursor-move">
      <span class="fas fa-grip-vertical"></span> {{ label | localized }}
    </label>
    <div class="form-control form-control-sm w-100 placeholder"></div>
  </div>
</template>

<script>
export default {
  name: "FilterPlaceholder",
  props: ["label"],
};
</script>

<style lang="scss">
@import "../../../custom";

.cursor-move {
  cursor: move !important;
}

.placeholder {
  border: 0;
  background-color: $input-bg;
}
</style>

<template>
  <div class="bg-selected pl-1 pr-1 mb-1" v-if="measurementTypeExists">
    {{ measurement.LocalDate | time }}:
    <span
      @click="openMeasurement"
      :class="{ 'cursor-pointer text-primary': isDesktopMode }"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import { openMeasurement } from "@/desktop";
import { MEASUREMENT_TYPES, MEASUREMENT_METHODS } from "@/common/domain";

export default {
  name: "LevelListItem",
  props: {
    levelID: { Type: String, required: true },
    measurement: { Type: Object, required: true },
  },
  computed: {
    isDesktopMode() {
      return this.$store.getters.isDesktopMode;
    },
    measurementTypeExists() {
      return !!MEASUREMENT_TYPES[this.measurement.MeasurementType];
    },
    text() {
      const type = this.$options.filters.localized(
        `MeasurementDescription.${
          MEASUREMENT_TYPES[this.measurement.MeasurementType]
        }`
      );
      // type only
      if (
        !this.measurement.ConductedMethodIds ||
        this.measurement.ConductedMethodIds.length === 0
      ) {
        return type;
      }
      // type + methods
      const methods = this.measurement.ConductedMethodIds.map((method) =>
        this.$options.filters.localized(
          `MeasurementDescription.${MEASUREMENT_METHODS[method]}`
        )
      );
      return `${type}: ${methods.join(",")}`;
    },
  },
  methods: {
    openMeasurement() {
      if (this.isDesktopMode) {
        // get id of measurement type
        const measurementID =
          MEASUREMENT_TYPES[this.measurement.MeasurementType];
        const measurementMethodIds = this.measurement.ConductedMethodIds.map(
          (str) => MEASUREMENT_METHODS[str]
        );
        // we need to pass strings
        openMeasurement(
          JSON.stringify(measurementID),
          this.levelID,
          measurementMethodIds.join(" ")
        );
      }
    },
  },
};
</script>

<style scoped></style>

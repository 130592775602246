<template>
  <div
    class="w-100 h-100 d-flex flex-column"
    :style="{ flex: '1 1 0% !important', minHeight: 0 }"
    @mousemove="onActivity"
    @keydown="onActivity"
  >
    <div
      class="w-100 h-100 d-flex flex-column"
      v-if="isInitialized"
      :style="{ flex: '1 1 0% !important', minHeight: 0 }"
    >
      <slot name="header">
        <Header v-if="!isDesktopMode" :lastActivity="lastActivity" />
      </slot>
      <slot name="content"></slot>
    </div>
    <div
      class="w-100 h-100 d-flex justify-content-center align-items-center"
      style="flex: 1 1 0% !important"
      v-else
    >
      <Spinner type="grow" />
    </div>
    <slot name="footer">
      <div class="bg-white text-muted small border-top p-1 pl-2">
        {{ version }}
      </div>
    </slot>
  </div>
</template>

<script>
import throttle from "lodash/throttle";

import Spinner from "@/components/Spinner";
import Header from "@/components/Header";

export default {
  name: "BaseLayout",
  components: { Header, Spinner },
  data() {
    return {
      lastActivity: 0,
    };
  },
  computed: {
    isInitialized() {
      return this.$store.getters.isInitialized;
    },
    isDesktopMode() {
      return this.$store.getters.isDesktopMode;
    },
    version() {
      return this.$store.getters.version;
    },
  },
  created() {
    this.throttledActivity = throttle(this.updateActivity, 1000);
  },
  methods: {
    updateActivity() {
      this.lastActivity = Date.now();
    },
    onActivity() {
      this.throttledActivity();
    },
  },
};
</script>

<style scoped></style>

var render = function render(_c, _vm) {
  return _c("span", [
    _vm._v(
      _vm._s(_vm.props.lastName) +
        " " +
        _vm._s(_vm.props.firstName) +
        " " +
        _vm._s(_vm.props.middleName)
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/auth.module";
import localization from "@/store/localization.module";
import visits from "@/store/visits.module";
import configuration from "@/store/configuration.module";
import filters from "@/store/filters.module";
import levels from "@/store/levels.module";
import { CLEAR_ALL } from "@/store/actions.type";
import { RESET } from "@/store/mutations.type";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  actions: {
    // Reset all stores to their initial state
    [CLEAR_ALL]({ commit }) {
      commit(RESET);
    },
  },
  modules: {
    auth,
    configuration,
    localization,
    levels,
    visits,
    filters,
  },
});

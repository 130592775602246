var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100 h-100 d-flex flex-column",
      style: { flex: "1 1 0% !important", minHeight: 0 },
      on: { mousemove: _vm.onActivity, keydown: _vm.onActivity },
    },
    [
      _vm.isInitialized
        ? _c(
            "div",
            {
              staticClass: "w-100 h-100 d-flex flex-column",
              style: { flex: "1 1 0% !important", minHeight: 0 },
            },
            [
              _vm._t("header", function () {
                return [
                  !_vm.isDesktopMode
                    ? _c("Header", {
                        attrs: { lastActivity: _vm.lastActivity },
                      })
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm._t("content"),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass:
                "w-100 h-100 d-flex justify-content-center align-items-center",
              staticStyle: { flex: "1 1 0% !important" },
            },
            [_c("Spinner", { attrs: { type: "grow" } })],
            1
          ),
      _vm._v(" "),
      _vm._t("footer", function () {
        return [
          _c(
            "div",
            { staticClass: "bg-white text-muted small border-top p-1 pl-2" },
            [_vm._v("\n      " + _vm._s(_vm.version) + "\n    ")]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
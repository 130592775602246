<template>
  <div class="row pl-2 pr-2">
    <div
      class="col tab ml-1 pl-2 pr-2 bg-light cursor-pointer rounded-top border"
      v-for="(report, idx) in reports"
      :key="idx"
      :class="{
        active: selection.reportID === report.ID,
        'cursor-not-allowed':
          pendingReportNamesBySelectedVisit[report.Filename],
      }"
      :title="report.Filename"
      @click="
        $emit('select-report', {
          reportID: report.ID,
          reportName: report.Filename,
        })
      "
    >
      <Spinner
        type="border"
        small
        v-if="pendingReportNamesBySelectedVisit[report.Filename]"
      />
      <ReportIcon
        v-else
        :type="report.Type"
        :name="report.Filename"
        class="d-inline-block mr-2"
      />
      {{ report.Filename }}
    </div>
    <div
      class="col tab tab-new ml-1 pl-2 pr-2 cursor-pointer border rounded-top d-flex justify-content-center"
      @click="$emit('new-report')"
    >
      <div>
        <i class="fas fa-plus" />
      </div>
    </div>
  </div>
</template>

<script>
import ReportIcon from "@/components/ReportIcon";
import Spinner from "@/components/Spinner";

export default {
  name: "ReportTabs",
  components: { ReportIcon, Spinner },
  props: {
    reports: Array,
  },
  computed: {
    selection() {
      return this.$store.getters.selection;
    },
    pendingReportNamesBySelectedVisit() {
      return this.$store.getters.pendingReportNamesBySelectedVisit;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../_custom.scss";

$tabHeight: 35px;

.tab {
  border-bottom: 0 !important;
  min-height: $tabHeight;
  height: $tabHeight;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: $tabHeight;

  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }

  &.active {
    border: 1px solid map-get($theme-colors, "highlight") !important;
    border-bottom: 0 !important;
    background-color: white !important;
  }

  &-new {
    @extend .tab;
    max-width: 40px;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row pl-2 pr-2" },
    [
      _vm._l(_vm.reports, function (report, idx) {
        return _c(
          "div",
          {
            key: idx,
            staticClass:
              "col tab ml-1 pl-2 pr-2 bg-light cursor-pointer rounded-top border",
            class: {
              active: _vm.selection.reportID === report.ID,
              "cursor-not-allowed":
                _vm.pendingReportNamesBySelectedVisit[report.Filename],
            },
            attrs: { title: report.Filename },
            on: {
              click: function ($event) {
                return _vm.$emit("select-report", {
                  reportID: report.ID,
                  reportName: report.Filename,
                })
              },
            },
          },
          [
            _vm.pendingReportNamesBySelectedVisit[report.Filename]
              ? _c("Spinner", { attrs: { type: "border", small: "" } })
              : _c("ReportIcon", {
                  staticClass: "d-inline-block mr-2",
                  attrs: { type: report.Type, name: report.Filename },
                }),
            _vm._v("\n    " + _vm._s(report.Filename) + "\n  "),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col tab tab-new ml-1 pl-2 pr-2 cursor-pointer border rounded-top d-flex justify-content-center",
          on: {
            click: function ($event) {
              return _vm.$emit("new-report")
            },
          },
        },
        [_vm._m(0)]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "fas fa-plus" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }
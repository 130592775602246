<template>
  <div
    class="bg-white border-bottom pb-2 container-fluid"
    style="border-bottom-width: 2px !important"
  >
    <div v-if="!isDesktopMode" class="row">
      <div class="col-2 offset-3">
        <div class="small text-muted">
          {{ "Review.View.VisitList.3" | localized }}
        </div>
        <div>
          <PatientName
            :firstName="patient.FirstName"
            :middleName="patient.MiddleName"
            :lastName="patient.LastName"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="small text-muted">
          {{ "Review.View.VisitList.8" | localized }}
        </div>
        <div id="patient-id">
          {{ patient.ExternalID }}
        </div>
      </div>
      <div class="col-2">
        <div class="small text-muted">
          {{ "Review.View.VisitList.6" | localized }}
        </div>
        <div id="birthday">
          {{ patient.Birthdate | date }}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <slot name="pagination"></slot>
      </div>
      <div class="col-8 text-right">
        <div v-if="!isSpecialReportMode" class="align-self-end">
          <button class="btn btn-sm btn-outline-secondary" @click="onClose">
            {{ "Review.View.Patient.1" | localized }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STOP_PATIENT_MODE } from "@/store/actions.type";

export default {
  name: "PatientBar",
  props: {
    patient: { type: Object, required: true },
  },
  computed: {
    isDesktopMode() {
      return this.$store.getters.isDesktopMode;
    },
    isSpecialReportMode() {
      return this.$store.getters.isSpecialReportMode;
    },
  },
  methods: {
    onClose() {
      this.$store.dispatch(STOP_PATIENT_MODE);
      this.$router.push({ path: "/" }).catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped></style>

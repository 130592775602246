var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "button",
        staticClass: "button",
        staticStyle: { position: "relative", cursor: "pointer" },
        on: { click: _vm.toggleVisibility },
      },
      [
        _c("VisitStatus", {
          attrs: {
            visitID: _vm.visit.ID,
            freeze: _vm.visit.ReviewStatusFreeze,
            status: _vm.visit.ReviewStatus,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isVisible
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: {
                  exclude: ["button"],
                  handler: "hide",
                },
                expression:
                  "{\n      exclude: ['button'],\n      handler: 'hide',\n    }",
              },
            ],
            ref: "VisitStatusPanel",
            staticClass: "bg-white rounded shadow position-absolute",
            staticStyle: {
              top: "calc(100% + 3px)",
              "z-index": "10",
              right: "10px",
              "min-width": "250px",
              "max-width": "calc(100%)",
            },
          },
          [
            !_vm.visit.ReviewStatusFreeze
              ? [
                  _c(
                    "b-list-group",
                    { staticClass: "no-border" },
                    [
                      _c("b-list-group-item", [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-group-title",
                            staticStyle: { "white-space": "normal" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("localized")(
                                    "Review.View.SetVisitStatus.1"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-muted small info-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.SetVisitStatus.2"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.options, function (option, oidx) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: oidx,
                            staticClass: "dropdown-selectable-item",
                            attrs: {
                              button: "",
                              id: `filter-visit-status-${option.Id}`,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.freezeVisit(option.Id)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("VisitStatus", {
                                  attrs: { status: option.Id },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              : [
                  _c(
                    "b-list-group",
                    { staticClass: "no-border" },
                    [
                      _c("b-list-group-item", [
                        _c("div", { staticClass: "dropdown-group-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.SetVisitStatus.8"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-muted small info-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("localized")(
                                  "Review.View.SetVisitStatus.5"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-list-group-item",
                        {
                          staticClass: "dropdown-selectable-item",
                          attrs: { button: "", id: "unfreeze" },
                          on: { click: _vm.unfreezeVisit },
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "fas fa-unlock" }),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("localized")(
                                    "Review.View.SetVisitStatus.7"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
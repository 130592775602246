<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <BaseLayout>
    <template v-slot:header>
      <!-- do not show the header -->
      <span></span>
    </template>
    <template v-slot:content>
      <iframe
        class="w-100 h-100"
        id="embedded-viewer"
        src="static/web/viewer.html#zoom=auto"
        ref="pdfViewer"
      ></iframe>
    </template>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout";

import ApiService from "@/common/api.service";
import { NOTIFICATION_DURATION_ERROR } from "@/common/constants";
import {
  ErrCodeInvalidParameter,
  ErrCodeReportNotFound,
  ErrCodeVisitNotFound,
  ErrCodeReportInProcess,
} from "@/common/errors";

export default {
  name: "DirectLink",
  components: { BaseLayout },
  props: {
    Create: {
      type: String,
      required: true,
    },
    PatientID: {
      type: String,
      required: true,
    },
    VisitDate: {
      type: String,
      required: true,
    },
    ReportName: {
      type: String,
      required: true,
    },
    Edit: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      visitID: "",
      reportID: "",
    };
  },
  methods: {
    loadReport() {
      if (this.$refs.pdfViewer.contentWindow.PDFViewerApplication) {
        this.$refs.pdfViewer.contentWindow.PDFViewerApplication.close();
      }
      if (this.visitID === "" || this.reportID === "") {
        this.$notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.13",
        });
        return;
      }
      ApiService.fetchReportPDF(this.visitID, this.reportID).then((d) =>
        this.$refs.pdfViewer.contentWindow.PDFViewerApplication.open(d)
      );
    },
  },
  created() {
    const create = this.Create === "true";
    ApiService.directLink(
      create,
      this.PatientID,
      this.VisitDate,
      this.ReportName
    )
      .then((visit) => {
        const report = visit.Reports.find(
          (r) => r.Filename.toLowerCase() === this.ReportName.toLowerCase()
        );
        this.visitID = visit.ID;
        this.reportID = report.ID;
        if (this.Edit !== "true") {
          this.loadReport();
          return;
        }
        this.$router
          .replace({
            name: "report",
            params: {
              visitID: visit.ID,
              reportID: report.ID,
            },
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        let text = "";
        switch (err.response.data.Code) {
          case ErrCodeVisitNotFound:
            text = "Review.View.DirectLink.4";
            break;
          case ErrCodeReportNotFound:
            text = "Review.View.DirectLink.5";
            break;
          case ErrCodeInvalidParameter:
            text = "Review.View.DirectLink.2";
            break;
          case ErrCodeReportInProcess:
            text = "Review.View.Messages.15";
            break;
          default:
            text = "Review.View.Messages.13";
        }
        this.$notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text,
        });
      });
  },
};
</script>

<style scoped></style>

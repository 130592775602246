<template>
  <b-modal lazy ref="modal" @show="onShow" @hide="$emit('hide', $event)">
    <template slot="modal-title">
      <h5 class="modal-title">
        {{ "Review.View.Sign.1" | localized }}
      </h5>
    </template>
    <template slot="modal-footer">
      <button
        id="btn-cancel-sign-modal"
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.Sign.3" | localized }}
      </button>
      <button
        id="btn-confirm-sign-modal"
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="busy"
        @click="handleOK"
      >
        <Spinner type="border" small color="white" v-if="busy" />
        {{ "Review.View.Sign.2" | localized }}
      </button>
    </template>
    <!-- content -->
    <div v-if="showLogin">
      <form @submit.prevent="handleOK">
        <div>
          <ErrorMessage :error="error" :dismiss="hideError" />
          <div class="form-group row">
            <label
              for="input-username-sign-modal"
              class="col-3 col-form-label col-form-label-sm"
              >{{ "Review.View.Login.1" | localized }}</label
            >
            <div class="col-9">
              <input
                id="input-username-sign-modal"
                ref="usernameInput"
                v-model="username"
                type="text"
                class="form-control form-control-sm"
                autocomplete="username"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="input-password-sign-modal"
              class="col-3 col-form-label col-form-label-sm"
              >{{ "Review.View.Login.3" | localized }}</label
            >
            <div class="col-9">
              <input
                id="input-password-sign-modal"
                v-model="password"
                type="password"
                class="form-control form-control-sm"
                autocomplete="current-password"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-else>
      <div>{{ "Review.View.Sign.9" | localized }} {{ username }}.</div>
    </div>
    <div>{{ "Review.View.Sign.8" | localized }}.</div>
    <div>{{ "Review.View.Sign.7" | localized }}</div>
    <ReportStatusSelection
      @update="status = $event"
      :initial-status="status"
      :available-states="availableStates"
    />
  </b-modal>
</template>

<script>
import ApiService from "@/common/api.service";
import { NOTIFICATION_DURATION_ERROR } from "@/common/constants";
import { SIGN_REPORT_PHYSICIAN } from "@/store/actions.type";

import ErrorMessage from "@/components/ErrorMessage";
import ReportStatusSelection from "@/components/ReportStatusSelection";
import Spinner from "@/components/Spinner";

export default {
  name: "ReportModalSignPhysician",
  props: {
    comment: { Type: String, required: true },
  },
  components: {
    Spinner,
    ReportStatusSelection,
    ErrorMessage,
  },
  data() {
    return {
      busy: false,
      showLogin:
        !this.$store.getters.hasOneTimeSignOnRight || this.isDefaultUser,
      status: this.$store.getters.nextReportStatus,
      username: "",
      password: "",
      error: "",
    };
  },
  computed: {
    isDefaultUser() {
      return this.$store.getters.isDefaultUser;
    },
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    selectedReport() {
      return this.$store.getters.selectedReport;
    },
    availableStates() {
      return this.$store.getters.availableReportStates;
    },
    workstationID() {
      return this.$store.getters.workstationID;
    },
  },
  methods: {
    hideError() {
      this.error = "";
    },
    show() {
      this.$refs.modal.show();
    },
    handleOK() {
      this.busy = true;
      // we fake the login promise if we don't need to login again. It's important to use undefined
      // instead of null. To make the default parameters work in the store/api
      let loginPromise = Promise.resolve({ Token: undefined });
      if (this.showLogin) {
        loginPromise = ApiService.login(
          this.username,
          this.password,
          "",
          this.workstationID
        );
      }
      loginPromise
        .then((data) => {
          const reportName = this.selectedReport.Filename;
          ApiService.savePhysicianComment(
            this.selectedVisit.ID,
            this.selectedReport.ID,
            this.comment
          )
            .then(() => {
              this.$store.dispatch(SIGN_REPORT_PHYSICIAN, {
                visitID: this.selectedVisit.ID,
                reportID: this.selectedReport.ID,
                newState: this.status,
                token: data.Token,
                reportName,
              });
              this.busy = false;
              this.$refs.modal.hide("ok");
            })
            .catch(() => {
              this.busy = false;
              this.$notify({
                type: "danger",
                duration: NOTIFICATION_DURATION_ERROR,
                title: reportName,
                text: "Review.View.Report.11",
              });
            });
        })
        .catch(() => {
          this.busy = false;
          this.password = "";
          this.error = "Review.View.Login.5";
        });
    },
    handleCancel() {
      // use default "events"
      this.$refs.modal.hide("cancel");
    },
    onShow() {
      this.username = "";
      if (!this.isDefaultUser) {
        this.username = this.$store.getters.username;
      }
      this.status = this.$store.getters.nextReportStatus;
      this.password = "";
      this.hideError();
    },
  },
};
</script>

<style scoped></style>

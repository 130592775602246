var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c(
        "div",
        {
          staticClass: "col-12 alert alert-danger alert-dismissible",
          attrs: { role: "alert" },
        },
        [
          _vm._v("\n  " + _vm._s(_vm.message) + "\n  "),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { id: "btn-dismiss-error-message", type: "button" },
              on: { click: _vm.dismiss },
            },
            [_vm._v("\n    ×\n  ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
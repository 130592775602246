<template>
  <div>
    <div
      class="button"
      style="position: relative; cursor: pointer"
      @click="toggleVisibility"
      ref="button"
    >
      <VisitStatus
        :visitID="visit.ID"
        :freeze="visit.ReviewStatusFreeze"
        :status="visit.ReviewStatus"
      />
    </div>
    <div
      ref="VisitStatusPanel"
      class="bg-white rounded shadow position-absolute"
      style="
        top: calc(100% + 3px);
        z-index: 10;
        right: 10px;
        min-width: 250px;
        max-width: calc(100%);
      "
      v-if="isVisible"
      v-click-outside="{
        exclude: ['button'],
        handler: 'hide',
      }"
    >
      <template v-if="!visit.ReviewStatusFreeze">
        <b-list-group class="no-border">
          <b-list-group-item>
            <div class="dropdown-group-title" style="white-space: normal">
              {{ "Review.View.SetVisitStatus.1" | localized }}
            </div>
            <p class="text-muted small info-text">
              {{ "Review.View.SetVisitStatus.2" | localized }}
            </p>
          </b-list-group-item>
          <b-list-group-item
            button
            v-for="(option, oidx) in options"
            :id="`filter-visit-status-${option.Id}`"
            :key="oidx"
            class="dropdown-selectable-item"
            @click="freezeVisit(option.Id)"
          >
            <div>
              <VisitStatus :status="option.Id" />
            </div>
          </b-list-group-item>
        </b-list-group>
      </template>
      <template v-else>
        <b-list-group class="no-border">
          <b-list-group-item>
            <div class="dropdown-group-title">
              {{ "Review.View.SetVisitStatus.8" | localized }}
            </div>
            <p class="text-muted small info-text">
              {{ "Review.View.SetVisitStatus.5" | localized }}
            </p>
          </b-list-group-item>
          <b-list-group-item
            button
            id="unfreeze"
            class="dropdown-selectable-item"
            @click="unfreezeVisit"
          >
            <div>
              <span class="fas fa-unlock"></span>
              {{ "Review.View.SetVisitStatus.7" | localized }}
            </div>
          </b-list-group-item>
        </b-list-group>
      </template>
    </div>
  </div>
</template>

<script>
import VisitStatus from "@/components/VisitStatus";
import { FREEZE_VISIT, UNFREEZE_VISIT } from "@/store/actions.type";

export default {
  name: "VisitStatusFreeze",
  components: { VisitStatus },
  props: {
    visit: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      menuVisible: false,
    };
  },
  computed: {
    isVisible() {
      return this.menuVisible;
    },
    options() {
      const opts = this.$store.getters.activeWorkflowConfigurations;
      // convert to options
      if (opts.length === 0) {
        return [];
      }
      return opts.map((c) => {
        return { Id: c.VisitStatus };
      });
    },
  },
  methods: {
    hide() {
      this.menuVisible = false;
    },
    toggleVisibility() {
      this.menuVisible = !this.menuVisible;
    },
    unfreezeVisit() {
      this.$store.dispatch(UNFREEZE_VISIT, {
        visitID: this.visit.ID,
      });
    },
    freezeVisit(visitStatus) {
      this.$store.dispatch(FREEZE_VISIT, {
        visitID: this.visit.ID,
        visitStatus: visitStatus,
      });
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          const { handler, exclude } = binding.value;
          let clickedOnExcludedEl = false;
          exclude.forEach((refName) => {
            if (!clickedOnExcludedEl) {
              // Get the element using the reference name
              const excludedEl = vnode.context.$refs[refName];
              // See if this excluded element is the same element the user just clicked on
              clickedOnExcludedEl = excludedEl.contains(event.target);
            }
          });
          // We check to see if the clicked element is not the dialog element and not excluded
          if (!el.contains(event.target) && !clickedOnExcludedEl) {
            vnode.context[handler]();
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.no-border {
  border: 0 none;
}
.dropdown-selectable-item {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>

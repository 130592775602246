// global
export const CLEAR_ALL = "clearAll";

// auth related
export const AUTHENTICATE = "authenticate";
export const LOGOUT = "logout";
export const LOGOUT_WITHOUT_REQUEST = "logoutWithoutRequest";

// localization
export const CHANGE_LOCALE = "changeLocale";
export const FETCH_TEXTS = "fetchTexts";

// configuration
export const FETCH_VERSION = "fetchVersion";
export const INIT_COMPLETE = "initComplete";
export const FETCH_CONFIGURATION = "fetchConfiguration";
export const UPDATE_SETTINGS = "updateSettings";
export const UPDATE_CURRENT_FILTER = "updateFilter";
export const CLEAR_CURRENT_FILTER = "clearFilter";
export const START_BATCH_MODE = "startBatchMode";
export const STOP_BATCH_MODE = "stopBatchMode";
export const START_PATIENT_MODE = "startPatientMode";
export const STOP_PATIENT_MODE = "stopPatientMode";
export const START_DESKTOP_MODE = "startDesktopMode";
export const UPDATE_LAST_AUTO_INTERPRETATION = "updateLastAutoInterpretation";
export const START_SPECIAL_REPORT_MODE = "startSpecialReportMode";
export const ENABLE_SWITCH_TO_REPORT = "enableSwitchToReport";
export const DISABLE_SWITCH_TO_REPORT = "disableSwitchToReport";

// visits
export const FETCH_VISITS = "fetchVisits";
export const FETCH_VISIT = "fetchVisit";
export const FETCH_LEVELS = "fetchLevels";
export const SORT_BY = "sortBy";
export const SORT_ORDER = "sortOrder";
export const STORE_SEARCH = "storeSearch";
export const SELECT_PAGE = "selectPage";
export const SELECT_VISIT = "selectVisit";
export const FREEZE_VISIT = "freezeVisit";
export const UNFREEZE_VISIT = "unfreezeVisit";
export const FETCH_VISIT_META_DATA = "fetchVisitMetaData";

// reports
export const UPDATE_REPORT = "updateReport";
export const UPLOAD_REPORT = "uploadReport";
export const NEW_REPORT = "newReport";
export const DELETE_REPORT = "deleteReport";
export const SIGN_REPORT_PHYSICIAN = "signReportAsPhysician";
export const SIGN_REPORT_TECHNICIAN = "signReportAsTechnician";
export const SEND_REPORT = "sendReport";

// ArtiQ
export const INSERT_ARTIQ = "insertArtiQ";

// filter related
export const FETCH_SAVED_FILTERS = "fetchSavedFilters";
export const SAVE_FILTER = "saveFilter";
export const DELETE_FILTER = "deleteFilter";

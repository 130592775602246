var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      { staticClass: "small text-muted", attrs: { for: _vm.label } },
      [_vm._v("\n    " + _vm._s(_vm._f("localized")(_vm.label)) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.proxiedValue,
            expression: "proxiedValue",
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: { id: _vm.label },
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.proxiedValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        _c("option", { attrs: { value: "--" } }),
        _vm._v(" "),
        _vm._l(_vm.options, function (option, oidx) {
          return _c("option", { key: oidx, domProps: { value: option.ID } }, [
            _vm._v("\n      " + _vm._s(option.Name) + "\n    "),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
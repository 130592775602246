var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid comment-history" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          class: {
            "col-6": _vm.isVmaxVisit,
            "col-12": !_vm.isVmaxVisit,
          },
        },
        [
          _vm.showOpenTechnicianComments
            ? [
                _c("div", { staticClass: "pt-2 small text-muted" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("localized")("Review.View.Report.16")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.activeTechnicianComments, function (c, idx) {
                  return _c(
                    "div",
                    {
                      key: c.ID,
                      staticClass: "pl-2 pr-2 small",
                      class: { "mt-2": idx > 0 },
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "p-2 text-right" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("localized")(
                                    _vm.levelLabels[c.levelType]
                                  )
                                ) +
                                "\n                " +
                                _vm._s(c.levelSequence) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm._f("date")(c.LocalDate)) +
                              "\n              "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(c.Author) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-2" }, [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(c.Text) },
                          }),
                        ]),
                      ]),
                    ]
                  )
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-2 mb-4" },
            [
              _c("div", { staticClass: "small text-muted" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("localized")("Review.View.Report.17")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.signedComments, function (comment, idx) {
                return _c(
                  "div",
                  {
                    key: idx,
                    staticClass: "pl-2 pr-2 small",
                    class: { "mt-2": idx > 0 },
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "p-2 text-right" }, [
                        comment.Type === "Physician"
                          ? _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("localized")("Review.View.Report.14")
                                  ) +
                                  "\n              "
                              ),
                            ])
                          : comment.Type === "Technician"
                          ? _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("localized")("Review.View.Report.15")
                                  ) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("date")(comment.LocalDate))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              " +
                            _vm._s(comment.Author) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "p-2" }, [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(comment.Text) },
                        }),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isVmaxVisit
        ? _c("div", { staticClass: "col-6 small" }, [
            _vm.hasVmaxComments
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "pt-2 text-muted" }, [
                      _c("img", {
                        attrs: {
                          src: "static/vmax-report.png",
                          alt: "Vmax report",
                          height: "20px",
                        },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("localized")("Review.View.Report.26")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.vmaxTechComments, function (c) {
                      return _c("div", { key: c.ID }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-3 p-2 text-right" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm._f("localized")(c.Label)) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("date")(c.LocalDate)) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-9 p-2" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(c.Text) },
                            }),
                          ]),
                        ]),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-muted" }, [
                      _c("img", {
                        attrs: {
                          src: "static/vmax-report.png",
                          alt: "Vmax report",
                          height: "20px",
                        },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("localized")("Review.View.Report.27")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.vmaxLogicTree, function (c) {
                      return _c("div", { key: c.ID }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-3 p-2 text-right" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("date")(c.LocalDate)) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-9 p-2" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(c.Text) },
                            }),
                          ]),
                        ]),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-muted" }, [
                      _c("img", {
                        attrs: {
                          src: "static/vmax-report.png",
                          alt: "Vmax report",
                          height: "20px",
                        },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("localized")("Review.View.Report.28")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.vmaxInterpretations, function (c) {
                      return _c("div", { key: c.ID }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-3 p-2 text-right" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("date")(c.LocalDate)) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-9 p-2" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(c.Text) },
                            }),
                          ]),
                        ]),
                      ])
                    }),
                  ],
                  2
                )
              : _c("div", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("localized")("Review.View.Report.39"))
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pt-2 text-muted" }, [
      _c("img", {
        attrs: {
          src: "static/vmax-report.png",
          alt: "Vmax report",
          height: "20px",
        },
      }),
      _vm._v("\n          Vmax\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.query,
          expression: "query",
        },
      ],
      staticClass: "form-control form-control-sm",
      attrs: { type: "text" },
      domProps: { value: _vm.query },
      on: {
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onEnter.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            return _vm.onArrowDown.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            )
              return null
            return _vm.onArrowUp.apply(null, arguments)
          },
        ],
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.query = $event.target.value
          },
          _vm.onChange,
        ],
        focus: function ($event) {
          _vm.isOpen = true
        },
      },
    }),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "w-100 dropdown-menu scrollable",
        class: { show: _vm.isOpen },
      },
      [
        _vm._l(_vm.results, function (result, i) {
          return _c(
            "li",
            {
              key: i,
              staticClass: "dropdown-item word-break",
              class: { "bg-active": i === _vm.arrowCounter },
              on: {
                click: function ($event) {
                  return _vm.setResult(result.Name)
                },
              },
            },
            [
              _c("span", {
                staticClass: "w-100",
                domProps: { innerHTML: _vm._s(_vm.highlight(result.Name)) },
              }),
            ]
          )
        }),
        _vm._v(" "),
        _vm.results.length === 0
          ? _c(
              "li",
              {
                staticClass: "dropdown-item disabled",
                staticStyle: { cursor: "not-allowed" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.ReportSearch.46")) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c(
      "div",
      {
        staticClass: "mr-2",
        staticStyle: { border: "#333333" },
        attrs: { id: "batch-count" },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.currentVisitIndex + 1) +
            "/" +
            _vm._s(_vm.visits.length) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "btn-group btn-group-sm" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary",
          attrs: {
            id: "btn-previous-batch",
            disabled: !_vm.prevVisit || _vm.disabled,
          },
          on: { click: _vm.onPrevious },
        },
        [_c("i", { staticClass: "fas fa-arrow-left" })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary",
          attrs: {
            id: "btn-next-batch",
            disabled: !_vm.nextVisit || _vm.disabled,
          },
          on: { click: _vm.onNext },
        },
        [_c("i", { staticClass: "fas fa-arrow-right" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-white border-bottom pb-2 container-fluid",
      staticStyle: { "border-bottom-width": "2px !important" },
    },
    [
      !_vm.isDesktopMode
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-2 offset-3" }, [
              _c("div", { staticClass: "small text-muted" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.3")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("PatientName", {
                    attrs: {
                      firstName: _vm.patient.FirstName,
                      middleName: _vm.patient.MiddleName,
                      lastName: _vm.patient.LastName,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [
              _c("div", { staticClass: "small text-muted" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.8")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { id: "patient-id" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.patient.ExternalID) + "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [
              _c("div", { staticClass: "small text-muted" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.6")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { id: "birthday" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("date")(_vm.patient.Birthdate)) +
                    "\n      "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-4" }, [_vm._t("pagination")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "col-8 text-right" }, [
          !_vm.isSpecialReportMode
            ? _c("div", { staticClass: "align-self-end" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary",
                    on: { click: _vm.onClose },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("localized")("Review.View.Patient.1")) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
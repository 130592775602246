<template>
  <div
    class="col-12"
    @click="select"
    :class="{
      selected: selection.reportID === report.ID,
      'disabled text-muted': isReportOperationPending,
    }"
  >
    <div class="row pt-1 pb-1">
      <div class="col-1 d-flex justify-content-end align-items-center">
        <Spinner type="border" small v-if="isReportOperationPending" />
        <ReportIcon
          v-else
          @click.native="showReport(report.ID)"
          :style="{
            cursor: isReportOperationPending ? 'not-allowed' : 'pointer',
          }"
          :name="report.Filename"
          :type="report.Type"
        />
      </div>
      <div class="col-4 d-flex align-items-center">
        <span
          @click="showReport(report.ID)"
          :style="{
            cursor: isReportOperationPending ? 'not-allowed' : 'pointer',
          }"
        >
          {{ report.Filename }}
        </span>
      </div>
      <div class="col-3 d-flex align-items-center">
        {{ report.LocalDate | datetime }}
      </div>
      <div class="col-4 d-flex align-items-center">
        <ReportStatus :status="report.Status" :reportType="report.Type" />
      </div>
    </div>
  </div>
</template>

<script>
import ReportIcon from "@/components/ReportIcon";
import ReportStatus from "@/components/ReportStatus";
import Spinner from "@/components/Spinner";

export default {
  name: "ReportListItem",
  props: {
    visitID: String,
    report: Object,
    onSelect: Function,
  },
  components: {
    Spinner,
    ReportStatus,
    ReportIcon,
  },
  computed: {
    isReportOperationPending() {
      return this.$store.getters.isReportOperationPending(this.report.ID);
    },
    selection() {
      return this.$store.getters.selection;
    },
  },
  methods: {
    showReport(reportID) {
      if (this.isReportOperationPending) {
        return false;
      }
      this.select();
      this.$router
        .push({
          name: "report",
          params: { visitID: this.visitID, reportID },
        })
        .catch((err) => console.log(err));
    },
    select() {
      // do not perform any action if we select the same report again
      if (this.selection.reportID === this.report.ID) {
        return;
      }
      this.onSelect(this.report.ID);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../_custom.scss";

.selected {
  background-color: map-get($theme-colors, "active");
}
</style>

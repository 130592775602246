// global mutation used by all stores
export const RESET = "reset";

// auth related
export const SET_TOKEN = "setToken";

// configuration related
export const SET_VERSION = "setVersion";
export const FETCH_CONFIGURATION_PENDING = "fetchConfiguration";
export const FETCH_CONFIGURATION_SUCCESS = "fetchConfigurationSuccess";
export const FETCH_CONFIGURATION_ERROR = "fetchConfigurationError";
export const INIT_DONE = "initDone";
export const SET_SETTINGS = "setSettings";
export const SET_TOKEN_LIFE_TIME = "setTokenLifeTime";
export const SET_WORKFLOW_CONFIGURATIONS = "setWorkflowConfigurations";
export const SET_FILTER_VALUES = "setFilterValues";
export const SET_REPORT_TEMPLATES = "setReportTemplates";
export const SET_CUSTOM_REPORT_LISTS = "setCustomReportLists";
export const ACTIVATE_BATCH_MODE = "activateBatchMode";
export const DEACTIVATE_BATCH_MODE = "deactivateBatchMode";
export const ACTIVATE_PATIENT_MODE = "activatePatientMode";
export const DEACTIVATE_PATIENT_MODE = "deactivatePatientMode";
export const ACTIVATE_DESKTOP_MODE = "activateDesktopMode";
export const SET_WRAPPER_INFO = "setWrapperInfo";
export const SET_LAST_AUTO_INTERPRETATION = "setLastAutoInterpretation";
export const SET_FILTER_CONFIGURATION = "setFilterConfiguration";
export const ACTIVATE_SPECIAL_REPORT_MODE = "activateSpecialReportMode";
export const SET_SWITCH_TO_REPORT = "setSwitchToReport";

// localization related
export const FETCH_TEXTS_PENDING = "fetchTexts";
export const FETCH_TEXTS_SUCCESS = "fetchTextsSuccess";
export const FETCH_TEXTS_ERROR = "fetchTextsError";
export const SET_LOCALE = "setLocale";

// visit related
export const FETCH_VISITS_PENDING = "fetchVisits";
export const FETCH_VISITS_SUCCESS = "fetchVisitsSuccess";
export const FETCH_VISITS_ERROR = "fetchVisitsError";
export const FETCH_LEVELS_PENDING = "fetchLevels";
export const FETCH_LEVELS_SUCCESS = "fetchLevelsSuccess";
export const FETCH_LEVELS_ERROR = "fetchLevelsError";
export const UPDATE_VISIT = "updateVisit";
export const CLEAR_VISITS = "clearVisits";
export const SET_SORT_BY = "setSortBy";
export const SET_SORT_ORDER = "setSortOrder";
export const SET_SELECTION = "setSelection";
export const SET_VISIT_STATUS = "setVisitStatus";
export const VISIT_OPERATION_PENDING = "visitOperationPending";
export const VISIT_OPERATION_DONE = "visitOperationDone";
export const REPORT_OPERATION_PENDING = "reportOperationPending";
export const REPORT_OPERATION_DONE = "reportOperationDone";
export const APPEND_PLACEHOLDER_REPORT = "appendPlaceholderReport";
export const REMOVE_REPORT = "removeReport";
export const FETCH_VISIT_META_DATA_SUCCESS = "fetchVisitMetaDataSuccess";

// filters related
export const FETCH_SAVED_FILTERS_PENDING = "fetchSavedFiltersPending";
export const FETCH_SAVED_FILTERS_SUCCESS = "fetchSavedFiltersSuccess";
export const FETCH_SAVED_FILTERS_ERROR = "fetchSavedFiltersError";
export const SET_SEARCH_FILTER = "setSearchFilter";
export const SET_CURRENT_FILTER = "setCurrentFilter";
export const SAVE_FILTER_SUCCESS = "saveFilterSuccess";
export const SAVE_FILTER_ERROR = "saveFilterError";
export const DELETE_FILTER_SUCCESS = "deleteSavedFilterSuccess";
export const DELETE_FILTER_ERROR = "deleteSavedFilterError";

// pagination related
export const SET_ITEMS_PER_PAGE = "setItemsPerPage";
export const SET_CURRENT_PAGE = "setCurrentPage";

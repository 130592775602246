var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.range, function (n, idx) {
        return _c("span", { key: idx }, [
          n !== "..."
            ? _c(
                "button",
                {
                  staticClass: "mr-1 btn btn-sm btn-outline-primary",
                  class: { "active btn-primary": _vm.currentPage === n },
                  attrs: { id: `btn-page-${n}` },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.select(n, $event)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(n) + "\n    ")]
              )
            : _c("span", { staticClass: "mr-1" }, [_vm._v("...")]),
        ])
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "small text-muted", attrs: { id: "visit-count" } },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.visitsCount) +
              " " +
              _vm._s(_vm._f("localized")("Review.View.Visit.11")) +
              "\n  "
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
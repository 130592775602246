<template>
  <b-dropdown id="dropdown-sort" size="sm" no-caret variant="outline-secondary">
    <template slot="button-content">
      <template v-if="sortBy === 'LocalDate'">
        {{ "Review.View.VisitList.1" | localized }}
      </template>
      <template v-if="sortBy === 'LastName'">
        {{ "Review.View.VisitList.3" | localized }}
      </template>
      <template v-if="sortBy === 'Birthdate'">
        {{ "Review.View.VisitList.6" | localized }}
      </template>
      <template v-if="sortBy === 'ExternalID'">
        {{ "Review.View.VisitList.8" | localized }}
      </template>
      <template v-if="sortBy === 'HISOrderID'">
        {{ "Review.View.VisitList.10" | localized }}
      </template>
      <template v-if="sortBy === 'HISVisitID'">
        {{ "Review.View.VisitList.9" | localized }}
      </template>
      <template v-if="sortBy === 'ReviewStatus'">
        {{ "Review.View.VisitList.11" | localized }}
      </template>
      <i class="fas fa-sort-amount-up" v-if="sortAscending"></i>
      <i class="fas fa-sort-amount-down" v-else></i>
    </template>
    <b-dropdown-header class="dropdown-group-title">
      {{ "Review.View.ReportSearch.51" | localized }}
    </b-dropdown-header>
    <b-dropdown-item
      id="btn-sort-ascending"
      class="dropdown-selectable-item"
      @click.native="setSortOrder('Ascending')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="sortAscending" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.ReportSearch.49" | localized }}</div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item-btn
      id="btn-sort-descending"
      class="dropdown-selectable-item"
      @click.native="setSortOrder('Descending')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="!sortAscending" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.ReportSearch.50" | localized }}</div>
      </div>
    </b-dropdown-item-btn>
    <b-dropdown-divider />
    <b-dropdown-header class="dropdown-group-title">
      {{ "Review.View.ReportSearch.48" | localized }}
    </b-dropdown-header>
    <b-dropdown-item
      id="btn-sort-date"
      class="dropdown-selectable-item"
      @click.native="setSortBy('LocalDate')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="sortBy === 'LocalDate'" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.VisitList.1" | localized }}</div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      id="btn-sort-name"
      class="dropdown-selectable-item"
      @click.native="setSortBy('LastName')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="sortBy === 'LastName'" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.VisitList.3" | localized }}</div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      id="btn-sort-external-id"
      class="dropdown-selectable-item"
      @click.native="setSortBy('ExternalID')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="sortBy === 'ExternalID'" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.VisitList.8" | localized }}</div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      id="btn-sort-birthdate"
      class="dropdown-selectable-item"
      @click.native="setSortBy('Birthdate')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="sortBy === 'Birthdate'" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.VisitList.6" | localized }}</div>
      </div>
    </b-dropdown-item>
    <template v-if="isHISAvailable">
      <b-dropdown-item
        id="btn-sort-his-order-id"
        class="dropdown-selectable-item"
        @click.native="setSortBy('HISOrderID')"
      >
        <div class="d-flex dropdown-item-content">
          <div class="d-flex dropdown-item-check-wrapper">
            <i v-if="sortBy === 'HISOrderID'" class="fas fa-check"></i>
          </div>
          <div>{{ "Review.View.VisitList.10" | localized }}</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        id="btn-sort-his-visit-id"
        class="dropdown-selectable-item"
        @click.native="setSortBy('HISVisitID')"
      >
        <div class="d-flex dropdown-item-content">
          <div class="d-flex dropdown-item-check-wrapper">
            <i v-if="sortBy === 'HISVisitID'" class="fas fa-check"></i>
          </div>
          <div>{{ "Review.View.VisitList.9" | localized }}</div>
        </div>
      </b-dropdown-item>
    </template>
    <b-dropdown-item
      id="btn-sort-review-status"
      class="dropdown-selectable-item"
      @click.native="setSortBy('ReviewStatus')"
    >
      <div class="d-flex dropdown-item-content">
        <div class="d-flex dropdown-item-check-wrapper">
          <i v-if="sortBy === 'ReviewStatus'" class="fas fa-check"></i>
        </div>
        <div>{{ "Review.View.VisitList.11" | localized }}</div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { SORT_BY, SORT_ORDER } from "@/store/actions.type";

export default {
  name: "Sort",
  computed: {
    sortBy() {
      return this.$store.getters.sortBy;
    },
    sortAscending() {
      return this.$store.getters.sortOrder === "Ascending";
    },
    isHISAvailable() {
      return this.$store.getters.isHISAvailable;
    },
  },
  methods: {
    setSortBy(value) {
      this.$store.dispatch(SORT_BY, { field: value });
    },
    setSortOrder(value) {
      this.$store.dispatch(SORT_ORDER, { order: value });
    },
  },
};
</script>

<style scoped>
.dropdown-item-check-wrapper {
  min-width: 32px;
  justify-content: center;
  align-items: center;
}
.dropdown-selectable-item {
  padding: 0;
}
.dropdown-item-content {
  padding: 4px 8px 4px 0px;
}
.dropdown-group-title {
  padding: 4px 16px 4px 32px;
}
</style>

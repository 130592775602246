<template>
  <div class="p-2 d-flex flex-column" style="overflow-y: auto">
    <div class="mb-1 small text-muted">
      {{ "Review.View.Levels.3" | localized }}
    </div>
    <div
      v-if="isFetchingLevels"
      class="flex-grow-1 d-flex justify-content-center align-items-center"
    >
      <Spinner type="grow" />
    </div>
    <div style="flex: 1 1 0% !important" v-else>
      <div v-for="(lvl, idx) in levels" :key="idx">
        <div class="mb-1 pl-1 bg-hover">
          {{ levelLabels[lvl.Type] | localized }}
          <span v-if="displaySequence(lvl.Type)">{{ lvl.Sequence }}</span>
        </div>
        <LevelListItem
          :measurement="meas"
          :levelID="lvl.ID"
          v-for="(meas, idx) in lvl.Measurements"
          :key="idx"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { LEVEL_LANG_MAPPING } from "@/common/constants";
import { displayLevelSequence } from "@/common/utils";

import Spinner from "@/components/Spinner";
import LevelListItem from "@/components/views/visits/LevelListItem";

export default {
  name: "LevelList",
  components: { LevelListItem, Spinner },
  computed: {
    levelLabels() {
      return LEVEL_LANG_MAPPING;
    },
    isFetchingLevels() {
      return this.$store.getters.isFetchingLevels;
    },
    levels() {
      return this.$store.getters.levels;
    },
  },
  methods: {
    displaySequence(type) {
      return displayLevelSequence(this.levels, type);
    },
  },
};
</script>

<style scoped></style>

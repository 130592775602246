<template>
  <div class="container-fluid comment-history">
    <div class="row">
      <div
        :class="{
          'col-6': isVmaxVisit,
          'col-12': !isVmaxVisit,
        }"
      >
        <template v-if="showOpenTechnicianComments">
          <div class="pt-2 small text-muted">
            {{ "Review.View.Report.16" | localized }}
          </div>
          <div
            class="pl-2 pr-2 small"
            v-for="(c, idx) in activeTechnicianComments"
            :class="{ 'mt-2': idx > 0 }"
            :key="c.ID"
          >
            <div class="d-flex">
              <div class="p-2 text-right">
                <span class="font-weight-bold">
                  {{ levelLabels[c.levelType] | localized }}
                  {{ c.levelSequence }}
                </span>
                <br />
                {{ c.LocalDate | date }}
                <br />
                {{ c.Author }}
              </div>
              <div class="p-2">
                <div v-html="c.Text"></div>
              </div>
            </div>
          </div>
        </template>
        <div class="pt-2 mb-4">
          <div class="small text-muted">
            {{ "Review.View.Report.17" | localized }}
          </div>
          <div
            class="pl-2 pr-2 small"
            v-for="(comment, idx) in signedComments"
            :class="{ 'mt-2': idx > 0 }"
            :key="idx"
          >
            <div class="d-flex">
              <div class="p-2 text-right">
                <span
                  class="font-weight-bold"
                  v-if="comment.Type === 'Physician'"
                >
                  {{ "Review.View.Report.14" | localized }}
                </span>
                <span
                  class="font-weight-bold"
                  v-else-if="comment.Type === 'Technician'"
                >
                  {{ "Review.View.Report.15" | localized }}
                </span>
                <br />
                {{ comment.LocalDate | date }}<br />
                {{ comment.Author }}
              </div>
              <div class="p-2">
                <div v-html="comment.Text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 small" v-if="isVmaxVisit">
        <div v-if="hasVmaxComments">
          <div class="pt-2 text-muted">
            <img src="static/vmax-report.png" alt="Vmax report" height="20px" />
            {{ "Review.View.Report.26" | localized }}
          </div>
          <div v-for="c in vmaxTechComments" :key="c.ID">
            <div class="row">
              <div class="col-3 p-2 text-right">
                <span class="font-weight-bold">
                  {{ c.Label | localized }}
                </span>
                <br />
                {{ c.LocalDate | date }}
              </div>
              <div class="col-9 p-2">
                <div v-html="c.Text"></div>
              </div>
            </div>
          </div>
          <div class="text-muted">
            <img src="static/vmax-report.png" alt="Vmax report" height="20px" />
            {{ "Review.View.Report.27" | localized }}
          </div>
          <div v-for="c in vmaxLogicTree" :key="c.ID">
            <div class="row">
              <div class="col-3 p-2 text-right">
                {{ c.LocalDate | date }}
              </div>
              <div class="col-9 p-2">
                <div v-html="c.Text"></div>
              </div>
            </div>
          </div>
          <div class="text-muted">
            <img src="static/vmax-report.png" alt="Vmax report" height="20px" />
            {{ "Review.View.Report.28" | localized }}
          </div>
          <div v-for="c in vmaxInterpretations" :key="c.ID">
            <div class="row">
              <div class="col-3 p-2 text-right">
                {{ c.LocalDate | date }}
              </div>
              <div class="col-9 p-2">
                <div v-html="c.Text"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-2 text-muted">
            <img src="static/vmax-report.png" alt="Vmax report" height="20px" />
            Vmax
          </div>
          <div>{{ "Review.View.Report.39" | localized }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";

import { COMMENT_STATUS_OPEN, LEVEL_LANG_MAPPING } from "@/common/constants";

export default {
  name: "CommentHistory",
  props: {
    activeTechnicianComments: { type: Array, required: true },
    comments: { type: Array, required: true },
    showOpenTechnicianComments: { type: Boolean, required: true },
  },
  computed: {
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    levelLabels() {
      return LEVEL_LANG_MAPPING;
    },
    signedComments() {
      return this.comments
        .filter((c) => c.Status !== COMMENT_STATUS_OPEN)
        .sort((a, b) => b.LocalDate.localeCompare(a.LocalDate));
    },
    hasVmaxComments() {
      const vmax = [
        ...this.vmaxTechComments,
        ...this.vmaxLogicTree,
        ...this.vmaxInterpretations,
      ];
      return !every(vmax, (v) => isEmpty(v.Text));
    },
    vmaxTechComments() {
      return this.comments
        .filter(
          (c) =>
            c.Type === "VmaxTechComment" ||
            c.Type === "VmaxExTestNotes" ||
            c.Type === "VmaxExEndNotes" ||
            c.Type === "VmaxExTechNotes"
        )
        .map((c) => {
          // add label to each comment
          switch (c.Type) {
            case "VmaxTechComment":
              c.Label = "Review.View.Report.29";
              break;
            case "VmaxExTestNotes":
              c.Label = "Review.View.Report.30";
              break;
            case "VmaxExEndNotes":
              c.Label = "Review.View.Report.31";
              break;
            case "VmaxExTechNotes":
              c.Label = "Review.View.Report.32";
              break;
          }
          return c;
        })
        .sort((a, b) => a.Label.localeCompare(b.Label));
    },
    vmaxLogicTree() {
      return this.comments.filter(
        (c) => c.Type === "VmaxExAutoInterp" || c.Type === "VmaxPfAutoInterp"
      );
    },
    vmaxInterpretations() {
      return this.comments.filter(
        (c) => c.Type === "VmaxExInterp" || c.Type === "VmaxPfInterp"
      );
    },
    isVmaxVisit() {
      if (!this.selectedVisit || !this.selectedVisit.Code) {
        return false;
      }
      const code = this.selectedVisit.Code.trim().toLowerCase();
      return (
        code.includes("vmax") ||
        code.includes("mgc") ||
        code.includes("nspire") ||
        code.includes("spcs")
      );
    },
  },
};
</script>

<style>
.comment-history a {
  pointer-events: none !important;
  display: inline-block !important;
  color: #06c !important;
}
</style>

import store from "@/store";
import {
  AUTHENTICATE,
  CHANGE_LOCALE,
  FETCH_CONFIGURATION,
  FETCH_VISIT,
  INIT_COMPLETE,
  LOGOUT_WITHOUT_REQUEST,
  START_DESKTOP_MODE,
  START_PATIENT_MODE,
  START_SPECIAL_REPORT_MODE,
} from "@/store/actions.type";
import router from "@/router/router";
import { ready } from "@/desktop";

// When running within the desktop application, we use LOGOUT_WITHOUT_REQUEST to avoid the invalidation of the default
// or user token
export const desktop = () => {
  console.log("starting desktop mode");
  // The old CEF based wrapper uses window.sesbound to expose version and raiseEvent() to the web side.
  // The new WebView2 based wrapper works slightly different.
  // To support both wrappers (mixed mode) window.sesbound is created here if necessary and version is mapped to the new location.
  if (!window.sesbound) {
    // 3.30
    window.sesbound = {
      version: window.chrome.webview.hostObjects.sync.sesbound.version,
    };
  } else if (!window.sesbound.version) {
    // 3.10
    window.sesbound.version = 0;
  }
  // else 3.20

  // extend sesbound
  window.sesbound.showSettingsDialog = () => {
    router.push({ name: "settings" }).catch((err) => console.log(err));
  };
  window.sesbound.changeUser = (user, token, workstationID, patientID) => {
    store
      .dispatch(LOGOUT_WITHOUT_REQUEST)
      .then(() => window.sesbound.login(user, token, workstationID, patientID));
  };
  window.sesbound.logout = () => {
    store.dispatch(LOGOUT_WITHOUT_REQUEST);
  };
  window.sesbound.login = (user, token, workstationID, patientID) => {
    console.log(user, token, workstationID, patientID);
    store
      .dispatch(AUTHENTICATE, {
        username: user,
        token,
        workstationID,
      })
      .then(() => {
        // change to the user language
        store.dispatch(CHANGE_LOCALE, {
          language: store.getters.userLanguage,
          country: store.getters.userCountry,
        });
        // once authenticated load configuration
        return store.dispatch(FETCH_CONFIGURATION);
      })
      .then(() => {
        if (patientID) {
          store.dispatch(START_SPECIAL_REPORT_MODE);
          store.dispatch(START_PATIENT_MODE, patientID);
          router
            .push({ name: "patient", params: { patientID } })
            .catch((err) => console.log(err));
        } else {
          router.push({ name: "visits" }).catch((err) => console.log(err));
        }
        return store.dispatch(INIT_COMPLETE);
      });
  };
  window.sesbound.fullViewClosed = () => {
    // FullView saves reports to the current visit. So, there is no need to
    // fetch all visits.
    const visitID = store.getters.selection.visitID;
    store.dispatch(FETCH_VISIT, { visitID });
  };
  const version = window.sesbound.version;
  console.log(`wrapper has version ${version}`);
  // set desktop mode and signalize ready
  return store.dispatch(START_DESKTOP_MODE, { version }).then(ready);
};

export const mobile = () => {
  console.log("starting mobile mode");
  let pr = Promise.resolve(true);
  if (store.getters.isAuthenticated) {
    // switch to user language and fetch configuration
    pr = Promise.all([
      store.dispatch(FETCH_CONFIGURATION),
      store.dispatch(CHANGE_LOCALE, {
        language: store.getters.userLanguage,
        country: store.getters.userCountry,
      }),
    ]);
  }
  return pr
    .then(() => {
      store.dispatch(INIT_COMPLETE);
    })
    .catch(() => {
      store.dispatch(LOGOUT);
    });
};

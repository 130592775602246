<template>
  <div>
    <input
      v-model="query"
      @keydown.enter="onEnter"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @input="onChange"
      @focus="isOpen = true"
      type="text"
      class="form-control form-control-sm"
    />
    <ul class="w-100 dropdown-menu scrollable" :class="{ show: isOpen }">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result.Name)"
        class="dropdown-item word-break"
        :class="{ 'bg-active': i === arrowCounter }"
      >
        <span class="w-100" v-html="highlight(result.Name)"></span>
      </li>
      <li
        class="dropdown-item disabled"
        v-if="results.length === 0"
        style="cursor: not-allowed"
      >
        {{ "Review.View.ReportSearch.46" | localized }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Typeahead",
  props: {
    label: String,
    initialValue: String,
    options: Array,
  },
  data() {
    return {
      query: this.initialValue || "",
      isOpen: false,
      // results: this.options,
      arrowCounter: -1,
    };
  },
  computed: {
    results() {
      return this.options.filter((item) => {
        return item.Name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      });
    },
    regex() {
      const escaped = this.query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      return new RegExp(escaped, "gi");
    },
  },
  watch: {
    initialValue(newValue) {
      this.query = newValue || "";
    },
  },
  methods: {
    highlight(rt) {
      if (!this.query) {
        return rt;
      }
      return rt.replace(this.regex, (match) => {
        return '<span class="font-weight-bold">' + match + "</span>";
      });
    },
    onChange() {
      // Let's warn the parent that a change was made
      this.$emit("input", this.query);
      this.isOpen = true;
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter(event) {
      if (this.results.length > 0 && this.arrowCounter > -1) {
        event.preventDefault();
        this.query = this.results[this.arrowCounter].Name;
      }
      this.isOpen = false;
      this.arrowCounter = -1;
      this.$emit("input", this.query);
    },
    setResult(result) {
      this.$emit("input", result);

      this.query = result;
      this.isOpen = false;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.scrollable {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  max-height: 20vh;
  overflow-y: auto;
}
.word-break {
  white-space: normal !important;
  word-break: break-word;
}
</style>

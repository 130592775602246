var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-white border-bottom pb-2 container-fluid",
      staticStyle: { "border-bottom-width": "2px !important" },
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.search.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c("FilterForm", {
                      attrs: { filters: _vm.visibleFilters },
                      model: {
                        value: _vm.currentFilter,
                        callback: function ($$v) {
                          _vm.currentFilter = $$v
                        },
                        expression: "currentFilter",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _c("label", { staticClass: "small text-muted" }, [
                    _c("b", [_vm._v(_vm._s(_vm.activeFilterCount))]),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm._f("localized")("Review.View.Visit.12")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-group d-flex" }, [
                    _c(
                      "button",
                      {
                        staticClass: "w-100 btn btn-sm btn-outline-secondary",
                        attrs: { id: "btn-search" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.search.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "fas fa-search" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "w-100 btn btn-sm btn-outline-secondary",
                        attrs: { id: "btn-toggle-filter" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleAdvanced.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "fas",
                          class: {
                            "fa-plus": !_vm.showAdvanced,
                            "fa-minus": _vm.showAdvanced,
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.showAdvanced
            ? _c("div", { staticClass: "row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c("FilterForm", {
                      attrs: { filters: _vm.hiddenFilters },
                      model: {
                        value: _vm.currentFilter,
                        callback: function ($$v) {
                          _vm.currentFilter = $$v
                        },
                        expression: "currentFilter",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _c("label", { staticClass: "small text-muted" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("localized")("Review.View.ReportSearch.24")
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-100 mb-2 btn btn-sm btn-outline-secondary",
                      attrs: { id: "btn-use-and-hide" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.useAndHide.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("localized")("Review.View.ReportSearch.26")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "w-100 btn btn-sm btn-outline-secondary",
                      attrs: { id: "btn-clear-all" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clear.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("localized")("Review.View.ReportSearch.25")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-4" }, [_vm._t("pagination")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "col-8 text-right" }, [
          _c("div", { staticClass: "d-inline-block" }, [
            _c(
              "div",
              { staticClass: "form-inline" },
              [
                _c("Sort"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ml-2 btn btn-sm btn-primary",
                    attrs: {
                      id: "btn-batch-mode",
                      disabled: _vm.isBatchModeDisabled,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.startBatchMode.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "fas fa-exchange-alt" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("localized")("Review.View.ReportSearch.42")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ml-1 btn-group btn-group-sm",
                    attrs: { role: "group", "aria-label": "saved-filters" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary",
                        attrs: { id: "btn-save-filter" },
                        on: { click: _vm.showSaveFilterModal },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("localized")("Review.View.ReportSearch.33")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary",
                        attrs: { id: "btn-load-filter" },
                        on: { click: _vm.showLoadFilterModal },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("localized")("Review.View.ReportSearch.39")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("FilterModalSave", { ref: "saveFilterModal" }),
      _vm._v(" "),
      _c("FilterModalLoad", { ref: "loadFilterModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
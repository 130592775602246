<template>
  <b-modal lazy ref="modal" @show="onShow" @hide="$emit('hide', $event)">
    <template slot="modal-title">
      <h5 class="modal-title">{{ "Review.View.Sign.1" | localized }}</h5>
    </template>
    <template slot="modal-footer">
      <button
        id="btn-cancel-sign-modal"
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.Sign.3" | localized }}
      </button>
      <button
        id="btn-confirm-sign-modal"
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="busy"
        @click="handleOK"
      >
        <Spinner type="border" small color="white" v-if="busy" />
        {{ "Review.View.Sign.2" | localized }}
      </button>
    </template>
    <!-- content -->
    <form @submit.prevent="handleOK">
      <div>
        <ErrorMessage :error="error" :dismiss="hideError" />
        <div class="form-group row">
          <label
            for="input-username-sign-modal"
            class="col-3 col-form-label col-form-label-sm"
            >{{ "Review.View.Login.1" | localized }}</label
          >
          <div class="col-9">
            <input
              id="input-username-sign-modal"
              ref="usernameInput"
              v-model="username"
              type="text"
              class="form-control form-control-sm"
              autocomplete="username"
            />
          </div>
        </div>
        <div class="form-group row">
          <label
            for="input-password-sign-modal"
            class="col-3 col-form-label col-form-label-sm"
            >{{ "Review.View.Login.3" | localized }}</label
          >
          <div class="col-9">
            <input
              id="input-password-sign-modal"
              v-model="password"
              type="password"
              class="form-control form-control-sm"
              autocomplete="current-password"
            />
          </div>
        </div>
      </div>
    </form>

    <div>{{ "Review.View.Sign.8" | localized }}.</div>
  </b-modal>
</template>

<script>
import ApiService from "@/common/api.service";
import { NOTIFICATION_DURATION_ERROR } from "@/common/constants";
import { SIGN_REPORT_TECHNICIAN } from "@/store/actions.type";

import ErrorMessage from "@/components/ErrorMessage";
import Spinner from "@/components/Spinner";

export default {
  name: "ReportModalSignTechnician",
  props: {
    comments: { Type: Array, required: true },
  },
  components: {
    Spinner,
    ErrorMessage,
  },
  computed: {
    isDefaultUser() {
      return this.$store.getters.isDefaultUser;
    },
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    selectedReport() {
      return this.$store.getters.selectedReport;
    },
    workstationID() {
      return this.$store.getters.workstationID;
    },
  },
  data() {
    return {
      busy: false,
      username: "",
      password: "",
      error: "",
    };
  },
  methods: {
    hideError() {
      this.error = "";
    },
    show() {
      this.$refs.modal.show();
    },
    handleOK() {
      this.busy = true;
      // check login
      ApiService.login(this.username, this.password, "", this.workstationID)
        .then((d) => {
          const reportName = this.selectedReport.Filename;
          const promises = this.comments.map((c) => {
            return ApiService.saveTechnicianComment(
              this.selectedVisit.ID,
              this.selectedReport.ID,
              c.LinkID,
              c.Text
            );
          });
          Promise.all(promises)
            .then(() => {
              this.$store.dispatch(SIGN_REPORT_TECHNICIAN, {
                visitID: this.selectedVisit.ID,
                reportID: this.selectedReport.ID,
                token: d.Token,
                reportName,
              });
              this.busy = false;
              this.$refs.modal.hide("ok");
            })
            .catch(() => {
              this.$notify({
                type: "danger",
                duration: NOTIFICATION_DURATION_ERROR,
                title: reportName,
                text: "Review.View.Report.11",
              });
              this.busy = false;
            });
        })
        .catch(() => {
          this.busy = false;
          this.password = "";
          this.error = "Review.View.Login.5";
        });
    },
    handleCancel() {
      // use default "events"
      this.$refs.modal.hide("cancel");
    },
    onShow() {
      this.username = "";
      if (!this.isDefaultUser) {
        this.username = this.$store.getters.username;
      }
      this.password = "";
      this.hideError();
    },
  },
};
</script>

<style scoped></style>

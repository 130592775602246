var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid bg-white" }, [
    _c("div", { staticClass: "d-flex w-100 justify-content-between" }, [
      _c("img", {
        attrs: {
          id: "review-logo",
          src: require("@/assets/img/logo-header.png"),
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "mr-2" }, [
          _c("i", { staticClass: "fas fa-user" }),
          _vm._v(" " + _vm._s(_vm.account.USER_DISPLAY_NAME) + "\n      "),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "mr-1 btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-settings" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goToSettings.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "fas fa-cog" })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-logout" },
            on: { click: _vm.logout },
          },
          [
            _c("span", { staticClass: "fas fa-sign-out-alt" }),
            _vm._v(" "),
            _c("timer", { attrs: { time: _vm.logoutTime } }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
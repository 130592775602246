<template>
  <div
    ref="insertPanel"
    class="bg-white rounded border shadow position-absolute small"
    style="
      top: 48px;
      right: 8px;
      height: calc(100% - 56px);
      min-height: 200px;
      z-index: 10;
      width: 66%;
    "
  >
    <div class="h-100 d-flex flex-column position-relative">
      <div class="bg-light p-2 d-flex">
        <div class="flex-fill">
          <input
            type="text"
            ref="searchInput"
            class="w-100 form-control form-control-sm"
            v-model="search"
          />
        </div>
        <div class="pl-2 btn-group btn-group-sm">
          <button
            class="btn btn-outline-secondary"
            title="expand all"
            ref="btnExpand"
            @click="expandAll"
          >
            <b-icon-arrows-expand />
          </button>
          <button
            class="btn btn-outline-secondary"
            title="collapse all"
            ref="btnCollapse"
            @click="collapseAll"
          >
            <b-icon-arrows-collapse />
          </button>
        </div>
      </div>
      <div
        class="tree h-100"
        :class="{
          'align-items-center justify-content-center':
            filteredValues.length === 0,
        }"
        ref="selectionList"
      >
        <div
          class="template-node selectable p-1 border-bottom cursor-pointer"
          v-for="(t, idx) in filteredValues"
          :key="idx"
          :id="`node-${idx}`"
          :style="{
            paddingLeft: 10 + 16 * t.depth + 'px !important',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }"
          :class="{
            'bg-active': selectedIndex === idx,
            'bg-light': t.isCategory,
          }"
          @click="onSelection(t, idx)"
        >
          <template v-if="t.isCategory">
            <span
              class="fas"
              :class="{
                'fa-caret-down': !collapsedCategories[t.path],
                'fa-caret-right': collapsedCategories[t.path],
              }"
              style="text-align: center; min-width: 15px"
            ></span>
            {{ t.Name }}
          </template>
          <span v-html="highlight(t.Name)" v-else></span>
        </div>
        <div v-if="filteredValues.length === 0">
          {{ "Review.View.Report.40" | localized }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconArrowsCollapse, BIconArrowsExpand } from "bootstrap-vue";

import { COMMENT_TEMPLATES_STATE } from "@/common/constants";

const flattenAndFilter = (v, path, depth, filterFunc, res) => {
  const isCategory = !!v.Children;
  const item = {
    Name: v.Name,
    ID: v.ID,
    path,
    depth,
    isCategory,
  };
  // apply filterFunc to leaf nodes
  if (!isCategory && filterFunc(item)) {
    res.push(item);
    return;
  }
  if (isCategory) {
    res.push(item);
    // remove empty category
    const len = res.length;
    v.Children.forEach((v, idx) =>
      flattenAndFilter(v, `${path}.${idx}`, depth + 1, filterFunc, res)
    );
    if (len === res.length) {
      res.pop();
    }
  }
};

export default {
  name: "EditorInsertPanel",
  components: {
    BIconArrowsCollapse,
    BIconArrowsExpand,
  },
  props: {
    values: { Type: Object },
  },
  data() {
    return {
      search: "",
      selectedIndex: 0,
      collapsedCategories: {},
    };
  },
  computed: {
    filteredValues() {
      const res = [];
      // apply search filter
      this.values.Children.forEach((v, idx) =>
        flattenAndFilter(
          v,
          idx + "",
          0,
          (v) =>
            this.useStartsWithSearch
              ? v.Name.toLowerCase().startsWith(this.search.toLowerCase())
              : v.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1,
          res
        )
      );
      const paths = Object.keys(this.collapsedCategories);
      // filter out collapsed categories/paths
      return res.filter((v) => {
        for (const path of paths) {
          // exclude all sub path items
          if (v.path.startsWith(path + ".")) {
            return false;
          }
        }
        return true;
      });
    },
    regex() {
      const escaped = this.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      let flags = "gi";
      if (this.useStartsWithSearch) {
        flags = "i";
      }
      return new RegExp(escaped, flags);
    },
    useStartsWithSearch() {
      return (
        this.$vnode.key === "commentTemplates" &&
        this.$store.getters.commentTemplateSearch === "StartsWith"
      );
    },
  },
  watch: {
    search: function () {
      this.first();
      // reset categories
      this.expandAll();
      this.scrollToSelection();
    },
  },
  created() {
    const paths = window.sessionStorage.getItem(COMMENT_TEMPLATES_STATE);
    try {
      this.collapsedCategories = JSON.parse(paths) || {};
    } catch (e) {
      console.log("parsing comment template state failed:", e);
    }
  },
  mounted() {
    this.first();
    this.scrollToSelection();
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);

    this.$refs.searchInput.focus();
  },
  destroyed() {
    // store current comment template state
    window.sessionStorage.setItem(
      COMMENT_TEMPLATES_STATE,
      JSON.stringify(this.collapsedCategories)
    );

    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    collapseAll() {
      this.$refs.btnCollapse.blur();
      const res = {};
      this.filteredValues.forEach((v) => {
        if (v.isCategory) {
          res[v.path] = true;
        }
      });
      this.collapsedCategories = res;
    },
    expandAll() {
      this.$refs.btnExpand.blur();
      this.collapsedCategories = {};
    },
    focus() {
      this.$refs.searchInput.focus();
    },
    hide() {
      this.$emit("hide");
    },
    select() {
      const v = this.filteredValues[this.selectedIndex];
      if (v) {
        this.onSelection(v, this.selectedIndex);
      }
    },
    onSelection(t, idx) {
      this.selectedIndex = idx;
      if (t.isCategory) {
        if (this.collapsedCategories[t.path]) {
          this.$delete(this.collapsedCategories, t.path);
        } else {
          this.$set(this.collapsedCategories, t.path, true);
        }
      } else {
        this.$emit("selected", t.ID);
        this.search = "";
      }
      this.focus();
    },
    highlight(rt) {
      if (!this.search) {
        return rt;
      }
      return rt.replace(this.regex, (match) => {
        return (
          '<span class="font-weight-bold highlighted">' + match + "</span>"
        );
      });
    },
    scrollToSelection() {
      // move scroll
      const el = document.getElementById(`node-${this.selectedIndex}`);
      if (!el) {
        return;
      }
      // get bounding rect height
      const { height } = this.$refs.selectionList.getBoundingClientRect();
      // we add an additional offset to make sure a small part of the element above is still visible
      this.$refs.selectionList.scrollTop = el.offsetTop - (height / 2 + 50);
    },
    first() {
      // select first non category element
      for (let i = 0; i < this.filteredValues.length; i++) {
        if (!this.filteredValues[i].isCategory) {
          this.selectedIndex = i;
          return;
        }
      }
    },
    prev() {
      if (this.selectedIndex <= 0) {
        return;
      }
      this.selectedIndex = this.selectedIndex - 1;
      this.scrollToSelection();
    },
    next() {
      if (this.selectedIndex >= this.filteredValues.length - 1) {
        return;
      }
      this.selectedIndex = this.selectedIndex + 1;
      this.scrollToSelection();
    },
    handleKeyUp(event) {
      // no-op if event was already handled
      if (event.defaultPrevented) {
        return;
      }
      // fallback to keyCode
      const key = event.key || event.keyCode;
      if (key === "Enter" || key === 13) {
        this.select();
        return;
      }
      if (
        key === "Esc" ||
        key === "Escape" ||
        key === 27 ||
        ((key === "Digit1" || key === "1" || key === 49) &&
          event.ctrlKey === true)
      ) {
        this.hide();
      }
    },
    handleKeyDown(event) {
      // no-op if event was already handled
      if (event.defaultPrevented) {
        return;
      }
      const key = event.key || event.keyCode;
      // Prevent default for "<ctrl> + <1>", but wait for keyup event
      if (
        (key === "Digit1" || key === "1" || key === 49) &&
        event.ctrlKey === true
      ) {
        event.preventDefault();
      }
      if (key === "ArrowUp" || key === "Up" || key === 38) {
        event.preventDefault();
        this.prev();
        return;
      }
      if (key === "ArrowDown" || key === "Down" || key === 40) {
        event.preventDefault();
        this.next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../_custom.scss";

.highlighted {
  background-color: map-get($theme-colors, "highlight-light") !important;
}

.tree {
  overflow: auto;
}

.selectable:hover {
  cursor: pointer;
  background-color: map-get($theme-colors, "active") !important;
}
</style>

<template>
  <div class="w-100">
    <div v-if="!isInterpretationReport">
      {{ reportStatus.label | localized }}
    </div>
    <div v-if="his" class="w-100">
      {{ hisKey | localized }}
    </div>
  </div>
</template>

<script>
import { HISStatusLabel, isSentToHIS } from "@/common/his";
import { convertIntToReportStatus } from "@/common/utils";
import { REPORT_TYPE_INTERPRETATION } from "@/common/constants";

export default {
  name: "ReportStatus",
  props: {
    status: {
      type: Number,
    },
    reportType: {
      type: String,
    },
  },
  computed: {
    his() {
      return isSentToHIS(this.status);
    },
    hisKey() {
      return HISStatusLabel(this.status);
    },
    reportStatus() {
      return convertIntToReportStatus(this.status);
    },
    isInterpretationReport() {
      return this.reportType === REPORT_TYPE_INTERPRETATION;
    },
  },
};
</script>

<style scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-icon" }, [
    _vm.isVmax
      ? _c("img", {
          attrs: {
            src: "static/vmax-report.png",
            alt: "Vmax report",
            height: "20px",
          },
        })
      : _c("span", { class: _vm.icon }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
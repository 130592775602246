var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: { lazy: "", id: "load-filter-modal" },
          on: {
            show: _vm.clear,
            hide: function ($event) {
              return _vm.$emit("hide", $event)
            },
          },
        },
        [
          _c("template", { slot: "modal-title" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("localized")("Review.View.ReportSearch.39")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-outline-secondary",
                attrs: { id: "btn-cancel-load-filter", type: "button" },
                on: { click: _vm.handleCancel },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("localized")("Review.View.ReportSearch.28")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                attrs: {
                  id: "btn-confirm-load-filter",
                  type: "button",
                  disabled:
                    _vm.selection === "" || _vm.savedFilters.length === 0,
                },
                on: { click: _vm.handleOK },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("localized")("Review.View.ReportSearch.30")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-group" },
            _vm._l(_vm.savedFilters, function (filter) {
              return _c(
                "a",
                {
                  key: filter,
                  staticClass:
                    "list-group-item list-group-item-action d-flex p-2 pl-3 justify-content-between align-items-center",
                  class: { "text-white active": _vm.selection === filter },
                  attrs: { id: `btn-load-filter-${filter}` },
                  on: {
                    click: function ($event) {
                      return _vm.select(filter)
                    },
                  },
                },
                [
                  _vm._v("\n        " + _vm._s(filter) + "\n        "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm",
                      class: {
                        "btn-primary": _vm.selection === filter,
                        "btn-outline-secondary": _vm.selection !== filter,
                      },
                      attrs: { id: `btn-delete-filter-${filter}` },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showDeleteFilterModal(filter)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "fas fa-trash-alt",
                        attrs: { id: "btn-delete-filter" },
                      }),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.savedFilters.length === 0
            ? _c("div", { staticClass: "text-center text-muted" }, [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm._f("localized")("Review.View.ReportSearch.32"))
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("FilterModalDelete", {
        ref: "deleteFilterModal",
        on: { hide: _vm.handleDeleteFilter },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
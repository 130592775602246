var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("img", {
      style: { backgroundColor: _vm.configuration._bg },
      attrs: {
        height: "20px",
        src: "static/" + _vm.configuration._src + ".png",
      },
    }),
    _vm._v(" "),
    _vm.freeze ? _c("span", { staticClass: "fas fa-lock" }) : _vm._e(),
    _vm._v("\n  " + _vm._s(_vm._f("localized")(_vm.configuration._key)) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
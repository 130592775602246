<template>
  <div>
    <span v-for="(n, idx) in range" :key="idx">
      <button
        :id="`btn-page-${n}`"
        class="mr-1 btn btn-sm btn-outline-primary"
        :class="{ 'active btn-primary': currentPage === n }"
        @click.prevent="select(n, $event)"
        v-if="n !== '...'"
      >
        {{ n }}
      </button>
      <span class="mr-1" v-else>...</span>
    </span>
    <label id="visit-count" class="small text-muted">
      {{ visitsCount }} {{ "Review.View.Visit.11" | localized }}
    </label>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    selectPage: {
      type: Function,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.$store.getters.isFetchingVisits;
    },
    visitsCount() {
      return this.$store.getters.visitsCount;
    },
    currentPage() {
      return this.$store.getters.currentPage;
    },
    range() {
      if (this.numPages === 0) {
        return [];
      }

      const delta = 2;

      let range = [];
      for (
        let i = Math.max(delta, this.currentPage - delta);
        i <= Math.min(this.numPages - 1, this.currentPage + delta);
        i++
      ) {
        range.push(i);
      }

      if (this.currentPage - delta > delta) {
        range.unshift("...");
      }
      if (this.currentPage + delta < this.numPages - 1) {
        range.push("...");
      }

      if (this.numPages > 1) {
        range.unshift(1);
      }
      range.push(this.numPages);
      return range;
    },
    numPages() {
      return Math.ceil(this.visitsCount / this.$store.getters.itemsPerPage);
    },
  },
  methods: {
    select(n, $event) {
      $event.target.blur();
      if (this.currentPage === n) {
        return;
      }
      this.selectPage(n);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div
    :class="{
      [`spinner-${type}`]: true,
      [`spinner-${type}-sm`]: small,
      [`text-${color}`]: true,
    }"
    role="status"
  >
    <span class="sr-only">{{ "Review.View.Loading.1" | localized }}</span>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    small: { type: Boolean, default: false },
    type: { type: String, required: true },
    color: { type: String, default: "primary" },
  },
};
</script>

<style scoped></style>

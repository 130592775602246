<template>
  <div class="w-100 h-100">
    <notifications
      width="30%"
      class="mt-2"
      position="top center"
      :duration="5000"
    >
      <template slot="body" slot-scope="props">
        <div
          class="alert shadow d-flex justify-content-between"
          :class="`alert-${props.item.type}`"
        >
          <div>
            <b v-if="props.item.title">{{ props.item.title }}:</b>
            {{ props.item.text | localized }}
            <span v-if="props.item.data && props.item.data.message">
              <br /><i class="fas fa-exclamation-triangle"></i>
              {{ props.item.data.message }}
            </span>
          </div>
          <button
            id="btn-dismiss-notification"
            type="button"
            class="close"
            @click="props.close"
          >
            &times;
          </button>
        </div>
      </template>
    </notifications>
    <router-view />
  </div>
</template>

<script>
import "@/assets/img/logo-white-lines-40.png";
import "@/assets/img/logo-header.png";

export default {
  name: "App",
};
</script>

<style scoped></style>

// The old CEF based wrapper (window.sesbound.version < 2) uses a different communication
// mechanism than the new WebView2 based one
// We want to support both (mixed mode)

export const openMeasurement = (
  measurementType,
  levelID,
  conductedMethodIds
) => {
  if (window.sesbound.version < 2) {
    window.sesbound.raiseEvent("openmeasurement", {
      MeasurementType: measurementType,
      LevelId: levelID,
      ConductedMethodIds: conductedMethodIds,
    });
  } else {
    window.chrome.webview.postMessage(
      `openmeasurement ${levelID} ${measurementType} ${conductedMethodIds}`
    );
  }
};

export const changeVisit = (visitID = "", patientID = "") => {
  if (window.sesbound.version < 2) {
    window.sesbound.raiseEvent("changevisit", {
      VisitID: visitID,
      PatientID: patientID,
    });
  } else {
    //Note: visit id MUST be first as patient id might be empty
    window.chrome.webview.postMessage(`changevisit ${visitID} ${patientID}`);
  }
};

export const ready = () => {
  if (window.sesbound.version < 2) {
    window.sesbound.raiseEvent("ready", { foo: "bar" });
  } else {
    window.chrome.webview.postMessage("ready");
  }
};

export const openFullView = () => {
  // not supported before 3.20
  if (window.sesbound.version < 1) {
    return;
  }
  // 3.20
  if (window.sesbound.version === 1) {
    window.sesbound.raiseEvent("openFullView", { foo: "bar" });
    return;
  }
  // 3.30
  window.chrome.webview.postMessage("openfullview");
};

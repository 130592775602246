<template>
  <div
    :id="`visit-${visit.ID}`"
    class="row m-2 border border-secondary visit"
    :class="{
      'border-selected shadow': selected,
    }"
  >
    <div class="col-12">
      <div
        class="row p-1 visit-header"
        :class="{ 'bg-selected': selected }"
        @click="select()"
      >
        <div class="col-12">
          <div class="row">
            <div class="order-lg-7 order-1 col-12 col-sm-12 col-lg">
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.11" | localized }}
              </div>
              <template v-if="!isVisitOperationPending">
                <VisitStatusFreeze
                  v-if="selected && isFreezeEnabled && hasFreezeRight"
                  :visit="visit"
                />
                <VisitStatus
                  :visitID="visit.ID"
                  :freeze="visit.ReviewStatusFreeze"
                  :status="visit.ReviewStatus"
                  v-else
                />
              </template>
              <Spinner type="border" small v-else></Spinner>
            </div>
            <div class="order-lg-1 order-2 col-6 col-sm-4 col-lg">
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.1" | localized }}
              </div>
              {{ visit.LocalDate | datetime }}
            </div>
            <div class="order-lg-2 order-3 col-6 col-sm-4 col-lg">
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.3" | localized }}
              </div>
              <PatientName
                :firstName="visit.Patient.FirstName"
                :middleName="visit.Patient.MiddleName"
                :lastName="visit.Patient.LastName"
              />
            </div>
            <div class="order-lg-3 order-4 col-6 col-sm-4 col-lg">
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.8" | localized }}
              </div>
              {{ visit.Patient.ExternalID }}
            </div>
            <div class="order-lg-4 order-5 col-6 col-sm-4 col-lg">
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.6" | localized }}
              </div>
              {{ visit.Patient.Birthdate | date }}
            </div>
            <div
              v-if="isHISAvailable"
              class="order-lg-5 order-6 col-6 col-sm-4 col-lg"
            >
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.10" | localized }}
              </div>
              {{ visit.HISOrderID | placeholder }}
            </div>
            <div
              v-if="isHISAvailable"
              class="order-lg-6 order-7 col-6 col-sm-4 col-lg"
            >
              <div class="visit-label text-muted">
                {{ "Review.View.VisitList.9" | localized }}
              </div>
              {{ visit.HISVisitID | placeholder }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ReportList
          :reports="visit.Reports"
          :visitID="visit.ID"
          :onSelect="select"
        />
      </div>
      <div v-if="selected" class="row">
        <div class="col-12 pl-3 p-2 bg-white">
          <div class="d-flex align-items-end">
            <div class="mr-2">
              <div>
                <label class="visit-label text-muted">
                  {{ "Review.View.Visit.14" | localized }}
                </label>
              </div>
              <div :class="{ 'btn-group': showUploadButton }">
                <button
                  id="btn-create-report"
                  class="btn btn-sm btn-outline-secondary"
                  :disabled="isCreateButtonDisabled"
                  @click="onCreateReport"
                >
                  {{ "Review.View.Visit.7" | localized }}
                </button>
                <input
                  type="file"
                  accept="application/pdf"
                  ref="uploadReport"
                  style="display: none"
                  @change="handleUploadReport"
                />
                <button
                  v-if="showUploadButton"
                  id="btn-upload-report"
                  class="btn btn-sm btn-outline-secondary"
                  :disabled="isUploadButtonDisabled"
                  @click="$refs.uploadReport.click()"
                >
                  {{ "Review.View.Visit.13" | localized }}
                </button>
              </div>
            </div>
            <div v-if="isFullViewButtonShown" class="mr-2">
              <button
                id="btn-open-full-view"
                class="btn btn-sm btn-outline-secondary"
                @click="handleFullView"
              >
                {{ "Review.View.Visit.17" | localized }}
              </button>
            </div>
            <div class="mr-2">
              <div class="btn-group">
                <button
                  id="btn-update-report"
                  class="btn btn-sm btn-outline-secondary"
                  :disabled="isUpdateButtonDisabled"
                  @click="onUpdateReport"
                >
                  {{ "Review.View.Visit.4" | localized }}
                </button>
                <button
                  id="btn-delete-report"
                  class="btn btn-sm btn-outline-secondary"
                  :disabled="isDeleteButtonDisabled"
                  @click="onDeleteReport"
                >
                  {{ "Review.View.Visit.6" | localized }}
                </button>
                <button
                  v-if="isHISAvailable"
                  id="btn-send-report"
                  class="btn btn-sm btn-outline-secondary"
                  :disabled="isSendButtonDisabled"
                  @click="onSendReport"
                >
                  {{ "Review.View.Visit.8" | localized }}
                </button>
              </div>
            </div>
            <div v-if="!isPatientMode">
              <div>
                <label class="visit-label text-muted">
                  {{ "Review.View.Visit.15" | localized }}
                </label>
              </div>
              <button
                id="btn-patient-details"
                class="btn btn-sm btn-outline-secondary"
                @click="onPatientDetails(visit.Patient.ID)"
              >
                {{ "Review.View.Visit.16" | localized }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeVisit, openFullView } from "@/desktop";

import { FEATURE_FULL_VIEW, REPORT_TYPE_EXTERNAL } from "@/common/constants";
import { isSentToHIS } from "@/common/his";
import { convertIntToReportStatus, supportedFeatures } from "@/common/utils";

import {
  SELECT_VISIT,
  FETCH_LEVELS,
  FETCH_VISIT_META_DATA,
} from "@/store/actions.type";
import ReportList from "@/components/views/visits/ReportList";
import Spinner from "@/components/Spinner";
import PatientName from "@/components/PatientName";
import VisitStatusFreeze from "@/components/VisitStatusFreeze";
import VisitStatus from "@/components/VisitStatus";

export default {
  name: "VisitListItem",
  components: {
    VisitStatus,
    Spinner,
    ReportList,
    PatientName,
    VisitStatusFreeze,
  },
  props: {
    visit: { type: Object, required: true },
    selected: { type: Boolean },
    isPatientMode: { type: String },
    onCreateReport: { type: Function, required: true },
    onUpdateReport: { type: Function, required: true },
    onDeleteReport: { type: Function, required: true },
    onSendReport: { type: Function, required: true },
    onPatientDetails: { type: Function, required: true },
  },
  computed: {
    isDesktopMode() {
      return this.$store.getters.isDesktopMode;
    },
    isFullViewButtonShown() {
      const version = this.$store.getters.wrapperVersion;
      return (
        this.isDesktopMode &&
        supportedFeatures(version).includes(FEATURE_FULL_VIEW)
      );
    },
    isFreezeEnabled() {
      return this.$store.getters.isFreezeEnabled;
    },
    hasFreezeRight() {
      return this.$store.getters.hasFreezeRight;
    },
    isVisitOperationPending() {
      return this.$store.getters.isVisitOperationPending(this.visit.ID);
    },
    isUpdateButtonDisabled() {
      // check the current selected report
      const r = this.$store.getters.selectedReport;
      if (!r) {
        return true;
      }
      if (r.Type === REPORT_TYPE_EXTERNAL && !r.Filename.endsWith("Signed")) {
        return true;
      }
      if (!this.$store.getters.hasModifyRight) {
        return true;
      }
      return this.$store.getters.isReportOperationPending(
        this.selection.reportID
      );
    },
    isDeleteButtonDisabled() {
      const r = this.$store.getters.selectedReport;
      if (!r) {
        return true;
      }
      if (!this.$store.getters.hasDeleteRight) {
        return true;
      }
      if (isSentToHIS(r.Status) && !this.$store.getters.isDeleteReportAllowed) {
        return true;
      }
      return this.$store.getters.isReportOperationPending(
        this.selection.reportID
      );
    },
    isSendButtonDisabled() {
      const r = this.$store.getters.selectedReport;
      if (!r) {
        return true;
      }
      const reportStatus = convertIntToReportStatus(r.Status).status;
      switch (reportStatus) {
        case "Preliminary":
          if (!this.$store.getters.hasSendRight) {
            return true;
          }
          break;
        case "Final":
        case "Correction":
          if (!this.$store.getters.hasSendFinalRight) {
            return true;
          }
          break;
        default:
          return true;
      }
      /**
       * isSendToHISWithoutCommentAllowed	hasSignedPhysicianComment		button disabled
       *                                0	                        0		              1  <---
       *                                0	                        1	                0
       *                                1	                        0	                0
       *                                1	                        1	                0
       */
      if (
        !this.$store.getters.isSendToHISWithoutCommentAllowed &&
        !this.$store.getters.hasSignedPhysicianComment
      ) {
        return true;
      }
      return this.$store.getters.isReportOperationPending(
        this.selection.reportID
      );
    },
    isCreateButtonDisabled() {
      return !this.$store.getters.hasCreateRight;
    },
    isUploadButtonDisabled() {
      return !this.$store.getters.hasImportRight;
    },
    selection() {
      return this.$store.getters.selection;
    },
    configurations() {
      return this.$store.getters.activeWorkflowConfigurations;
    },
    showUploadButton() {
      return this.$store.getters.isExternalReportUploadAllowed;
    },
    isHISAvailable() {
      return this.$store.getters.isHISAvailable;
    },
  },
  methods: {
    select(reportID) {
      const visitID = this.visit.ID;
      // only fetch the levels on visit change
      if (this.selection.visitID !== visitID) {
        this.$store.dispatch(FETCH_LEVELS, { visitID });
      }
      this.$store.dispatch(SELECT_VISIT, {
        visitID,
        reportID,
      });
      this.$store.dispatch(FETCH_VISIT_META_DATA, { visitID });
      // trigger visit change event on desktop mode
      if (this.isDesktopMode) {
        changeVisit(visitID, this.visit.Patient.ID);
      }
    },
    handleUploadReport() {
      const file = this.$refs.uploadReport.files[0];
      if (!file) {
        return;
      }
      // reset input
      this.$refs.uploadReport.value = null;
      this.$emit("uploadReport", file);
    },
    handleFullView() {
      openFullView();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../_custom.scss";

.visit-label {
  font-size: 0.8rem;
}

.border-selected {
  border: 1px solid $input-border-color !important;
}

.visit-header {
  font-size: 14px;
  transition: background-color 0.2s linear;

  &:not(.bg-selected):hover {
    background-color: map-get($theme-colors, "hover") !important;
    cursor: pointer;
  }

  &.bg-selected {
    background-color: $input-bg !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

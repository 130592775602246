var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 btn-group btn-group-toggle btn-group-sm d-flex" },
    _vm._l(_vm.sequence, function (s, idx) {
      return _c(
        "label",
        {
          key: idx,
          staticClass: "w-100 btn btn-outline-secondary",
          class: {
            active: _vm.proxiedStatus === s.status,
            "cursor-not-allowed disabled": s.disabled || _vm.disabled,
            "cursor-pointer": !s.disabled || !_vm.disabled,
          },
          attrs: { id: `btn-report-status-selection-${s.status}` },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.proxiedStatus,
                expression: "proxiedStatus",
              },
            ],
            class: { disabled: s.disabled },
            attrs: { type: "radio", disabled: s.disabled || _vm.disabled },
            domProps: {
              value: s.status,
              checked: _vm._q(_vm.proxiedStatus, s.status),
            },
            on: {
              change: function ($event) {
                _vm.proxiedStatus = s.status
              },
            },
          }),
          _vm._v("\n    " + _vm._s(_vm._f("localized")(s.label)) + "\n  "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { lazy: "", id: "save-filter-modal" },
      on: {
        show: _vm.clear,
        shown: _vm.focusInput,
        hide: function ($event) {
          return _vm.$emit("hide", $event)
        },
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("localized")("Review.View.ReportSearch.38")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: { id: "btn-cancel-save-filter", type: "button" },
            on: { click: _vm.handleCancel },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.ReportSearch.28")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            class: { disabled: _vm.disabled },
            attrs: { id: "btn-confirm-save-filter", type: "button" },
            on: { click: _vm.handleOK },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("localized")("Review.View.ReportSearch.33")) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleOK.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "label",
              {
                staticClass: "m-0 w-100 col-form-label-sm text-muted",
                attrs: { for: "input-save-filter" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("localized")("Review.View.ReportSearch.34")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterName,
                  expression: "filterName",
                },
              ],
              ref: "saveFilter",
              staticClass: "form-control",
              attrs: { id: "input-save-filter", type: "text" },
              domProps: { value: _vm.filterName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.filterName = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.nameExists
              ? _c("div", { staticClass: "mt-2 alert alert-warning" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("localized")("Review.View.ReportSearch.27")
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
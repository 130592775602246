var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-2 cursor-move" }, [
    _c(
      "label",
      {
        staticClass: "small text-muted cursor-move",
        attrs: { for: _vm.label },
      },
      [
        _c("span", { staticClass: "fas fa-grip-vertical" }),
        _vm._v(" " + _vm._s(_vm._f("localized")(_vm.label)) + "\n  "),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "form-control form-control-sm w-100 placeholder",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import moment from "moment";

import ApiService from "@/common/api.service";
import { ErrCodeRequestTimeout } from "@/common/errors";
import {
  FILTER_DATE,
  FILTER_EXTERNAL_PATIENT_ID,
  FILTER_FIRST_NAME,
  FILTER_HIS_ORDER_ID,
  FILTER_HIS_VISIT_ID,
  FILTER_LAST_NAME,
  FILTER_LOCATION,
  FILTER_OPERATOR,
  FILTER_PATIENT_TYPE,
  FILTER_PHYSICIAN,
  FILTER_REF_PHYSICIAN,
  FILTER_REPORT_STATUS_CORRECTION,
  FILTER_REPORT_STATUS_FINAL,
  FILTER_REPORT_STATUS_PRELIMINARY,
  FILTER_SORT_BY,
  FILTER_SORT_ORDER,
  FILTER_VISIT_STATUS,
  DATE_RELATIVE_MONTH,
  DATE_RELATIVE_TODAY,
  DATE_RELATIVE_WEEK,
  DATE_RELATIVE_LAST_7_DAYS,
  DATE_RELATIVE_LAST_30_DAYS,
  DATE_RELATIVE_EXACT,
} from "@/common/constants";

export const convertFilterToBackend = (
  filter,
  locations,
  useLocationFilter
) => {
  const search = {
    DateFrom: filter.Date.from,
    DateTo: filter.Date.to,
    DateRelative: filter.Date.relative,
  };
  // keep the datetime and strip of the timezone
  const from = moment(search.DateFrom);
  const to = moment(search.DateTo);
  if (from.isValid()) {
    search.DateFrom = moment(from)
      .utc()
      .add(from.utcOffset(), "m")
      .startOf("day");
  } else {
    search.DateFrom = "";
  }
  if (to.isValid()) {
    search.DateTo = moment(to).utc().add(to.utcOffset(), "m").endOf("day");
  } else {
    search.DateTo = "";
  }
  if (useLocationFilter) {
    search.LocationID = filter[FILTER_LOCATION];
  } else if (filter[FILTER_LOCATION]) {
    const l = locations.find((l) => l.Name === filter[FILTER_LOCATION]);
    if (l) {
      search.HospitalID = l.ID;
    } else {
      search.HospitalName = filter[FILTER_LOCATION];
    }
  }
  [
    FILTER_PHYSICIAN,
    FILTER_EXTERNAL_PATIENT_ID,
    FILTER_LAST_NAME,
    FILTER_FIRST_NAME,
    FILTER_PATIENT_TYPE,
    FILTER_OPERATOR,
    FILTER_REF_PHYSICIAN,
    FILTER_VISIT_STATUS,
    FILTER_REPORT_STATUS_PRELIMINARY,
    FILTER_REPORT_STATUS_FINAL,
    FILTER_REPORT_STATUS_CORRECTION,
    FILTER_HIS_VISIT_ID,
    FILTER_HIS_ORDER_ID,
    FILTER_SORT_BY,
    FILTER_SORT_ORDER,
  ].forEach((f) => {
    search[f] = filter[f];
  });
  return search;
};

export const convertFilterToFrontend = (
  filter,
  locations,
  useLocationFilter
) => {
  const search = {
    [FILTER_DATE]: {
      from: filter.DateFrom,
      to: filter.DateTo,
      relative: filter.DateRelative,
    },
    [FILTER_LOCATION]: filter.HospitalName,
  };
  // update according to the relative time
  switch (search[FILTER_DATE].relative) {
    case DATE_RELATIVE_TODAY:
      search[FILTER_DATE].from = moment().startOf("day");
      search[FILTER_DATE].to = moment().endOf("day");
      break;
    case DATE_RELATIVE_WEEK:
      search[FILTER_DATE].from = moment().startOf("week");
      search[FILTER_DATE].to = moment().endOf("week");
      break;
    case DATE_RELATIVE_MONTH:
      search[FILTER_DATE].from = moment().startOf("month");
      search[FILTER_DATE].to = moment().endOf("month");
      break;
    case DATE_RELATIVE_LAST_7_DAYS:
      search[FILTER_DATE].from = moment().subtract(6).startOf("day");
      search[FILTER_DATE].to = moment().endOf("day");
      break;
    case DATE_RELATIVE_LAST_30_DAYS:
      search[FILTER_DATE].from = moment().subtract(29).startOf("day");
      search[FILTER_DATE].to = moment().endOf("day");
      break;
    case DATE_RELATIVE_EXACT:
      // manipulate from and to into the local timezone
      const from = moment(search[FILTER_DATE].from);
      const to = moment(search[FILTER_DATE].to);
      if (from) {
        search[FILTER_DATE].from = moment(from).add(-from.utcOffset(), "m");
      }
      if (to) {
        search[FILTER_DATE].to = moment(to).add(-to.utcOffset(), "m");
      }
      break;
    default:
      search[FILTER_DATE].relative = null;
      search[FILTER_DATE].to = null;
      search[FILTER_DATE].from = null;
  }
  if (useLocationFilter) {
    // only set the location id if we're still allowed to access it
    const l = locations.find((l) => l.ID === filter.LocationID);
    if (l) {
      search[FILTER_LOCATION] = filter.LocationID;
    }
  } else if (filter.HospitalID) {
    const l = locations.find((l) => l.ID === filter.HospitalID);
    if (l) {
      search[FILTER_LOCATION] = l.Name;
    }
  }
  [
    FILTER_PHYSICIAN,
    FILTER_EXTERNAL_PATIENT_ID,
    FILTER_LAST_NAME,
    FILTER_FIRST_NAME,
    FILTER_PATIENT_TYPE,
    FILTER_OPERATOR,
    FILTER_REF_PHYSICIAN,
    FILTER_VISIT_STATUS,
    FILTER_REPORT_STATUS_PRELIMINARY,
    FILTER_REPORT_STATUS_FINAL,
    FILTER_REPORT_STATUS_CORRECTION,
    FILTER_HIS_VISIT_ID,
    FILTER_HIS_ORDER_ID,
    FILTER_SORT_BY,
    FILTER_SORT_ORDER,
  ].forEach((f) => {
    search[f] = filter[f];
  });
  return search;
};

export const checkReportOperation = (visitID, reportName) => {
  return ApiService.checkReportOperation(visitID, reportName)
    .then((data) => data.response)
    .catch((error) => {
      if (
        error.config &&
        error.response &&
        error.response.data.Code === ErrCodeRequestTimeout
      ) {
        return checkReportOperation(visitID, reportName);
      }
      return Promise.reject(error);
    });
};

export const convertMomentToDate = (momentDate) => {
  return new Date(momentDate.year(), momentDate.month(), momentDate.date());
};

<template>
  <div class="report-icon">
    <img
      src="static/vmax-report.png"
      alt="Vmax report"
      height="20px"
      v-if="isVmax"
    />
    <span :class="icon" v-else></span>
  </div>
</template>

<script>
import "@/assets/img/vmax-report.png";

import {
  REPORT_TYPE_EXTERNAL,
  REPORT_TYPE_OUTPUT,
  REPORT_TYPE_INTERPRETATION,
} from "@/common/constants";

export default {
  name: "ReportIcon",
  props: {
    type: String,
    name: String,
  },
  computed: {
    isVmax() {
      const { type, name } = this;
      return type === REPORT_TYPE_EXTERNAL && name && name.startsWith("Vmax");
    },
    icon() {
      const { type } = this;
      switch (type) {
        case REPORT_TYPE_OUTPUT:
          return "fas fa-file icon-color";
        case REPORT_TYPE_INTERPRETATION:
          return "fab fa-artiq artiq-color";
        case REPORT_TYPE_EXTERNAL:
          return "fas fa-arrow-circle-up icon-color";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.artiq-color {
  color: #0a5487;
}
.icon-color {
  color: #ff7f00 !important;
}
.report-icon {
  min-width: 22px;
  text-align: center;
}
</style>

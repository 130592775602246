var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c(
        "label",
        { staticClass: "small text-muted", attrs: { for: _vm.label } },
        [_vm._v("\n    " + _vm._s(_vm._f("localized")(_vm.label)) + "\n  ")]
      ),
      _vm._v(" "),
      _c("Typeahead", {
        attrs: { options: _vm.options, "initial-value": _vm.initialValue },
        on: {
          input: function ($event) {
            return _vm.$emit("update", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import moment from "moment";

import { DATE_FORMAT } from "./constants";

export default (value) => {
  if (value) {
    return moment.utc(String(value)).format(DATE_FORMAT);
  }
  return "";
};

import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

import { LOGOUT_WITHOUT_REQUEST } from "@/store/actions.type";
import store from "@/store/index";

import VisitList from "@/components/views/visits/VisitList";
import Report from "@/components/views/report/Report";
import Login from "@/components/views/login/Login";
import Settings from "@/components/views/settings/Settings";
import DirectLink from "@/components/views/directlink/DirectLink";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  console.log("ifNotAuthenticated", to, from);
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  if (store.getters.patientMode) {
    next({ name: "patient", params: { patientID: store.getters.patientMode } });
  } else {
    next("/");
  }
};

const ifAuthenticated = (to, from, next) => {
  console.log("ifAuthenticated", to, from);
  if (store.getters.isAuthenticated) {
    // Admin without access right in mobile has only access to settings
    if (
      store.getters.hasAdminRight &&
      !store.getters.hasAccess &&
      to.name !== "settings"
    ) {
      next({
        name: "settings",
        query: { redirect: to.fullPath },
      });
      return;
    }
    next();
    return;
  }
  next({
    name: "login",
    query: { redirect: to.fullPath },
  });
};

const routes = [
  {
    name: "login",
    path: "/login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "visits",
    path: "/",
    component: VisitList,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "patient",
    path: "/patients/:patientID",
    component: VisitList,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "report",
    path: "/visits/:visitID/reports/:reportID",
    component: Report,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "settings",
    path: "/settings",
    component: Settings,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "directLink",
    path: "/quickreport",
    component: DirectLink,
    beforeEnter: ifAuthenticated,
    props: (route) => ({
      Create: route.query.Create,
      PatientID: route.query.PatientId,
      VisitDate: route.query.VisitDate,
      ReportName: route.query.ReportName,
      Edit: route.query.Edit,
    }),
  },
];

let base = "/";
// detect base if running behind reverse proxy
if (window.location.href.indexOf("reviewservice") !== -1) {
  base = "/reviewservice";
}

const router = new VueRouter({
  base,
  routes,
});

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    // ignore errors for logout request
    console.log(error.response);
    if (error.response.status === 401 && !store.getters.isDesktopMode) {
      console.log("redirecting to login page");
      store.dispatch(LOGOUT_WITHOUT_REQUEST).finally(() => {
        router.push("/login").catch((err) => console.log(err));
      });
      // EventBus.$emit(SESSION_TIMEOUT);
    }
    return Promise.reject(error);
  }
);

export default router;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col-12",
      class: {
        selected: _vm.selection.reportID === _vm.report.ID,
        "disabled text-muted": _vm.isReportOperationPending,
      },
      on: { click: _vm.select },
    },
    [
      _c("div", { staticClass: "row pt-1 pb-1" }, [
        _c(
          "div",
          {
            staticClass: "col-1 d-flex justify-content-end align-items-center",
          },
          [
            _vm.isReportOperationPending
              ? _c("Spinner", { attrs: { type: "border", small: "" } })
              : _c("ReportIcon", {
                  style: {
                    cursor: _vm.isReportOperationPending
                      ? "not-allowed"
                      : "pointer",
                  },
                  attrs: { name: _vm.report.Filename, type: _vm.report.Type },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.showReport(_vm.report.ID)
                    },
                  },
                }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
          _c(
            "span",
            {
              style: {
                cursor: _vm.isReportOperationPending
                  ? "not-allowed"
                  : "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.showReport(_vm.report.ID)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.report.Filename) + "\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3 d-flex align-items-center" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("datetime")(_vm.report.LocalDate)) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4 d-flex align-items-center" },
          [
            _c("ReportStatus", {
              attrs: { status: _vm.report.Status, reportType: _vm.report.Type },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";

import ApiService from "@/common/api.service";
import { FETCH_LEVELS } from "@/store/actions.type";
import {
  FETCH_LEVELS_ERROR,
  FETCH_LEVELS_PENDING,
  FETCH_LEVELS_SUCCESS,
  RESET,
} from "@/store/mutations.type";

import { NOTIFICATION_DURATION_ERROR, LEVEL_ORDER } from "@/common/constants";

// initial state
const initialState = () => {
  return {
    loading: false,
    levels: [],
  };
};

// getters
const getters = {
  levels: (state) => state.levels,
  isFetchingLevels: (state) => state.loading,
};

// store last promises to allow cancellation
let fetchLevelsPromise;
// actions
const actions = {
  [FETCH_LEVELS]({ commit }, { visitID }) {
    commit(FETCH_LEVELS_PENDING);
    const promise = ApiService.fetchLevels(visitID);
    fetchLevelsPromise = promise;
    return promise
      .then((data) => {
        if (fetchLevelsPromise !== promise) {
          console.log(`${FETCH_LEVELS}: discarded old result set`);
          // outdated data skip it
          return;
        }
        // sort levels
        data.sort((a, b) => {
          if (a.Type === b.Type) {
            return a.Sequence - b.Sequence;
          }
          return LEVEL_ORDER.indexOf(a.Type) - LEVEL_ORDER.indexOf(b.Type);
        });

        commit(FETCH_LEVELS_SUCCESS, { levels: data });
      })
      .catch((e) => {
        if (fetchLevelsPromise !== promise) {
          console.log(`${FETCH_LEVELS}: discarded old error`);
          // outdated data skip it
          return;
        }
        commit(FETCH_LEVELS_ERROR, e);
        Vue.notify({
          type: "danger",
          duration: NOTIFICATION_DURATION_ERROR,
          text: "Review.View.Messages.3",
        });
      });
  },
};

// mutations
const mutations = {
  [RESET](state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  [FETCH_LEVELS_PENDING](state) {
    console.log("fetching levels");
    state.loading = true;
    // we do not set state.levels to an empty array to avoid flickering of the level area (sidebar)
  },
  [FETCH_LEVELS_SUCCESS](state, { levels }) {
    state.loading = false;
    state.levels = levels;
  },
  [FETCH_LEVELS_ERROR](state, err) {
    console.log("fetching levels failed:", err);
    state.levels = [];
    state.loading = false;
  },
};

export default {
  state: initialState(),
  actions,
  mutations,
  getters,
};

const hisFlags = 0b110000;

const HIS = 0b010000;
const MUST_BE_SENT_AGAIN = 0b100000;

export const isSentToHIS = (reportStatus) => {
  return (reportStatus & hisFlags) > 0;
};

export const HISStatusLabel = (reportStatus) => {
  const his = reportStatus & hisFlags;

  if ((his & MUST_BE_SENT_AGAIN) === MUST_BE_SENT_AGAIN) {
    return "Review.Common.ReportStatus.7";
  }
  if ((his & HIS) === HIS) {
    return "Review.Common.ReportStatus.6";
  }
  return "";
};

<template>
  <b-modal lazy ref="modal" @hide="$emit('hide', $event)">
    <template slot="modal-title">
      <h5 class="modal-title">{{ "Review.View.Visit.4" | localized }}</h5>
    </template>
    <template slot="modal-footer">
      <button
        id="btn-cancel-update-report"
        type="button"
        class="btn btn-sm btn-outline-secondary"
        @click="handleCancel"
      >
        {{ "Review.View.Common.1" | localized }}
      </button>
      <button
        id="btn-confirm-update-report"
        type="button"
        class="btn btn-sm btn-primary"
        @click="handleOK"
      >
        {{ "Review.View.Common.2" | localized }}
      </button>
    </template>
    {{ "Review.View.Visit.1" | localized }}
    {{ `"${selectedReport.Filename}"` }} ?
  </b-modal>
</template>

<script>
import { UPDATE_REPORT } from "@/store/actions.type";

export default {
  name: "ReportModalUpdate",
  computed: {
    selectedVisit() {
      return this.$store.getters.selectedVisit;
    },
    selectedReport() {
      return this.$store.getters.selectedReport;
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    handleOK() {
      // same action regardless of comment type
      const reportName = this.selectedReport.Filename;
      this.$store
        .dispatch(UPDATE_REPORT, {
          visitID: this.selectedVisit.ID,
          reportID: this.selectedReport.ID,
          reportName: reportName,
        })
        .catch((err) => {
          console.log(err);
        });
      // use default "events"
      this.$refs.modal.hide("ok");
    },
    handleCancel() {
      // use default "events"
      this.$refs.modal.hide("cancel");
    },
  },
};
</script>

<style scoped></style>

// ids
export const DEFAULT_USER_ID = "00000000-0000-0000-0000-000000000002";

// features
export const FEATURE_FULL_VIEW = "featureFullView";

// formats
export const DATE_FORMAT = "L";
export const DATE_TIME_FORMAT = "LT";

// date relatives
export const DATE_RELATIVE_TODAY = "today";
export const DATE_RELATIVE_WEEK = "week";
export const DATE_RELATIVE_MONTH = "month";
export const DATE_RELATIVE_LAST_7_DAYS = "last7Days";
export const DATE_RELATIVE_LAST_30_DAYS = "last30Days";
export const DATE_RELATIVE_EXACT = "exact";

// filter identifier
export const FILTER_DATE = "Date";
export const FILTER_PHYSICIAN = "Physician";
export const FILTER_LOCATION = "Location";
export const FILTER_EXTERNAL_PATIENT_ID = "ExternalID";
export const FILTER_LAST_NAME = "LastName";
export const FILTER_FIRST_NAME = "FirstName";
export const FILTER_PATIENT_TYPE = "Type";
export const FILTER_OPERATOR = "Operator";
export const FILTER_REF_PHYSICIAN = "ReferringPhysician";
export const FILTER_VISIT_STATUS = "VisitStatus";
export const FILTER_REPORT_STATUS_PRELIMINARY = "ReportStatusPreliminary";
export const FILTER_REPORT_STATUS_FINAL = "ReportStatusFinal";
export const FILTER_REPORT_STATUS_CORRECTION = "ReportStatusCorrection";
export const FILTER_HIS_VISIT_ID = "HISVisitID";
export const FILTER_HIS_ORDER_ID = "HISOrderID";
export const FILTER_SORT_BY = "SortBy";
export const FILTER_SORT_ORDER = "SortOrder";
export const FILTER_LABELS = {
  [FILTER_DATE]: "Review.View.VisitList.1",
  [FILTER_PHYSICIAN]: "Review.View.ReportSearch.19",
  [FILTER_LOCATION]: "Review.View.ReportSearch.12",
  [FILTER_EXTERNAL_PATIENT_ID]: "Review.View.VisitList.8",
  [FILTER_LAST_NAME]: "Review.View.ReportSearch.2",
  [FILTER_FIRST_NAME]: "Review.View.ReportSearch.3",
  [FILTER_PATIENT_TYPE]: "Review.View.ReportSearch.6",
  [FILTER_OPERATOR]: "Review.View.ReportSearch.14",
  [FILTER_REF_PHYSICIAN]: "Review.View.ReportSearch.18",
  [FILTER_VISIT_STATUS]: "Review.View.VisitList.11",
  [FILTER_REPORT_STATUS_PRELIMINARY]: "Review.View.ReportSearch.15",
  [FILTER_REPORT_STATUS_FINAL]: "Review.View.ReportSearch.16",
  [FILTER_REPORT_STATUS_CORRECTION]: "Review.View.ReportSearch.17",
  [FILTER_HIS_VISIT_ID]: "Review.View.ReportSearch.4",
  [FILTER_HIS_ORDER_ID]: "Review.View.ReportSearch.5",
};

// levels
export const LEVEL_TEST = "Test";
export const LEVEL_PRE = "Pre";
export const LEVEL_POST = "Post";
export const LEVEL_NOT_DEFINED = "__NOT_DEFINED__";
export const LEVEL_PROVBASE = "ProvBase";
export const LEVEL_PROVOCATION = "Provocation";
export const LEVEL_PROVDILATATION = "ProvDilatation";
export const LEVEL_PROVREVIEW = "ProvReview";
export const LEVEL_ORDER = [
  LEVEL_TEST,
  LEVEL_PRE,
  LEVEL_POST,
  LEVEL_NOT_DEFINED,
  LEVEL_PROVBASE,
  LEVEL_PROVOCATION,
  LEVEL_PROVDILATATION,
  LEVEL_PROVREVIEW,
];
export const LEVEL_LANG_MAPPING = {
  [LEVEL_TEST]: "Review.View.Levels.6",
  [LEVEL_PRE]: "Review.View.Levels.7",
  [LEVEL_POST]: "Review.View.Levels.8",
  [LEVEL_NOT_DEFINED]: "Review.View.Levels.9",
  [LEVEL_PROVBASE]: "Review.View.Levels.10",
  [LEVEL_PROVOCATION]: "Review.View.Levels.11",
  [LEVEL_PROVDILATATION]: "Review.View.Levels.12",
  [LEVEL_PROVREVIEW]: "Review.View.Levels.13",
};

// report status
export const REPORT_STATUS_NONE = {
  status: "None",
  label: "", // no label available/needed
};
export const REPORT_STATUS_PRELIMINARY = {
  status: "Preliminary",
  label: "Review.Common.ReportStatus.2",
};
export const REPORT_STATUS_FINAL = {
  status: "Final",
  label: "Review.Common.ReportStatus.3",
};
export const REPORT_STATUS_CORRECTION = {
  status: "Correction",
  label: "Review.Common.ReportStatus.4",
};

// report type
export const REPORT_TYPE_EXTERNAL = "ReportExternal";
export const REPORT_TYPE_OUTPUT = "ReportOutput";
export const REPORT_TYPE_INTERPRETATION = "ReportInterpretation";

// comment status
export const COMMENT_STATUS_OPEN = 0;

// notifications
export const NOTIFICATION_DURATION_ERROR = 30000; // 30 sec

// session/local storage keys
export const REPORT_VIEW_DIVIDERS = "ReportViewDividers";
export const COMMENT_TEMPLATES_STATE = "CommentTemplatesState";

// i18n default language values
export const DEFAULT_LANG = {
  // loading
  "Review.View.Loading.1": "Loading...",
  // Common
  "Review.View.Common.1": "Cancel",
  "Review.View.Common.2": "Confirm",
  // Create Report
  "Review.View.CreateReport.1": "OK",
  "Review.View.CreateReport.2": "Cancel",
  "Review.View.CreateReport.3": "New Report",
  "Review.View.CreateReport.4": "Choose initial status",
  "Review.View.CreateReport.5": "Choose report",
  "Review.View.CreateReport.6": "Choose category",
  "Review.View.CreateReport.7": "All",
  "Review.View.CreateReport.8": "Recommended",
  "Review.View.CreateReport.9": "Clear",
  "Review.View.CreateReport.10": "Interpretation",
  "Review.View.CreateReport.11": "Open report",
  "Review.View.CreateReport.12": "Keep open to be redirected to the report.",
  // Delete Report
  "Review.View.DeleteReport.2": "Delete Report",
  "Review.View.DeleteReport.3": "Do you want to delete the report",
  "Review.View.DeleteReport.4": "Please enter your password to confirm",
  "Review.View.DeleteReport.5": "Cancel",
  "Review.View.DeleteReport.6": "Delete Report",
  // DirectLink
  "Review.View.DirectLink.2": "Invalid parameters",
  "Review.View.DirectLink.3": "Patient not found",
  "Review.View.DirectLink.4":
    "No visits found for this patient on the given date",
  "Review.View.DirectLink.5": "Report not found",
  "Review.View.DirectLink.6": "Could not create or update report",
  // Login
  "Review.View.Login.1": "Login name",
  "Review.View.Login.2": "Login",
  "Review.View.Login.3": "Password",
  "Review.View.Login.5": "Authentication failed",
  "Review.View.Login.8":
    "The user has been locked for {0}sec due to frequent login attempts!",
  "Review.View.Login.9": "Password expired",
  "Review.View.Login.10": "Change Password",
  // Review.View.Login.1XX block is used for change password
  "Review.View.Login.101": "Change",
  "Review.View.Login.102": "Enter current Password",
  "Review.View.Login.103": "Enter new Password",
  "Review.View.Login.104": "Repeat new Password",
  "Review.View.Login.105": "Cancel",
  "Review.View.Login.106":
    "The password could not be changed. Check error log for details.",
  // Review.View.Login.2XX block is used for password policy errors/status
  // PasswordPolicyError.Unique
  "Review.View.Login.201":
    "The password has not been changed - a different password has to be entered",
  // PasswordPolicyError.Empty
  "Review.View.Login.202":
    "The password has not been changed - a password has to be entered",
  // PasswordPolicyError.LengthMin
  "Review.View.Login.203":
    "The password has not been changed - the entered password has to be longer",
  // PasswordPolicyError.LengthMax
  "Review.View.Login.204":
    "The password has not been changed - the entered password is too long",
  // PasswordPolicyError.ComplexLetters
  "Review.View.Login.205":
    "The password has not been changed - it needs to contain more letters",
  // PasswordPolicyError.ComplexUpper
  "Review.View.Login.206":
    "The password has not been changed - it needs to contain more uppercase letters",
  // PasswordPolicyError.ComplexLower
  "Review.View.Login.207":
    "The password has not been changed - it needs to contain more lowercase letters",
  // PasswordPolicyError.ComplexSymbol
  "Review.View.Login.208":
    "The password has not been changed - it needs to contain more number/symbol letters",
  // PasswordPolicyError.ComplexUsername
  "Review.View.Login.209":
    "The password has not been changed - the username is not allowed as part of the password",
  // Levels
  "Review.View.Levels.3": "Visit Levels",
  "Review.View.Levels.6": "Test",
  "Review.View.Levels.7": "Pre",
  "Review.View.Levels.8": "Post",
  "Review.View.Levels.9": "NotDefinedLevel",
  "Review.View.Levels.10": "Base",
  "Review.View.Levels.11": "Prov",
  "Review.View.Levels.12": "Dilat.",
  "Review.View.Levels.13": "Review",
  // Messages
  "Review.View.Messages.5": "Send HIS Report done",
  "Review.View.Messages.6": "Failed to send to HIS",
  "Review.View.Messages.7": "Upload failed",
  "Review.View.Messages.8": "Upload successful",
  "Review.View.Messages.9": "File too large",
  "Review.View.Messages.10": "Overwrite Report",
  "Review.View.Messages.11":
    "Report with same name already exists! Do you want to overwrite existing report?",
  "Review.View.Messages.12": "Overwrite Report",
  "Review.View.Messages.13":
    "Network error, please try again later. Check error log for details.",
  "Review.View.Messages.14": "Settings saved",
  "Review.View.Messages.15":
    "Report is being processed by another user. Please try again later.",
  "Review.View.Messages.16": "Report deleted",
  "Review.View.Messages.17": "Failed to delete report",
  "Review.View.Messages.18": "Report updated",
  "Review.View.Messages.19": "Failed to update report",
  "Review.View.Messages.20": "Report created",
  "Review.View.Messages.21": "Failed to create report",
  "Review.View.Messages.22": "Password changed",
  // Report
  "Review.View.Report.4": "Save",
  "Review.View.Report.5": "Sign",
  "Review.View.Report.6": "Cancel",
  "Review.View.Report.8": "Close",
  "Review.View.Report.10": "Comment saved",
  "Review.View.Report.11": "Comment could not be saved",
  "Review.View.Report.14": "Physician",
  "Review.View.Report.15": "Technician",
  "Review.View.Report.17": "Comment History",
  "Review.View.Report.20": "Auto Interpretation",
  "Review.View.Report.23": "Insert last auto interpretation",
  "Review.View.Report.24": "Templates",
  "Review.View.Report.26": "Technical Comments",
  "Review.View.Report.27": "LOGIC Tree",
  "Review.View.Report.28": "Interpretation",
  "Review.View.Report.29": "PFT Comments",
  "Review.View.Report.30": "MET Events",
  "Review.View.Report.31": "MET End",
  "Review.View.Report.32": "MET Tech Notes",
  "Review.View.Report.39": "No Vmax comments",
  "Review.View.Report.40": "No matches found",
  "Review.View.Report.41": "Insert text from interpretation report",
  "Review.View.Report.42":
    "PFT data characterization support provided by ARTIQ.PFT based off the latest ERS/ATS standards",
  // Report Search
  "Review.View.ReportSearch.2": "Last Name",
  "Review.View.ReportSearch.3": "First Name",
  "Review.View.ReportSearch.4": "HIS Visit ID",
  "Review.View.ReportSearch.5": "HIS Order ID",
  "Review.View.ReportSearch.6": "Patient Type",
  "Review.View.ReportSearch.7": "Patient",
  "Review.View.ReportSearch.8": "Demo Patient",
  "Review.View.ReportSearch.9": "BIO QC",
  "Review.View.ReportSearch.10": "SeS QM validation",
  "Review.View.ReportSearch.11": "ATS/ERS automatic pump",
  "Review.View.ReportSearch.12": "Location",
  "Review.View.ReportSearch.14": "Operator",
  "Review.View.ReportSearch.15": "Report Status Preliminary",
  "Review.View.ReportSearch.16": "Report Status Final",
  "Review.View.ReportSearch.17": "Report Status Correction",
  "Review.View.ReportSearch.18": "Ref. Physician",
  "Review.View.ReportSearch.19": "Physician",
  "Review.View.ReportSearch.20": "Month",
  "Review.View.ReportSearch.21": "Week",
  "Review.View.ReportSearch.22": "Today",
  "Review.View.ReportSearch.24": "Filter Actions",
  "Review.View.ReportSearch.25": "Clear All",
  "Review.View.ReportSearch.26": "Use & hide",
  "Review.View.ReportSearch.27":
    "A filter preset with this name already exists. It will be overwritten when you save the current filter with this name.",
  "Review.View.ReportSearch.28": "Cancel",
  "Review.View.ReportSearch.29": "Failed to delete filter preset.",
  "Review.View.ReportSearch.30": "Load Filter",
  "Review.View.ReportSearch.32": "No filters saved yet.",
  "Review.View.ReportSearch.33": "Save Filter",
  "Review.View.ReportSearch.34": "Filter preset name",
  "Review.View.ReportSearch.35": "Failed to save filter preset.",
  "Review.View.ReportSearch.36": "Filter preset saved.",
  "Review.View.ReportSearch.38": "Save Filter Preset",
  "Review.View.ReportSearch.39": "Filter Presets",
  "Review.View.ReportSearch.40": "Should the filter preset really be deleted?",
  "Review.View.ReportSearch.41": "Delete Filter Preset",
  "Review.View.ReportSearch.42": "Batch Mode",
  "Review.View.ReportSearch.45": "To",
  "Review.View.ReportSearch.46": "No matches found",
  "Review.View.ReportSearch.47": "Exact",
  "Review.View.ReportSearch.48": "Sort by",
  "Review.View.ReportSearch.49": "Ascending",
  "Review.View.ReportSearch.50": "Descending",
  "Review.View.ReportSearch.51": "Order",
  "Review.View.ReportSearch.52": "Last 7 Days",
  "Review.View.ReportSearch.53": "Last 30 Days",
  "Review.View.ReportSearch.54": "Custom",
  // Report Status
  "Review.Common.ReportStatus.2": "Preliminary",
  "Review.Common.ReportStatus.3": "Final",
  "Review.Common.ReportStatus.4": "Correction",
  "Review.Common.ReportStatus.6": "HIS",
  "Review.Common.ReportStatus.7": "must be sent to HIS again",
  "Review.Common.ReportStatus.8": "Modified",
  "Review.Common.ReportStatus.9": "Not sent",
  "Review.Common.ReportStatus.10": "Sent",
  "Review.Common.ReportStatus.11": "Changed (Not sent + Modified)",
  // SetVisitStatus
  "Review.View.SetVisitStatus.1": "Set visit status",
  "Review.View.SetVisitStatus.2":
    "Changing the visit status will lock the visit and disable the automatic visit status workflow until it is manually unlocked again. ",
  "Review.View.SetVisitStatus.5":
    "The manually changed visit status will be unlocked and the automatic visit status workflow will be turned on again.",
  "Review.View.SetVisitStatus.7": "Unlock",
  "Review.View.SetVisitStatus.8": "Status Locked",
  // Sign
  "Review.View.Sign.1": "Signing",
  "Review.View.Sign.2": "Sign",
  "Review.View.Sign.3": "Cancel",
  "Review.View.Sign.7": "Please select the state it should be saved in",
  "Review.View.Sign.8": "The selected report will be saved to the database",
  "Review.View.Sign.9": "Signing as",
  // Visit
  "Review.View.Visit.1": "Do you want to update this report?", //Todo: move to Review.View.Messages
  "Review.View.Visit.2": "Report updated", //Todo: remove and use Review.View.Messages.18
  "Review.View.Visit.3": "Could not create or update report", //Todo: remove
  "Review.View.Visit.4": "Update",
  "Review.View.Visit.6": "Delete",
  "Review.View.Visit.7": "New",
  "Review.View.Visit.8": "Send",
  "Review.View.Visit.11": "visit(s) found",
  "Review.View.Visit.12": "filters active",
  "Review.View.Visit.13": "Upload",
  "Review.View.Visit.14": "Report Actions",
  "Review.View.Visit.15": "Patient Actions",
  "Review.View.Visit.16": "Patient Details",
  "Review.View.Visit.17": "Full View",
  // VisitList
  "Review.View.VisitList.1": "Date",
  "Review.View.VisitList.3": "Name",
  "Review.View.VisitList.6": "Date of Birth",
  "Review.View.VisitList.8": "Patient ID",
  "Review.View.VisitList.9": "HIS Visit ID",
  "Review.View.VisitList.10": "HIS Order ID",
  "Review.View.VisitList.11": "Visit Status",
  // Settings
  "Review.View.Administration.1": "Administration Settings", // TODO: remove
  "Review.View.Administration.2": "General",
  "Review.View.Administration.3": "HIS Status sequence",
  "Review.View.Administration.4": "Timeouts (in minutes)",
  "Review.View.Administration.5": "Session timeout period", // TODO: remove
  "Review.View.Administration.6": "minutes",
  "Review.View.Administration.7": "Auto logout period",
  "Review.View.Administration.8": "Cancel",
  "Review.View.Administration.9": "OK",
  "Review.View.Administration.10": "HIS",
  "Review.View.Administration.11":
    'Sent report to HIS automatically by clicking "Sign"',
  "Review.View.Administration.12":
    "Allow sending reports to HIS without a signed comment",
  "Review.View.Administration.13":
    "Allow signing reports without a physician comment",
  "Review.View.Administration.14": "Allow uploading of external reports (PDF)",
  "Review.View.Administration.15": "Allow commenting of external reports (PDF)",
  "Review.View.Administration.16": "Allow signature of technician comments",
  "Review.View.Administration.17": "Preliminary - Final - Correction",
  "Review.View.Administration.18": "Final - Correction",
  "Review.View.Administration.20": "HIS connection is activated",
  "Review.View.Administration.21": "Filter",
  "Review.View.Administration.22":
    "This section lets you customize how the filters in the visit list are displayed. Use Drag'n'Drop to change the order or visibility of the filters.",
  "Review.View.Administration.23": "Visible",
  "Review.View.Administration.24":
    "You can select up to 4 filters, that are always visible.",
  "Review.View.Administration.25": "Hidden",
  "Review.View.Administration.26": "These filters are hidden by default.",
  "Review.View.Administration.27": "Comment template filter method",
  "Review.View.Administration.28": "Type ahead",
  "Review.View.Administration.29": "Starts with",
  // Measurement Descriptions
  // Measurement Description keys are build dynamically
  "MeasurementDescription.1": "Spiro",
  "MeasurementDescription.3": "Body",
  "MeasurementDescription.4": "Diff SB",
  "MeasurementDescription.6": "Comp",
  "MeasurementDescription.7": "Rocc",
  "MeasurementDescription.12": "CPET",
  "MeasurementDescription.13": "ABG",
  "MeasurementDescription.14": "Cardiology",
  "MeasurementDescription.15": "Par 1",
  "MeasurementDescription.16": "Usr Par",
  "MeasurementDescription.18": "IOS",
  "MeasurementDescription.19": "Capno",
  "MeasurementDescription.20": "BabyBody",
  "MeasurementDescription.21": "Baby/RC",
  "MeasurementDescription.22": "Tidal",
  "MeasurementDescription.23": "User prog.",
  "MeasurementDescription.25": "Rhino",
  "MeasurementDescription.33": "Par 2",
  "MeasurementDescription.34": "Par 3",
  "MeasurementDescription.35": "Par 4",
  "MeasurementDescription.36": "Inhaler",
  "MeasurementDescription.37": "Par 6",
  "MeasurementDescription.39": "Squeeze",
  "MeasurementDescription.43": "HIRES",
  "MeasurementDescription.44": "ECG-Resting",
  "MeasurementDescription.45": "ECG-Stress",
  "MeasurementDescription.46": "ECG2012_REST",
  "MeasurementDescription.47": "RECG",
  "MeasurementDescription.53": "Sleep",
  "MeasurementDescription.57": "RVSQU",
  "MeasurementDescription.59": "Diff SB IB",
  "MeasurementDescription.62": "N2 Washout",
  "MeasurementDescription.63": "Diff SB NO",
  "MeasurementDescription.65": "MIP / MEP",
  "MeasurementDescription.66": "P 0.1",
  "MeasurementDescription.67": "SNIP",
  "MeasurementDescription.68": "FRC Rb",
  "MeasurementDescription.69": "Diff Rb",
  "MeasurementDescription.70": "Provo",
  "MeasurementDescription.71": "SB O2",
  "MeasurementDescription.72": "Ex Temp",
  "MeasurementDescription.73": "6 MWT",
  "MeasurementDescription.74": "SkinFold",
  "MeasurementDescription.199": "VMAX",
  "MeasurementDescription.301": "SVC",
  "MeasurementDescription.302": "Transmural F/V",
  "MeasurementDescription.303": "Body",
  "MeasurementDescription.399": "VMAX",
  "MeasurementDescription.499": "VMAX",
  "MeasurementDescription.601": "Compl. Dyn",
  "MeasurementDescription.602": "Compl. Stat",
  "MeasurementDescription.603": "Compl. Pos",
  "MeasurementDescription.1000": "Calibration",
  "MeasurementDescription.1201": "BxB",
  "MeasurementDescription.1202": "ExDiffusion",
  "MeasurementDescription.1203": "C.O.",
  "MeasurementDescription.1204": "EFVL",
  "MeasurementDescription.1206": "CurveFit",
  "MeasurementDescription.1210": "P 0.1",
  "MeasurementDescription.1211": "Poes",
  "MeasurementDescription.1212": "REE",
  "MeasurementDescription.1215": "Slope",
  "MeasurementDescription.1216": "EOV",
  "MeasurementDescription.1401": "Leads",
  "MeasurementDescription.1402": "Stress",
  "MeasurementDescription.1403": "HiRes",
  "MeasurementDescription.1801": "Vt mean v.",
  "MeasurementDescription.1802": "Vt In-param",
  "MeasurementDescription.1803": "Vt Ex-param",
  "MeasurementDescription.1804": "VCmax In-param",
  "MeasurementDescription.1805": "VCmax Ex-param",
  "MeasurementDescription.1806": "FOT",
  "MeasurementDescription.1901": "Capno MB",
  "MeasurementDescription.1902": "Capno SB",
  "MeasurementDescription.1903": "Emphysema diagn.",
  "MeasurementDescription.1904": "Hypervent.",
  "MeasurementDescription.2001": "Tidal",
  "MeasurementDescription.2301": "Partial FVC",
  "MeasurementDescription.2302": "InCheck",
  "MeasurementDescription.2303": "TM part/max flowvolume",
  "MeasurementDescription.2304": "User prog. 4",
  "MeasurementDescription.2305": "User prog. 5",
  "MeasurementDescription.2306": "User prog. 6",
  "MeasurementDescription.2501": "Rhino L",
  "MeasurementDescription.2502": "Rhino R",
  "MeasurementDescription.5999": "VMAX",
  "MeasurementDescription.6299": "VMAX",
  "MeasurementDescription.6501": "MIP",
  "MeasurementDescription.6502": "MEP",
  "MeasurementDescription.6599": "VMAX",
  "MeasurementDescription.6601": "P 0.1 Rest",
  "MeasurementDescription.6602": "P 0.1 Max",
  "MeasurementDescription.6603": "P 0.1 CO2-1",
  "MeasurementDescription.6604": "P 0.1 CO2-2",
  "MeasurementDescription.6699": "VMAX",
  "MeasurementDescription.6701": "Sniff",
  "MeasurementDescription.6801": "FRC Rb",
  "MeasurementDescription.6901": "Diff Rb",
  "MeasurementDescription.7099": "VMAX",
  "MeasurementDescription.10752": "Patient",
  "MeasurementDescription.10753": "Ambient data",
  "MeasurementDescription.10754": "Axis description",
  "MeasurementDescription.10755": "Misc",
  "MeasurementDescription.10756": "Info",
  "MeasurementDescription.65537": "SVC",
  "MeasurementDescription.65538": "FVC",
  "MeasurementDescription.65539": "MVV",
  "MeasurementDescription.65540": "SPIR",
  "MeasurementDescription.65656": "Specific resistance",
  "MeasurementDescription.65657": "Lung volumes",
  "MeasurementDescription.65658": "SVC",
  "MeasurementDescription.65659": "Spirometry",
  "MeasurementDescription.65736": "Tidal",
  "MeasurementDescription.65936": "Rocc",
  "MeasurementDescription.66036": "Rhino",
  "MeasurementDescription.66136": "Compl. Pos",
  "MeasurementDescription.66137": "Compl. Dyn",
  "MeasurementDescription.66138": "Compl. Stat",
  "MeasurementDescription.66236": "IOS",
  "MeasurementDescription.66237": "IOS VC",
  "MeasurementDescription.66336": "Vol Cal",
  "MeasurementDescription.66337": "Vol Cal",
  "MeasurementDescription.66338": "Vol Ver",
  "MeasurementDescription.66339": "Fill Sensor",
  "MeasurementDescription.66340": "Auto",
  "MeasurementDescription.66436": "Raw",
  "MeasurementDescription.66437": "FRC",
  "MeasurementDescription.66536": "RTC",
  "MeasurementDescription.66537": "RV Rapid Thoracic Compression",
  "MeasurementDescription.66636": "Single Occl",
  "MeasurementDescription.66637": "Double Occl",
  "MeasurementDescription.66736": "SVC",
  "MeasurementDescription.66737": "FRC N2",
  "MeasurementDescription.66836": "O2/CO2",
  "MeasurementDescription.66837": "O2/CO2",
  "MeasurementDescription.66838": "CO/CH4",
  "MeasurementDescription.66839": "CO/CH4/C2H2",
  "MeasurementDescription.66840": "O2/CO2",
  "MeasurementDescription.66841": "O2",
  "MeasurementDescription.66842": "CO/HE",
  "MeasurementDescription.66843": "HE",
  "MeasurementDescription.66844": "CO/CH4",
  "MeasurementDescription.66845": "CO/CH4/C2H2",
  "MeasurementDescription.66846": "All Gases",
  "MeasurementDescription.66848": "O2/CO2/CO/CH4/C2H2",
  "MeasurementDescription.66849": "O2/CO2/CO/CH4",
  "MeasurementDescription.66850": "O2/CO2",
  "MeasurementDescription.66851": "CO/CH4/C2H2",
  "MeasurementDescription.66852": "CO/CH4",
  "MeasurementDescription.66936": "Box Cal.",
  "MeasurementDescription.66937": "Tau Ver.",
  "MeasurementDescription.66938": "PB Cal.",
  "MeasurementDescription.67036": "CPET",
  "MeasurementDescription.67037": "Rest",
  "MeasurementDescription.67038": "Exercise",
  "MeasurementDescription.67039": "BxB",
  "MeasurementDescription.67040": "Mix Ch.",
  "MeasurementDescription.67041": "Canopy",
  "MeasurementDescription.67042": "Vent. BxB",
  "MeasurementDescription.67043": "Vent. Mix. Ch.",
  "MeasurementDescription.67044": "Hi/Lo FiO2",
  "MeasurementDescription.67045": "Resp. Drv",
  "MeasurementDescription.67056": "POes",
  "MeasurementDescription.67057": "OxDelta",
  "MeasurementDescription.67136": "MIP",
  "MeasurementDescription.67137": "MEP",
  "MeasurementDescription.67236": "Diff SB RT",
  "MeasurementDescription.67237": "Diff SB IB",
  "MeasurementDescription.67238": "Diff SB CO/He",
  "MeasurementDescription.67336": "P 0.1 Rest",
  "MeasurementDescription.67337": "P 0.1 Max",
  "MeasurementDescription.67338": "P 0.1 CO2 1",
  "MeasurementDescription.67339": "P 0.1 CO2 2",
  "MeasurementDescription.67436": "SNIP",
  "MeasurementDescription.67536": "Diff SB NO",
  "MeasurementDescription.67636": "Provo",
  "MeasurementDescription.67736": "FRC Rb",
  "MeasurementDescription.67836": "Diff Rb",
  "MeasurementDescription.67936": "SB O2",
  "MeasurementDescription.68036": "Ex Temp",
  "MeasurementDescription.68137": "PFT",
  "MeasurementDescription.68138": "CPET",
  "MeasurementDescription.68236": "ECG-Stress",
  "MeasurementDescription.68237": "ECG-Resting",
  "MeasurementDescription.68238": "HRes ECG",
  "MeasurementDescription.131172": "Sq Complete",
  "MeasurementDescription.131173": "Sq Standard",
  "MeasurementDescription.131174": "Sq Linked (Research only)",
  "MeasurementDescription.131175": "Sq Imported",
  "MeasurementDescription.131179": "Body Separated (Research only)",
  "MeasurementDescription.131972": "Multi Raw",
  "MeasurementDescription.131973": "Multi FRC",
  "MeasurementDescription.131974": "Multi Test",
  "MeasurementDescription.131975": "Single Test",
};

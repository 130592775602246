// polyfill's
import "core-js/stable";
import "regenerator-runtime/runtime";

import moment from "moment";
import Vue from "vue";
import VueSplit from "vue-split-panel";
import Notifications from "vue-notification";
import VCalendar from "v-calendar";
import {
  ModalPlugin,
  DropdownPlugin,
  TooltipPlugin,
  ListGroupPlugin,
  InputGroupPlugin,
} from "bootstrap-vue";
import { mobile, desktop } from "@/bootstrap";
import { library, dom } from "@fortawesome/fontawesome-svg-core";

import "@/main.scss";
import { faArtiq } from "@/assets/icons";
import App from "@/App";

import store from "@/store";
import router from "@/router/router";

import ApiService from "@/common/api.service";
import date from "@/common/date.filter";
import datetime from "@/common/datetime.filter";
import time from "@/common/time.filter";
import localized from "@/common/localized.filter";
import placeholder from "@/common/placeholder.filter";
import { FETCH_VERSION } from "@/store/actions.type";

import PatientName from "@/components/PatientName";

// custom ArtiQ fa icon
library.add(faArtiq);
dom.watch();

// register functional components
Vue.component("PatientName", PatientName);

// set moment format to browser locale
moment.locale(navigator.language);

Vue.use(DropdownPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(InputGroupPlugin);
Vue.use(VueSplit);
Vue.use(Notifications);
Vue.use(VCalendar);

// filters
Vue.filter("date", date);
Vue.filter("datetime", datetime);
Vue.filter("localized", localized);
Vue.filter("placeholder", placeholder);
Vue.filter("time", time);

// init api service
ApiService.setHeader();

// fetch version
store.dispatch(FETCH_VERSION);

let pr;
// detect start mode
if (
  window.sesbound || // CEF based wrapper
  (window.chrome && window.chrome.webview) // WebView2 based wrapper
) {
  pr = desktop();
} else {
  pr = mobile();
}

pr.finally(() => {
  new Vue({
    el: "#app",
    router,
    store,
    components: {
      App,
    },
    render: (h) => h(App),
  });
});

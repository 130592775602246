var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      attrs: {
        id: "dropdown-sort",
        size: "sm",
        "no-caret": "",
        variant: "outline-secondary",
      },
    },
    [
      _c(
        "template",
        { slot: "button-content" },
        [
          _vm.sortBy === "LocalDate"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.1")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortBy === "LastName"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.3")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortBy === "Birthdate"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.6")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortBy === "ExternalID"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.8")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortBy === "HISOrderID"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.10")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortBy === "HISVisitID"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.9")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortBy === "ReviewStatus"
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("localized")("Review.View.VisitList.11")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.sortAscending
            ? _c("i", { staticClass: "fas fa-sort-amount-up" })
            : _c("i", { staticClass: "fas fa-sort-amount-down" }),
        ],
        2
      ),
      _vm._v(" "),
      _c("b-dropdown-header", { staticClass: "dropdown-group-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("localized")("Review.View.ReportSearch.51")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-ascending" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortOrder("Ascending")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              _vm.sortAscending
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                _vm._s(_vm._f("localized")("Review.View.ReportSearch.49"))
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item-btn",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-descending" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortOrder("Descending")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              !_vm.sortAscending
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                _vm._s(_vm._f("localized")("Review.View.ReportSearch.50"))
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-dropdown-divider"),
      _vm._v(" "),
      _c("b-dropdown-header", { staticClass: "dropdown-group-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("localized")("Review.View.ReportSearch.48")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-date" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortBy("LocalDate")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              _vm.sortBy === "LocalDate"
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(_vm._s(_vm._f("localized")("Review.View.VisitList.1"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-name" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortBy("LastName")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              _vm.sortBy === "LastName"
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(_vm._s(_vm._f("localized")("Review.View.VisitList.3"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-external-id" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortBy("ExternalID")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              _vm.sortBy === "ExternalID"
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(_vm._s(_vm._f("localized")("Review.View.VisitList.8"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-birthdate" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortBy("Birthdate")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              _vm.sortBy === "Birthdate"
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(_vm._s(_vm._f("localized")("Review.View.VisitList.6"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isHISAvailable
        ? [
            _c(
              "b-dropdown-item",
              {
                staticClass: "dropdown-selectable-item",
                attrs: { id: "btn-sort-his-order-id" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.setSortBy("HISOrderID")
                  },
                },
              },
              [
                _c("div", { staticClass: "d-flex dropdown-item-content" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex dropdown-item-check-wrapper" },
                    [
                      _vm.sortBy === "HISOrderID"
                        ? _c("i", { staticClass: "fas fa-check" })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("localized")("Review.View.VisitList.10"))
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-dropdown-item",
              {
                staticClass: "dropdown-selectable-item",
                attrs: { id: "btn-sort-his-visit-id" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.setSortBy("HISVisitID")
                  },
                },
              },
              [
                _c("div", { staticClass: "d-flex dropdown-item-content" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex dropdown-item-check-wrapper" },
                    [
                      _vm.sortBy === "HISVisitID"
                        ? _c("i", { staticClass: "fas fa-check" })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("localized")("Review.View.VisitList.9"))
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "dropdown-selectable-item",
          attrs: { id: "btn-sort-review-status" },
          nativeOn: {
            click: function ($event) {
              return _vm.setSortBy("ReviewStatus")
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex dropdown-item-content" }, [
            _c("div", { staticClass: "d-flex dropdown-item-check-wrapper" }, [
              _vm.sortBy === "ReviewStatus"
                ? _c("i", { staticClass: "fas fa-check" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(_vm._s(_vm._f("localized")("Review.View.VisitList.11"))),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12" },
    _vm._l(_vm.reports, function (report) {
      return _c(
        "div",
        { key: report.ID, staticClass: "row bg-white report-line" },
        [
          _c("ReportListItem", {
            attrs: {
              visitID: _vm.visitID,
              report: report,
              onSelect: _vm.onSelect,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
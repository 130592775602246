<template>
  <div>
    <label :for="label" class="small text-muted">
      {{ label | localized }}
    </label>
    <select
      :id="label"
      v-model="proxiedValue"
      class="form-control form-control-sm"
    >
      <option value="--"></option>
      <option v-for="(option, oidx) in options" :key="oidx" :value="option.ID">
        {{ option.Name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "FilterSelection",
  props: {
    label: String,
    initialValue: String,
    options: Array,
  },
  computed: {
    proxiedValue: {
      get() {
        return this.initialValue;
      },
      set(val) {
        // override "empty" indicator with null
        if (val === "--") {
          val = null;
        }
        this.$emit("update", val);
      },
    },
  },
};
</script>

<style type="scss" scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "bg-white d-flex flex-column",
                style: { flex: "1 1 0% !important", minHeight: 0 },
              },
              [
                _c("div", { staticClass: "container-fluid" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row pt-2 border-bottom",
                      staticStyle: { "border-width": "2px !important" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-10",
                          staticStyle: { "min-height": "35px" },
                        },
                        [
                          _vm.selectedVisit && !_vm.loading
                            ? _c("ReportTabs", {
                                attrs: { reports: _vm.selectedVisit.Reports },
                                on: {
                                  "new-report": _vm.handleNewReport,
                                  "select-report": _vm.handleSelectReport,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-2 d-flex justify-content-end align-items-end",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pb-1" },
                            [
                              _c("BatchControls", {
                                attrs: { disabled: _vm.loading },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { style: { flex: "1 1 0% !important", minHeight: 0 } },
                  [
                    _c(
                      "div",
                      { staticClass: "h-100" },
                      [
                        _c(
                          "Split",
                          {
                            staticClass: "h-100",
                            on: {
                              onDragStart: _vm.onDragStart,
                              onDragEnd: _vm.onDragEndVertical,
                            },
                          },
                          [
                            _c(
                              "SplitArea",
                              { attrs: { size: _vm.split.pdf, minSize: 200 } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-100 w-100 position-relative",
                                  },
                                  [
                                    _vm.selectedReport &&
                                    _vm.pendingReportNamesBySelectedVisit[
                                      _vm.selectedReport.Filename
                                    ]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "h-100 w-100 position-absolute bg-light d-flex justify-content-center align-items-center",
                                            staticStyle: {
                                              "z-index": "11",
                                              opacity: "0.4",
                                            },
                                          },
                                          [
                                            _c("Spinner", {
                                              attrs: { type: "border" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("iframe", {
                                      ref: "pdfViewer",
                                      staticClass: "w-100 h-100",
                                      style: _vm.iframeStyle,
                                      attrs: {
                                        id: "embedded-viewer",
                                        src: "static/web/viewer.html#zoom=auto",
                                      },
                                      on: { load: _vm.iframeLoaded },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "SplitArea",
                              {
                                staticClass: "h-100 bg-light",
                                attrs: {
                                  size: _vm.split.commentArea,
                                  minSize: 480,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "h-100 d-flex flex-column" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "container-fluid",
                                        staticStyle: { "min-height": "60px" },
                                      },
                                      [
                                        _vm.selectedVisit
                                          ? _c(
                                              "div",
                                              { staticClass: "row pb-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "small text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "localized"
                                                              )(
                                                                "Review.View.VisitList.1"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm._f("datetime")(
                                                            _vm.selectedVisit
                                                              .LocalDate
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "small text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "localized"
                                                              )(
                                                                "Review.View.VisitList.3"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("PatientName", {
                                                      attrs: {
                                                        firstName:
                                                          _vm.selectedVisit
                                                            .Patient.FirstName,
                                                        middleName:
                                                          _vm.selectedVisit
                                                            .Patient.MiddleName,
                                                        lastName:
                                                          _vm.selectedVisit
                                                            .Patient.LastName,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "small text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "localized"
                                                              )(
                                                                "Review.View.VisitList.8"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.selectedVisit
                                                            .Patient.ExternalID
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "small text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "localized"
                                                              )(
                                                                "Review.View.VisitList.11"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    !_vm.isVisitOperationPending
                                                      ? [
                                                          _vm.isFreezeEnabled &&
                                                          _vm.hasFreezeRight
                                                            ? _c(
                                                                "VisitStatusFreeze",
                                                                {
                                                                  attrs: {
                                                                    visit:
                                                                      _vm.selectedVisit,
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                "VisitStatus",
                                                                {
                                                                  attrs: {
                                                                    visitID:
                                                                      _vm
                                                                        .selectedVisit
                                                                        .ID,
                                                                    freeze:
                                                                      _vm
                                                                        .selectedVisit
                                                                        .ReviewStatusFreeze,
                                                                    status:
                                                                      _vm
                                                                        .selectedVisit
                                                                        .ReviewStatus,
                                                                  },
                                                                }
                                                              ),
                                                        ]
                                                      : _c("Spinner", {
                                                          attrs: {
                                                            type: "border",
                                                            small: "",
                                                          },
                                                        }),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isCommentingAllowed
                                      ? _c(
                                          "Split",
                                          {
                                            staticClass: "h-100 bg-white",
                                            attrs: { direction: "vertical" },
                                            on: {
                                              onDragEnd:
                                                _vm.onDragEndHorizontal,
                                            },
                                          },
                                          [
                                            _c(
                                              "SplitArea",
                                              {
                                                staticStyle: {
                                                  "overflow-y": "hidden",
                                                },
                                                attrs: {
                                                  size: _vm.split.editor,
                                                  minSize: 200,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "h-100 d-flex flex-column",
                                                    style: {
                                                      flex: "1 1 0% !important",
                                                      minHeight: 0,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "40px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex bg-light",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "p-2 w-100 text-center rounded-top comment-tab",
                                                                class: {
                                                                  active:
                                                                    _vm.selection ===
                                                                    "physician",
                                                                },
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                  overflow:
                                                                    "hidden",
                                                                  "text-overflow":
                                                                    "ellipsis",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.editPhysician,
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "fas fa-user-md mr-1",
                                                                }),
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "localized"
                                                                      )(
                                                                        "Review.View.Report.14"
                                                                      )
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "p-2 w-100 text-center rounded-top comment-tab",
                                                                class: {
                                                                  active:
                                                                    _vm.selection ===
                                                                    "technician",
                                                                },
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                  overflow:
                                                                    "hidden",
                                                                  "text-overflow":
                                                                    "ellipsis",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.editTechnician,
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "fas fa-user-nurse mr-1",
                                                                }),
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "localized"
                                                                      )(
                                                                        "Review.View.Report.15"
                                                                      )
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.selection ===
                                                    "physician"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-grow-1 flex-shrink-1 position-relative",
                                                            staticStyle: {
                                                              height:
                                                                "calc(100% - 80px) !important",
                                                            },
                                                          },
                                                          [
                                                            !_vm.loading
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h-100 p-3",
                                                                  },
                                                                  [
                                                                    _vm.selectedVisit
                                                                      ? _c(
                                                                          "Editor",
                                                                          {
                                                                            key:
                                                                              _vm
                                                                                .selectedVisit
                                                                                .ID,
                                                                            attrs: {
                                                                              content:
                                                                                _vm
                                                                                  .activePhysicianComment
                                                                                  .Text,
                                                                              "comment-templates":
                                                                                _vm.commentTemplates,
                                                                              "auto-interpretations":
                                                                                _vm.autoInterpretations,
                                                                              interpretations:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              input: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.updatePhysicianComment(
                                                                                  $event
                                                                                )
                                                                              },
                                                                              "artiq-inserted":
                                                                                _vm.handleArtiQInserted,
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h-100 p-3 d-flex align-items-center justify-content-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Spinner",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "grow",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-grow-1 flex-shrink-1 position-relative",
                                                            staticStyle: {
                                                              height:
                                                                "calc(100% - 80px) !important",
                                                              "overflow-y":
                                                                "scroll",
                                                            },
                                                          },
                                                          [
                                                            !_vm.loading
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h-100 p-3",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.activeTechnicianComments,
                                                                    function (
                                                                      c,
                                                                      idx
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: idx,
                                                                          staticClass:
                                                                            "d-flex mb-4",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "small text-right pr-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "localized"
                                                                                        )(
                                                                                          _vm
                                                                                            .levelLabels[
                                                                                            c
                                                                                              .levelType
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                " +
                                                                                      _vm._s(
                                                                                        c.levelSequence
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "date"
                                                                                    )(
                                                                                      c.LocalDate
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    c.Author
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w-100",
                                                                              staticStyle: {
                                                                                "min-width":
                                                                                  "0",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "Editor",
                                                                                {
                                                                                  key:
                                                                                    c.LinkID,
                                                                                  attrs: {
                                                                                    height: 190,
                                                                                    id: `editor-${c.LinkID}`,
                                                                                    levelID:
                                                                                      c.LinkID,
                                                                                    content:
                                                                                      c.Text,
                                                                                    "comment-templates":
                                                                                      _vm.commentTemplates,
                                                                                  },
                                                                                  on: {
                                                                                    input: function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateTechnicianComment(
                                                                                        idx,
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h-100 p-3 d-flex align-items-center justify-content-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Spinner",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "grow",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "w-100 text-right bg-white",
                                                        staticStyle: {
                                                          height: "40px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "container-fluid",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col w-100",
                                                                  },
                                                                  [
                                                                    _vm.showTestReview
                                                                      ? _c(
                                                                          "TestReview"
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-sm btn-outline-secondary",
                                                                        attrs: {
                                                                          id:
                                                                            "btn-save-report",
                                                                          disabled:
                                                                            _vm.isSaveButtonDisabled,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.onSave,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.commentOperationPending
                                                                          ? _c(
                                                                              "Spinner",
                                                                              {
                                                                                attrs: {
                                                                                  type:
                                                                                    "border",
                                                                                  color:
                                                                                    "black",
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          "\n                                " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "localized"
                                                                              )(
                                                                                "Review.View.Report.4"
                                                                              )
                                                                            ) +
                                                                            "\n                              "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm.showSignButton
                                                                      ? _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-sm btn-outline-secondary",
                                                                            attrs: {
                                                                              id:
                                                                                "btn-sign-report",
                                                                              disabled:
                                                                                _vm.isSignButtonDisabled,
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.onSign,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "localized"
                                                                                  )(
                                                                                    "Review.View.Report.5"
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn ml-2 btn-sm btn-outline-secondary",
                                                                        on: {
                                                                          click:
                                                                            _vm.onCancel,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.isBatchMode
                                                                          ? [
                                                                              _vm._v(
                                                                                "\n                                  " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "localized"
                                                                                    )(
                                                                                      "Review.View.Report.7"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                "
                                                                              ),
                                                                            ]
                                                                          : [
                                                                              _vm._v(
                                                                                "\n                                  " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "localized"
                                                                                    )(
                                                                                      "Review.View.Report.8"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                "
                                                                              ),
                                                                            ],
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "SplitArea",
                                              {
                                                attrs: {
                                                  size:
                                                    _vm.split.commentHistory,
                                                  minSize: 50,
                                                },
                                              },
                                              [
                                                !_vm.loading
                                                  ? _c("CommentHistory", {
                                                      attrs: {
                                                        comments: _vm.comments,
                                                        showOpenTechnicianComments:
                                                          _vm.selection ===
                                                          "physician",
                                                        activeTechnicianComments:
                                                          _vm.activeTechnicianComments,
                                                      },
                                                    })
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h-100 p-3 d-flex align-items-center justify-content-center",
                                                      },
                                                      [
                                                        _c("Spinner", {
                                                          attrs: {
                                                            type: "grow",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-bottom":
                                                  "8px solid #eee",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "container-fluid bg-white",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col w-100 pt-2 pb-2 text-right",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn ml-2 btn-sm btn-outline-secondary",
                                                              on: {
                                                                click:
                                                                  _vm.onCancel,
                                                              },
                                                            },
                                                            [
                                                              _vm.isBatchMode
                                                                ? [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "localized"
                                                                          )(
                                                                            "Review.View.Report.7"
                                                                          )
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "localized"
                                                                          )(
                                                                            "Review.View.Report.8"
                                                                          )
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ],
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("CommentHistory", {
                                            staticClass: "flex-grow-1 bg-white",
                                            staticStyle: {
                                              "overflow-y": "scroll",
                                            },
                                            attrs: {
                                              comments: _vm.comments,
                                              showOpenTechnicianComments: false,
                                              activeTechnicianComments:
                                                _vm.activeTechnicianComments,
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedReport
                      ? [
                          _c("ReportModalCreate", {
                            ref: "createReportModal",
                            on: { "report-created": _vm.handleReportCreated },
                          }),
                          _vm._v(" "),
                          _c("ReportModalUpdate", {
                            ref: "updateReportModal",
                            on: { hide: _vm.navigate },
                          }),
                          _vm._v(" "),
                          _c("ReportModalSignPhysician", {
                            ref: "physicianSignReportModal",
                            attrs: { comment: _vm.activePhysicianComment.Text },
                            on: { hide: _vm.onPhysicianSignReportModalHide },
                          }),
                          _vm._v(" "),
                          _c("ReportModalSignTechnician", {
                            ref: "technicianSignReportModal",
                            attrs: { comments: _vm.activeTechnicianComments },
                            on: { hide: _vm.onTechnicianSignReportModalHide },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Base64 } from "js-base64";

import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import {
  COMMENT_TEMPLATES_STATE,
  DEFAULT_USER_ID,
  REPORT_VIEW_DIVIDERS,
} from "@/common/constants";
import { RESET, SET_TOKEN } from "@/store/mutations.type";
import {
  AUTHENTICATE,
  INIT_COMPLETE,
  LOGOUT,
  LOGOUT_WITHOUT_REQUEST,
} from "@/store/actions.type";

const ACCESS_RIGHT = { Id: "710", Sa: "1" };
const DESKTOP_ACCESS_RIGHT = { Id: "714", Sa: "1" };
const CREATE_RIGHT = { Id: "715", Sa: "2" };
const MODIFY_RIGHT = { Id: "715", Sa: "4" };
const SEND_RIGHT = { Id: "715", Sa: "8" }; // Send preliminary reports
const SEND_FINAL_RIGHT = { Id: "715", Sa: "16" }; // Send final or corrected reports
const ADMIN_RIGHT = { Id: "715", Sa: "10" };
const DELETE_RIGHT = { Id: "715", Sa: "12" };
const ONE_TIME_SIGN_ON_RIGHT = { Id: "715", Sa: "13" };
const PHYSICIAN_SIGN_RIGHT = { Id: "800", Sa: "7" };
const TECHNICIAN_SIGN_RIGHT = { Id: "801", Sa: "7" };
const IMPORT_RIGHT = { Id: "802", Sa: "11" };
const SET_TEST_REVIEW_RIGHT = { Id: "803", Sa: "14" };
const FREEZE_VISIT_STATUS_RIGHT = { Id: "803", Sa: "15" };

// initial state, getting token from jwt service
const initialState = () => {
  return {
    token: JwtService.getToken(),
  };
};

// getters
const getters = {
  isAuthenticated: (state) => !!state.token,
  tokenData: (state) =>
    state.token ? JSON.parse(Base64.decode(state.token.split(".")[1])) : {},
  rights: (state, getters) => {
    if (!getters.tokenData || !getters.tokenData.SES_RIGHTS) {
      return [];
    }
    const software = getters.tokenData.SES_RIGHTS.split(";");
    const rights = [];
    software.forEach((val) => {
      const s = val.split(":");
      const softwareID = s[0];
      const softwareActions = s[1].split(",");
      softwareActions.forEach((action) => {
        rights.push({ Id: softwareID, Sa: action });
      });
    });
    return rights;
  },
  username: (state, getters) => {
    const data = getters.tokenData;
    if (!data || !data.sub) {
      return "";
    }
    return data.sub;
  },
  workstationID: (state, getters) => {
    const data = getters.tokenData;
    if (!data || !data.WORKSTATION_ID) {
      return "";
    }
    return data.WORKSTATION_ID;
  },
  userLanguage: (state, getters) => {
    const data = getters.tokenData;
    if (!data || !data.USER_TXT_LANG) {
      return "En";
    }
    return data.USER_TXT_LANG;
  },
  userCountry: (state, getters) => {
    const data = getters.tokenData;
    if (!data || !data.USER_TXT_COUNTRY) {
      return "US";
    }
    return data.USER_TXT_COUNTRY;
  },
  hasFreezeRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) =>
          right.Id === FREEZE_VISIT_STATUS_RIGHT.Id &&
          right.Sa === FREEZE_VISIT_STATUS_RIGHT.Sa
      ) !== -1
    );
  },
  hasSendRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) => right.Id === SEND_RIGHT.Id && right.Sa === SEND_RIGHT.Sa
      ) !== -1
    );
  },
  hasSendFinalRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) =>
          right.Id === SEND_FINAL_RIGHT.Id && right.Sa === SEND_FINAL_RIGHT.Sa
      ) !== -1
    );
  },
  hasModifyRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) => right.Id === MODIFY_RIGHT.Id && right.Sa === MODIFY_RIGHT.Sa
      ) !== -1
    );
  },
  hasCreateRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) => right.Id === CREATE_RIGHT.Id && right.Sa === CREATE_RIGHT.Sa
      ) !== -1
    );
  },
  hasDeleteRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) => right.Id === DELETE_RIGHT.Id && right.Sa === DELETE_RIGHT.Sa
      ) !== -1
    );
  },
  hasPhysicianSignRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) =>
          right.Id === PHYSICIAN_SIGN_RIGHT.Id &&
          right.Sa === PHYSICIAN_SIGN_RIGHT.Sa
      ) !== -1
    );
  },
  hasTechnicianSignRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) =>
          right.Id === TECHNICIAN_SIGN_RIGHT.Id &&
          right.Sa === TECHNICIAN_SIGN_RIGHT.Sa
      ) !== -1
    );
  },
  hasOneTimeSignOnRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) =>
          right.Id === ONE_TIME_SIGN_ON_RIGHT.Id &&
          right.Sa === ONE_TIME_SIGN_ON_RIGHT.Sa
      ) !== -1
    );
  },
  hasImportRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) => right.Id === IMPORT_RIGHT.Id && right.Sa === IMPORT_RIGHT.Sa
      ) !== -1
    );
  },
  hasSetTestReviewRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) =>
          right.Id === SET_TEST_REVIEW_RIGHT.Id &&
          right.Sa === SET_TEST_REVIEW_RIGHT.Sa
      ) !== -1
    );
  },
  hasAdminRight: (state, getters) => {
    return (
      getters.rights.findIndex(
        (right) => right.Id === ADMIN_RIGHT.Id && right.Sa === ADMIN_RIGHT.Sa
      ) !== -1
    );
  },
  hasAccess: (state, getters) => {
    if (getters.isDesktopMode) {
      return (
        getters.rights.findIndex(
          (right) =>
            right.Id === DESKTOP_ACCESS_RIGHT.Id &&
            right.Sa === DESKTOP_ACCESS_RIGHT.Sa
        ) !== -1
      );
    }
    return (
      getters.rights.findIndex(
        (right) => right.Id === ACCESS_RIGHT.Id && right.Sa === ACCESS_RIGHT.Sa
      ) !== -1
    );
  },
  isDefaultUser: (state, getters) => {
    const data = getters.tokenData;
    if (!data) {
      return false;
    }
    return data.USER_ID === DEFAULT_USER_ID;
  },
};

// actions
const actions = {
  [AUTHENTICATE]({ commit }, { username, password, token, workstationID }) {
    commit(SET_TOKEN, null);
    return ApiService.login(username, password, token, workstationID)
      .then((data) => {
        const { Token } = data;
        JwtService.saveToken(Token);
        ApiService.setHeader();
        commit(SET_TOKEN, Token);
      })
      .catch((e) => {
        JwtService.destroyToken();
        ApiService.setHeader();
        throw e;
      });
  },
  [LOGOUT]({ commit, dispatch }) {
    JwtService.destroyToken();
    commit(SET_TOKEN, null);
    // clean up session storage
    window.sessionStorage.removeItem(REPORT_VIEW_DIVIDERS);
    window.sessionStorage.removeItem(COMMENT_TEMPLATES_STATE);
    return ApiService.logout().finally(() => {
      ApiService.setHeader();
      dispatch(INIT_COMPLETE, { root: true });
    });
  },
  [LOGOUT_WITHOUT_REQUEST]({ commit, dispatch }) {
    JwtService.destroyToken();
    ApiService.setHeader();
    commit(SET_TOKEN, null);
    dispatch(INIT_COMPLETE, { root: true });
  },
};

// mutations
const mutations = {
  [RESET](state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  [SET_TOKEN](state, token) {
    state.token = token;
  },
};

export default {
  state: initialState(),
  actions,
  mutations,
  getters,
};

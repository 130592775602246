var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row m-2 border border-secondary visit",
      class: {
        "border-selected shadow": _vm.selected,
      },
      attrs: { id: `visit-${_vm.visit.ID}` },
    },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass: "row p-1 visit-header",
            class: { "bg-selected": _vm.selected },
            on: {
              click: function ($event) {
                return _vm.select()
              },
            },
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "order-lg-7 order-1 col-12 col-sm-12 col-lg" },
                  [
                    _c("div", { staticClass: "visit-label text-muted" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("localized")("Review.View.VisitList.11")
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.isVisitOperationPending
                      ? [
                          _vm.selected &&
                          _vm.isFreezeEnabled &&
                          _vm.hasFreezeRight
                            ? _c("VisitStatusFreeze", {
                                attrs: { visit: _vm.visit },
                              })
                            : _c("VisitStatus", {
                                attrs: {
                                  visitID: _vm.visit.ID,
                                  freeze: _vm.visit.ReviewStatusFreeze,
                                  status: _vm.visit.ReviewStatus,
                                },
                              }),
                        ]
                      : _c("Spinner", { attrs: { type: "border", small: "" } }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order-lg-1 order-2 col-6 col-sm-4 col-lg" },
                  [
                    _c("div", { staticClass: "visit-label text-muted" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("localized")("Review.View.VisitList.1")
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("datetime")(_vm.visit.LocalDate)) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order-lg-2 order-3 col-6 col-sm-4 col-lg" },
                  [
                    _c("div", { staticClass: "visit-label text-muted" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("localized")("Review.View.VisitList.3")
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("PatientName", {
                      attrs: {
                        firstName: _vm.visit.Patient.FirstName,
                        middleName: _vm.visit.Patient.MiddleName,
                        lastName: _vm.visit.Patient.LastName,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order-lg-3 order-4 col-6 col-sm-4 col-lg" },
                  [
                    _c("div", { staticClass: "visit-label text-muted" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("localized")("Review.View.VisitList.8")
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.visit.Patient.ExternalID) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order-lg-4 order-5 col-6 col-sm-4 col-lg" },
                  [
                    _c("div", { staticClass: "visit-label text-muted" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("localized")("Review.View.VisitList.6")
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("date")(_vm.visit.Patient.Birthdate)) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isHISAvailable
                  ? _c(
                      "div",
                      {
                        staticClass: "order-lg-5 order-6 col-6 col-sm-4 col-lg",
                      },
                      [
                        _c("div", { staticClass: "visit-label text-muted" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("localized")("Review.View.VisitList.10")
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("placeholder")(_vm.visit.HISOrderID)
                            ) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isHISAvailable
                  ? _c(
                      "div",
                      {
                        staticClass: "order-lg-6 order-7 col-6 col-sm-4 col-lg",
                      },
                      [
                        _c("div", { staticClass: "visit-label text-muted" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("localized")("Review.View.VisitList.9")
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("placeholder")(_vm.visit.HISVisitID)
                            ) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("ReportList", {
              attrs: {
                reports: _vm.visit.Reports,
                visitID: _vm.visit.ID,
                onSelect: _vm.select,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.selected
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 pl-3 p-2 bg-white" }, [
                _c("div", { staticClass: "d-flex align-items-end" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c("div", [
                      _c("label", { staticClass: "visit-label text-muted" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("localized")("Review.View.Visit.14")
                            ) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: { "btn-group": _vm.showUploadButton } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-secondary",
                            attrs: {
                              id: "btn-create-report",
                              disabled: _vm.isCreateButtonDisabled,
                            },
                            on: { click: _vm.onCreateReport },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("localized")("Review.View.Visit.7")
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          ref: "uploadReport",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "application/pdf" },
                          on: { change: _vm.handleUploadReport },
                        }),
                        _vm._v(" "),
                        _vm.showUploadButton
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-outline-secondary",
                                attrs: {
                                  id: "btn-upload-report",
                                  disabled: _vm.isUploadButtonDisabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.uploadReport.click()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("localized")(
                                        "Review.View.Visit.13"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isFullViewButtonShown
                    ? _c("div", { staticClass: "mr-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-secondary",
                            attrs: { id: "btn-open-full-view" },
                            on: { click: _vm.handleFullView },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("localized")("Review.View.Visit.17")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mr-2" }, [
                    _c("div", { staticClass: "btn-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-outline-secondary",
                          attrs: {
                            id: "btn-update-report",
                            disabled: _vm.isUpdateButtonDisabled,
                          },
                          on: { click: _vm.onUpdateReport },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("localized")("Review.View.Visit.4")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-outline-secondary",
                          attrs: {
                            id: "btn-delete-report",
                            disabled: _vm.isDeleteButtonDisabled,
                          },
                          on: { click: _vm.onDeleteReport },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("localized")("Review.View.Visit.6")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isHISAvailable
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-outline-secondary",
                              attrs: {
                                id: "btn-send-report",
                                disabled: _vm.isSendButtonDisabled,
                              },
                              on: { click: _vm.onSendReport },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("localized")("Review.View.Visit.8")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.isPatientMode
                    ? _c("div", [
                        _c("div", [
                          _c(
                            "label",
                            { staticClass: "visit-label text-muted" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("localized")("Review.View.Visit.15")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-secondary",
                            attrs: { id: "btn-patient-details" },
                            on: {
                              click: function ($event) {
                                return _vm.onPatientDetails(
                                  _vm.visit.Patient.ID
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("localized")("Review.View.Visit.16")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
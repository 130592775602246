var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "insertPanel",
      staticClass: "bg-white rounded border shadow position-absolute small",
      staticStyle: {
        top: "48px",
        right: "8px",
        height: "calc(100% - 56px)",
        "min-height": "200px",
        "z-index": "10",
        width: "66%",
      },
    },
    [
      _c("div", { staticClass: "h-100 d-flex flex-column position-relative" }, [
        _c("div", { staticClass: "bg-light p-2 d-flex" }, [
          _c("div", { staticClass: "flex-fill" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              ref: "searchInput",
              staticClass: "w-100 form-control form-control-sm",
              attrs: { type: "text" },
              domProps: { value: _vm.search },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.search = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pl-2 btn-group btn-group-sm" }, [
            _c(
              "button",
              {
                ref: "btnExpand",
                staticClass: "btn btn-outline-secondary",
                attrs: { title: "expand all" },
                on: { click: _vm.expandAll },
              },
              [_c("b-icon-arrows-expand")],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "btnCollapse",
                staticClass: "btn btn-outline-secondary",
                attrs: { title: "collapse all" },
                on: { click: _vm.collapseAll },
              },
              [_c("b-icon-arrows-collapse")],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "selectionList",
            staticClass: "tree h-100",
            class: {
              "align-items-center justify-content-center":
                _vm.filteredValues.length === 0,
            },
          },
          [
            _vm._l(_vm.filteredValues, function (t, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass:
                    "template-node selectable p-1 border-bottom cursor-pointer",
                  class: {
                    "bg-active": _vm.selectedIndex === idx,
                    "bg-light": t.isCategory,
                  },
                  style: {
                    paddingLeft: 10 + 16 * t.depth + "px !important",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                  attrs: { id: `node-${idx}` },
                  on: {
                    click: function ($event) {
                      return _vm.onSelection(t, idx)
                    },
                  },
                },
                [
                  t.isCategory
                    ? [
                        _c("span", {
                          staticClass: "fas",
                          class: {
                            "fa-caret-down": !_vm.collapsedCategories[t.path],
                            "fa-caret-right": _vm.collapsedCategories[t.path],
                          },
                          staticStyle: {
                            "text-align": "center",
                            "min-width": "15px",
                          },
                        }),
                        _vm._v("\n          " + _vm._s(t.Name) + "\n        "),
                      ]
                    : _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.highlight(t.Name)) },
                      }),
                ],
                2
              )
            }),
            _vm._v(" "),
            _vm.filteredValues.length === 0
              ? _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("localized")("Review.View.Report.40")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "d-flex flex-grow-1 flex-column",
                style: { flex: "1 1 0% !important", minHeight: 0 },
              },
              [
                !_vm.isPatientMode
                  ? _c(
                      "SearchBar",
                      [
                        _c(
                          "template",
                          { slot: "pagination" },
                          [
                            _c("Pagination", {
                              attrs: { "select-page": _vm.selectPage },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _c(
                      "PatientBar",
                      { attrs: { patient: _vm.patient } },
                      [
                        _c(
                          "template",
                          { slot: "pagination" },
                          [
                            _c("Pagination", {
                              attrs: { "select-page": _vm.selectPage },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                _vm._v(" "),
                !_vm.isFetchingVisits
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        style: { flex: "1 1 0% !important", minHeight: 0 },
                      },
                      [
                        _vm.sortedVisits.length > 0
                          ? _c(
                              "div",
                              {
                                ref: "visit-list",
                                staticClass: "flex-grow-1",
                                staticStyle: { "overflow-y": "scroll" },
                                attrs: { id: "visit-list" },
                              },
                              _vm._l(_vm.sortedVisits, function (v) {
                                return _c("VisitListItem", {
                                  key: v.ID,
                                  attrs: {
                                    id: `visit-${v.ID}`,
                                    visit: v,
                                    isPatientMode: _vm.isPatientMode,
                                    onPatientDetails: _vm.handlePatientDetails,
                                    selected: v.ID === _vm.selection.visitID,
                                    onUpdateReport: _vm.handleUpdateReport,
                                    onDeleteReport: _vm.handleDeleteReport,
                                    onCreateReport: _vm.handleCreateReport,
                                    onSendReport: _vm.handleSendReport,
                                  },
                                  on: { uploadReport: _vm.handleUploadReport },
                                })
                              }),
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-grow-1 justify-content-center align-items-center",
                                attrs: { id: "empty-visit-list" },
                              },
                              [
                                _vm._v(
                                  "\n          0 " +
                                    _vm._s(
                                      _vm._f("localized")(
                                        "Review.View.Visit.11"
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            ),
                        _vm._v(" "),
                        _vm.levels.length > 0 && _vm.selection.visitID
                          ? _c("LevelList", {
                              staticClass: "bg-white",
                              attrs: { id: "level-area" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "pt-5 d-flex justify-content-center align-items-center",
                        staticStyle: { flex: "1 1 0% !important" },
                      },
                      [_c("Spinner", { attrs: { type: "grow" } })],
                      1
                    ),
                _vm._v(" "),
                _c("ReportModalCreate", {
                  ref: "createReportModal",
                  on: { "report-created": _vm.handleReportCreated },
                }),
                _vm._v(" "),
                _vm.selectedReport
                  ? _c("ReportModalUpdate", {
                      ref: "updateReportModal",
                      attrs: {
                        id: "update-report-modal",
                        visitID: _vm.selection.visitID,
                        reportID: _vm.selectedReport.ID,
                        reportName: _vm.selectedReport.Filename,
                        reportStatus: _vm.selectedReport.Status,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("ReportModalDelete", { ref: "deleteReportModal" }),
                _vm._v(" "),
                _c("ReportModalUpload", {
                  ref: "uploadReportModal",
                  on: { hide: _vm.onUploadReportModalHide },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span>
    <img
      height="20px"
      :src="'static/' + configuration._src + '.png'"
      :style="{ backgroundColor: configuration._bg }"
    />
    <span class="fas fa-lock" v-if="freeze"></span>
    {{ configuration._key | localized }}
  </span>
</template>

<script>
import "@/assets/img/visit-status/Patient-Assigned_alpha.png";
import "@/assets/img/visit-status/Patient-corrected_alpha.png";
import "@/assets/img/visit-status/Patient-Disabled_alpha.png";
import "@/assets/img/visit-status/Patient-in-review_alpha.png";
import "@/assets/img/visit-status/Patient-Ready_alpha.png";
import "@/assets/img/visit-status/Patient-sign_alpha.png";
import "@/assets/img/visit-status/Patient-skipped_alpha.png";
import "@/assets/img/visit-status/Patient-test-review_alpha.png";
import "@/assets/img/visit-status/Patient-waiting_alpha.png";

export default {
  name: "VisitStatus",
  components: {},
  props: {
    status: {
      type: Number,
    },
    freeze: {
      type: Boolean,
    },
  },
  computed: {
    configuration() {
      const c = this.$store.getters.workflowConfiguration(this.status);
      if (!c) {
        return {};
      }
      return c;
    },
  },
};
</script>

<style scoped></style>
